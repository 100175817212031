import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const  apiGetQuestion = ( access_token: string  ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/imam-qrs',config);
}