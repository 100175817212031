import React from 'react';
import PaymentForm from '../forms/PaymentForm/PaymentForm';

const Payment: React.FC = () => {
  return (
    <>
      <PaymentForm />
    </>
  )
}

export default Payment;
