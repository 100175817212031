import { Modal } from '@mui/material';
import React from 'react';
import './Loader.styles.scss';


export const Loader: React.FC<{ isLoading: boolean , loadingMessage?: string}> = ({ 
  isLoading ,
  loadingMessage = "Chargement "
}) => {

  return (
    <Modal
        open={isLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <div className="loader-container">
            <img src={require("@/assets/images/png/logo-mini.png")}
              className="logo-mini"
              alt="load-profil" />
            <img src={require("@/assets/images/gif/loadmore.gif")}
              className="load-profil"
              alt="load-profil" />
            <small> {loadingMessage} </small>
          </div>
    </Modal>
  )
}
