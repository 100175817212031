import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import { IconButton } from '@mui/material';
import { HighlightOffRounded } from '@mui/icons-material';
import { selectUserConnected } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TUserConnected } from '@/features/profile/types';
import './ModalBanned.styles.scss';



type TModalProps = {
  open: boolean,
  reason: string,
}
type PropsFromRedux = ConnectedProps<typeof connector>;


const CModalBannedFct: React.FC<TModalProps & PropsFromRedux> = ({
  open,
  reason,
  userConnected
}) => {

  const [openM, setOpenM] = useState(open);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openM}
      onClose={()=>{setOpenM(false)}}
    >
      <Grow in={openM}>
        <div className='c-modal-banned '>
          <IconButton className='close-icon'
            size="large"
            color='inherit'
            onClick={()=>{setOpenM(false)}}>
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require("@/assets/images/png/avatar-" + (userConnected as TUserConnected).infos.gender + ".png")}
            alt="sidebar-logo"
            className="modal-avatar"
          />



          {
            
            <>
              <h3><b> ACCÈS  REFUSÉES </b></h3>
              <br />

              <p>
                    Vous avez été <b> banni(e)</b> de la plateforme par notre équipe de modération.<br />
                    Pour la raison suivante : <br />
                    <strong> {reason} </strong>
              </p>
            </>
          }



        </div>
      </Grow>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected
});


const connector = connect(mapStateToProps);
const CModalBanned = connector(CModalBannedFct);

export default CModalBanned;