import { TCurrentExchange } from '@/features/profile/types';
import { selectUserConnected, selectToken } from '@/store/reducers/member/member.selector';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import EchangeHeader from '../components/Echange-header/EchangeHeader';
import EchangeInfos from '../components/Echange-Infos/EchangeInfos';
import EchangeMessage from '../components/Echanges-message/EchangeMessage';
import './DetailEchange.styles.scss';


type TDetailEchangeLayout = {
    currentExchange?: TCurrentExchange,
}
type PropsFromRedux = ConnectedProps<typeof connector>;

const DetailEchangeFct: React.FC<TDetailEchangeLayout & PropsFromRedux> = ({
    userConnected,
    token,
    currentExchange
}) => {

    return (

            <Box>
                <Grid container rowSpacing={4} columnSpacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={4} xxl={4}>
                        <EchangeInfos currentExchange={currentExchange}
                                      isDiscussing={true}/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={8} xxl={8}>
                        <Grid container rowSpacing={2} columnSpacing={0} >
                            <Grid item xs={12} sm={12} lg={12}>
                                <EchangeHeader currentExchange={currentExchange} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <EchangeMessage  exchangeId = {(currentExchange as TCurrentExchange).id} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectUserConnected,
    token: selectToken
});


const connector = connect(mapStateToProps);
const DetailEchange = connector(DetailEchangeFct);

export default DetailEchange;