import React, { useState } from "react";
import "./Header.styles.scss";
import {
  PowerSettingsNewRounded,
  HelpOutlineRounded,
  MenuOpenRounded,
} from "@mui/icons-material";
import {
  TooltipProps,
  Tooltip,
  tooltipClasses,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { persistor, store, useAppDispatch } from "@/store";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import CModal from "@/components/Modal/Modal";
import { InlineIcon } from "@iconify/react";
import { getToken } from "@/store/reducers/member/member.actions";
import {
  selectIsModerationSuspend,
  selectModerationSocket,
  selectNbreLiveSocket,
} from "@/store/reducers/general_param/general_param.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TModerationSocket } from "../../types";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

type TProps = {
  title: string;
  subtitle: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const HeaderFct: React.FC<TProps & PropsFromRedux> = ({
  moderation_socket,
  is_moderation_suspend,
  nbre_live_socket,
  title,
  subtitle,
}) => {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [openHelp, setOpenHelp] = useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [openModeration, setOpenModeration] = useState(false);
  const handleOpenModeration = () => setOpenModeration(true);
  const handleCloseModeration = () => setOpenModeration(false);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const logout = () => {
    store.dispatch({ type: "CLEAR_STORE" });
    localStorage.clear();
    navigate(routePaths.auth, { replace: true });
    dispatch(getToken(""));
    persistor.purge();
  };

  const goToHome = () => {
    navigate(routePaths.profileChildren.homeAbsolute, { replace: true });
  };

  return (
    <>
      <div className="c-header">
        <div className="mobile-header">
          <img
            src={require("@/assets/images/png/logo-mini.png")}
            alt="logo-mini"
            className="logo-mini"
            onClick={goToHome}
          />
          <div className="title-part">
            <h2 className="title">{title}</h2>
            <p className="subtitle">{subtitle}</p>
          </div>
          <Button
            className="btn-moderation"
            color={
              (moderation_socket as TModerationSocket).status &&
              !is_moderation_suspend
                ? "success"
                : "inherit"
            }
            onClick={handleOpenModeration}
            variant="contained"
          >
            <InlineIcon
              icon={
                (moderation_socket as TModerationSocket).status &&
                !is_moderation_suspend
                  ? "fa-solid:door-open"
                  : "fa-solid:door-closed"
              }
            />
          </Button>
          <StyledBadge
            overlap="circular"
            variant="dot"
            color="primary"
            badgeContent={nbre_live_socket}
          >
            <div
              className="rounded-square-btn-head"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <MenuOpenRounded color="secondary" />
            </div>
          </StyledBadge>
        </div>

        <img
          src={require("@/assets/images/png/logo-mini.png")}
          alt="logo-mini"
          className="logo-mini-md"
          onClick={goToHome}
        />
        <div className="title-part">
          <h2 className="title">{title}</h2>
          <p className="subtitle">{subtitle}</p>
        </div>

        <div className="btn-part">
          <Button
            className="btn-moderation"
            color={
              (moderation_socket as TModerationSocket).status &&
              !is_moderation_suspend
                ? "success"
                : "inherit"
            }
            onClick={handleOpenModeration}
            variant="contained"
          >
            MODÉRATION{" "}
            {(moderation_socket as TModerationSocket).status &&
            !is_moderation_suspend
              ? "OUVERT"
              : "FERMÉ"}
          </Button>
        </div>

        <div
          className="rounded-square-btn"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <MenuOpenRounded color="secondary" />
        </div>

        <CustomTooltip title="Besoin d'aide">
          <IconButton className="icon-header" onClick={handleOpenHelp}>
            <HelpOutlineRounded color="primary" />
          </IconButton>
        </CustomTooltip>

        <CustomTooltip title="Se déconnecter">
          <IconButton className="icon-header" onClick={handleClickOpenLogout}>
            <PowerSettingsNewRounded color="primary" />
          </IconButton>
        </CustomTooltip>
      </div>
      <CModal
        open={openHelp}
        type="aide"
        onClose={handleCloseHelp}
        closeClick={handleCloseHelp}
      />
      <CModal
        open={openModeration}
        type="moderation"
        onClose={handleCloseModeration}
        closeClick={handleCloseModeration}
        moderation_socket={moderation_socket}
        is_moderation_suspend={is_moderation_suspend}
      />
      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Déconnexion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de
            vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogout}>Annuler</Button>
          <Button onClick={logout} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  moderation_socket: selectModerationSocket,
  is_moderation_suspend: selectIsModerationSuspend,
  nbre_live_socket: selectNbreLiveSocket,
});

const connector = connect(mapStateToProps);
const Header = connector(HeaderFct);

export default Header;
