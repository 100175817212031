import React from 'react';
import { routePaths } from '@/config';
import { TextField, Button, FormGroup, FormControlLabel, Switch, Box, Grid } from '@mui/material';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/ArrowBack';
import { usePaymentInputs } from 'react-payment-inputs';
import './PaymentForm.styles.scss';

const PaymentForm:React.FC = () =>  {

  const [checked, setChecked] = React.useState(true);
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const goTo = (route:string)=> {
    navigate(route , { replace: true });
  }




  return (
    <div className="paiement-container">
      <IconButton aria-label="back" 
                  className='back-icon' 
                  size="large" 
                  color='tertiary'
                  onClick={()=>{goTo(routePaths.authChildren.termsAndConditionAbsolute)}}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
      <div className="header-part">
        <h1 className="title">Payer</h1>
      </div>
      <div className="form-part">
          <div className="option-payment-row">
            <div className="infos-option">
              <h1> Male - Forfait 1 an </h1>
              <FormGroup>
                  <FormControlLabel 
                    control={<Switch  checked={checked}
                                      onChange={handleChange}  />} label={checked ? "Payer par stripe" : "Payer par paypal"} />
              </FormGroup>
            </div>
            <div className="price">
              <span> 70.00 € </span>
            </div>
          </div>
          <Box>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12} lg={12}>
                <TextField  placeholder="nom du titutaire" 
                        variant="outlined"
                        label="Nom du titulaire"
                        type="text" />
              </Grid>
              <Grid item xs={12} sm={12} lg={7}>
                <TextField  placeholder="XXXX-XXXX-XXXX-XXXX" 
                        variant="outlined"
                        label="Numéro de carte"
                        type="text"
                        inputProps={ getCardNumberProps() } />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField  placeholder="MM/YY" 
                      variant="outlined"
                      label="Date d'expiration"
                      type="text"
                      inputProps={ getExpiryDateProps()} />
              </Grid>
              <Grid item xs={12} sm={12} lg={2}>
                <TextField  placeholder="CVC" 
                        variant="outlined"
                        label="CVC"
                        type="text"
                        inputProps={ getCVCProps()}  />
              </Grid>
            </Grid>
          </Box>

          <Button variant="contained" 
                  color='tertiary'
                  sx={{marginTop:'30px'}}> VALIDER ET PAYER </Button>
      </div>
      <div className="footer-part">
          <Link className="link-primary"
                to={routePaths.authChildren.signInAbsolute} > Retourner sur la page de connexion</Link>
          <a href="https://zawaj-sounnah.com/" target="_blank" rel="noreferrer" className="link-gray">Aller sur zawaj-sounnah.com</a>
      </div>
    </div>
  )
}

export default PaymentForm;
