import { selectToken } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { publicRoutes } from "./public.routes";
import { protectedRoutes } from "./protected.routes";
import {
  selectNbreLiveSocket,
  selectNbreNotificationSocket,
} from "@/store/reducers/general_param/general_param.selector";
import { SOCKET_URL } from "@/config";
import { createContext, useState, useEffect } from "react";
import socketIOClient, { Socket } from "socket.io-client";

export const AppSocketContext = createContext<{ socketIO: Socket | null }>({
  socketIO: null,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const AppRoutesFct: React.FC<PropsFromRedux> = ({
  token,
  nbre_notification_socket,
  nbre_live_socket,
}) => {
  const [socketIO, setSocketIO] = useState<Socket | null>(null);

  useEffect(() => {
    if (token) {
      const io = socketIOClient(SOCKET_URL, {
        path: "/socket/v1/socketio/",
        transports: ["websocket"],
        query: { token: token },
        upgrade: false,
        rejectUnauthorized: false,
      });

      setSocketIO(io);
    }
  }, [token]);

  const routes = token.length === 0 ? publicRoutes : protectedRoutes;
  const element = useRoutes([...routes]);

  return (
    <AppSocketContext.Provider value={{ socketIO }}>
      {element}
    </AppSocketContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectToken,
  nbre_notification_socket: selectNbreNotificationSocket,
  nbre_live_socket: selectNbreLiveSocket,
});

const connector = connect(mapStateToProps);
const AppRoutes = connector(AppRoutesFct);

export default AppRoutes;
