import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const  apiGetLive = ( access_token: string, page:number=1, limit: number=6, ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/live-sessions?page='+page+'&limit='+limit,config);
}