import { TUserConnected } from "@/features/profile/types";
import { MemberAction } from "./member.actions";
import memberTypes from "./member.types";

interface IMemberReducerState {
  user_connected: TUserConnected | null;
  token: string;
  progress: number;
  location: {
    latitude: number,
    longitude: number
  };
  nbre_message_notif: number,
  nbre_favorite_notif: number,
  nbre_exchange_notif: number,
  new_modification_submit: boolean
}

const INITIAL_STATE = {
  user_connected: null,
  token: '',
  progress: 10,
  location: { latitude: 0, longitude: 0 },
  nbre_message_notif: 0,
  nbre_favorite_notif: 0,
  nbre_exchange_notif: 0,
  new_modification_submit: false,
};

const memberReducer = (
  state: IMemberReducerState = INITIAL_STATE,
  action: MemberAction
): IMemberReducerState => {
  switch (action.type) {
    case memberTypes.GET_USER_CONNECTED:
      return {
        ...state,
        user_connected: action.payload
      };

    case memberTypes.GET_TOKEN:
      return {
        ...state,
        token: action.payload
      };


    case memberTypes.UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload
      };

    case memberTypes.UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload
      };

    case memberTypes.UPDATE_PROFILE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          additional_infos: {
            ...(state.user_connected as TUserConnected).additional_infos,
            profile: action.payload
          }
        }
      };

    case memberTypes.UPDATE_RELIGIOUS_ASPECT:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          additional_infos: {
            ...(state.user_connected as TUserConnected).additional_infos,
            religious_aspects: action.payload
          }
        }
      };

    case memberTypes.UPDATE_RELIGIOUS_PRACTICES:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          additional_infos: {
            ...(state.user_connected as TUserConnected).additional_infos,
            religious_practices: action.payload
          }
        }
      };

    case memberTypes.UPDATE_HEALTH_AND_DISABILITY:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          additional_infos: {
            ...(state.user_connected as TUserConnected).additional_infos,
            health_and_disability: action.payload
          }
        }
      };

    case memberTypes.UPDATE_IS_APPROVED:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_approved: action.payload
          }
        }
      };

    case memberTypes.UPDATE_IS_ACTIVE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_active: action.payload
          }
        }
      };


    case memberTypes.UPDATE_IS_ONLINE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_available: action.payload
          }
        }
      };






    case memberTypes.UPDATE_IS_FRESHNEW:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_fresh_new: action.payload
          }
        }
      };

    case memberTypes.UPDATE_IS_FICHE_FILLED:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            are_main_infos_provided: action.payload
          }
        }
      };

    case memberTypes.UPDATE_IS_SUBSCRIPTION_ACTIVE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_subscription_active: action.payload
          }
        }
      };

    case memberTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          notifications: action.payload
        }
      };

    case memberTypes.UPDATE_MESSAGE_NOTIF:
      return {
        ...state,
        nbre_message_notif: action.payload,
      };

    case memberTypes.UPDATE_FAVORITE_NOTIF:
      return {
        ...state,
        nbre_favorite_notif: action.payload,
      };

    case memberTypes.UPDATE_EXCHANGE_NOTIF:
      return {
        ...state,
        nbre_exchange_notif: action.payload,
      };

    case memberTypes.UPDATE_PENDING_CANCEL_CONVERSATION:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            pending_cancel_conversation: action.payload
          }
        }
      };

    case memberTypes.UPDATE_ACCEPT_EXCHANGE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            has_conversation: action.payload
          }
        }
      };

    case memberTypes.UPDATE_NEW_MODIFICATION_SUBMIT:
      return {
        ...state,
        new_modification_submit: action.payload,
      };


    default:
      return state;
  }
};

export default memberReducer;
