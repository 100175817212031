import { AuthRoutes } from "@/features/auth";
import { Navigate, RouteObject } from "react-router-dom";
import { routePaths } from "../config";
import ProtectedRoute from "./ProtectedRoutes";

export const publicRoutes: RouteObject[] = [
  {
    path: routePaths.auth + "/*",
    element: <ProtectedRoute > <AuthRoutes /> </ProtectedRoute>,
  },
  { path: "*", element: <Navigate to={`${routePaths.auth}`} /> }
];





