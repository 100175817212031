import React, { createContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import "./Blacklist.styles.scss";
import * as _ from "lodash";
import ListManager from "@/components/ListManager/ListManager";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "../../types";
import { TProfileCard } from "../../Elements/ProfileCard/ProfileCard";
import {
  apiGetBlackList,
  apiGetHideList,
  apiRemoveFromBlacklist,
} from "../../api/blacklist.api";
import { Toastify } from "@/utils/toast";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

export const BlacklistContext = createContext<{
  removeFromBlacklistFct: (profil_id: string, username: string) => void;
  loadingRemoveB: boolean;
}>({
  removeFromBlacklistFct: () => {},
  loadingRemoveB: false,
});

type PropsFromRedux = ConnectedProps<typeof connector>;
const CtBlacklistFct: React.FC<PropsFromRedux> = ({ userConnected, token }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingRemoveB, setLoadingRemoveB] = useState(false);

  const [listeNoire, setListeNoire] = useState<TProfileCard[]>([]);
  const [listeMasque, setListeMasque] = useState<TProfileCard[]>([]);

  useEffect(() => {
    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      setLoading(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetBlackList(token)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList: TProfileCard[] = response.items.map((data: any) => {
            return {
              isVerified: data.is_verified,
              username: data.username,
              type:
                (data.has_visited && "Mes spectateurs") ||
                (data.is_visited ? "consulted" : "non consultés"),
              progressValue: data.rate_of_completion,
              profil_id: data.profile_id,
              isOnline: data.is_online,
              age: data.age,
              town: data.city,
              country: data.country,
              origine: data.home_country,
              nationalite: data.citizenship,
              hasMore: data.has_more,
              sendStatut: "",
              description: data.description,
              profile_wanted: data.profile_wanted,
              isMutualFavorite: false,
              isSimpleFavorite: false,
              date_of_creation: data.date_of_creation,
              cloture: null,
              isListeNoire: true,
            };
          });

          console.log([...tempList]);
          setListeNoire([...tempList]);
        })
        .catch((error) => {
          setLoading(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    (userConnected as TUserConnected).account.is_active &&
      false &&
      setLoading2(true);

    (userConnected as TUserConnected).account.is_active &&
      false &&
      apiGetHideList(token)
        .then((response: any) => {
          setLoading2(false);
          console.log(response);

          let tempList: TProfileCard[] = response.items.map((data: any) => {
            return {
              title: data.title,
              host: data.host,
              type: data.type,
              link: data.link,
              live_date: data.link,
            };
          });

          console.log([...tempList]);
          setListeMasque([...tempList]);
        })
        .catch((error) => {
          setLoading2(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFromBlacklistFct = (profil_id: string, username: string) => {
    setLoadingRemoveB(true);
    apiRemoveFromBlacklist(profil_id, token)
      .then((response: any) => {
        setLoadingRemoveB(false);
        console.log(response);

        let tempArr = listeNoire;
        let removedItem = _.remove(tempArr, (x) => {
          return x.profil_id === profil_id;
        });
        console.log(removedItem);
        setListeNoire([...tempArr]);

        Toastify(
          `Vous avez retiré ${username} de votre liste noire avec succès`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingRemoveB(false);

        //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
      });
  };

  return (
    <BlacklistContext.Provider
      value={{
        removeFromBlacklistFct: removeFromBlacklistFct,
        loadingRemoveB: loadingRemoveB,
      }}
    >
      <>
        {(typeof (userConnected as TUserConnected).account
          .reason_of_desactivation_by_admin === "string"
          ? false
          : (userConnected as TUserConnected).account.is_free ||
            ((userConnected as TUserConnected).account.is_active &&
              (userConnected as TUserConnected).account
                .is_subscription_active)) && (
          <div className="ct-blacklist">
            <div className="ct-blacklist__body-part">
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <ListManager
                      title="Ma liste noire"
                      subtitle=""
                      loading={loading}
                      profileList={listeNoire}
                      alt={true}
                    />
                  </Grid>
                  {false && (
                    <Grid item xs={12} sm={12} lg={12} xl={6}>
                      <ListManager
                        title="Profile masqué"
                        subtitle=""
                        loading={loading2}
                        profileList={listeMasque}
                        alt={true}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
        )}
        {(userConnected as TUserConnected).account
          .changes_requests_rejected && (
          <CModalRejectedModif
            open={
              (userConnected as TUserConnected).account
                .changes_requests_rejected
            }
          />
        )}
        <CModalRemindPay
          open={
            typeof (userConnected as TUserConnected).account
              .reason_of_desactivation_by_admin === "string"
              ? true
              : !(userConnected as TUserConnected).account.is_free &&
                (!(userConnected as TUserConnected).account.is_active ||
                  !(userConnected as TUserConnected).account
                    .is_subscription_active)
          }
          type={
            (userConnected as TUserConnected).account.is_subscription_expired
              ? "reabonnement"
              : "abonnement"
          }
        />
      </>
    </BlacklistContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const CtBlacklist = connector(CtBlacklistFct);

export default CtBlacklist;
