export type TSubMenu = {
  [key: string]: string;
};

export type TMenu = {
  label: string;
  route: string | TSubMenu;
  exact?: boolean;
  icon?: any;
  children?: TMenu[];
};

export type TMaritalStatus =
  | "none"
  | "never-married"
  | "married"
  | "divorced"
  | "widow"
  | "not_pronounced";

export type TCountry = {
  country: string;
  abbreviation: string;
};

export type TFiche = {
  username: string;
  age: 0 | "";
  city: string;
  country: string;
  country_code: string;
};

export type TNotification = {
  id?: string;
  body: string;
  date: string;
  sender: string;
  subject: string;
};

export type TAdditionnalInfosProfil = {
  home_country: string;
  home_country_code: string;
  other_home_country: string;
  other_home_country_code: string;
  citizenship: string;
  citizenship_code: string;
  other_citizenship: string;
  other_citizenship_code: string;
  ethnicity: string;
  ethnicities_wanted: string[];
  marital_status: string;
  have_money: "" | "true" | "false";
  is_family_know_before_marriage: "" | "true" | "false";
  fair_in_sharing_nights: "" | "true" | "false";
  childhood_place: string;
  have_children: boolean | any;
  dependent_boys?: number;
  dependent_girls?: number;
  hijra_childcare: string;
  accept_anothers_children: boolean | any;
  accepted_children?: number;
  occupation: string;
  description: string;
  profile_wanted: string;
  prohibitive_criteria: string;
};

export type TAdditionnalInfosReligiousAspect = {
  is_salafi: boolean | any;
  male_dress_code: string;
  not_wear_headscarf: boolean;
  wear_turban: boolean;
  wear_hijab: boolean;
  wear_jilbab: boolean;
  wear_niqab: boolean;
  wear_abaya: boolean;
  favorite_scholars: string;
  favorite_preachers: string;
  have_school_of_jurisprudence: string;
  is_in_hijra: boolean | any;
  like_to_make_hijra: boolean | any;
  when_to_make_hijra: string;
  hijra_country: string;
  hijra_country_code: string;
  agree_to_move: boolean;
  accept_married_man: boolean | any;
  have_mahram: boolean | any;
  mahram_phone_number: string;
};

export type TAdditionnalInfosReligiousPractices = {
  pray_five_prayers: boolean | any;
  pray_friday_mosque: boolean | any;
  relationship_to_mosque: string;
  relationship_to_quran_reading: string;
  relationship_to_arabic_language: string;
  level_of_religious_education: string;
};

export type TAdditionnalInfosHealthAndDisability = {
  physical_health: string;
  occult_diseases: string;
};

export type TUserConnected = {
  id: string;
  member_id: string;
  stripe_id?: string;
  rate_of_completion: number;
  infos: {
    firstname: string;
    lastname: string;
    username: string;
    gender: "male" | "female";
    age: number;
    date_of_creation: string;
  };
  additional_infos: {
    profile: TAdditionnalInfosProfil;
    religious_aspects: TAdditionnalInfosReligiousAspect;
    religious_practices: TAdditionnalInfosReligiousPractices;
    health_and_disability: TAdditionnalInfosHealthAndDisability;
  };
  credentials?: {
    email: string;
    email_authentication: {
      expires_in: string;
    };
    is_email_verified: boolean;
  };
  contacts?: {
    phone_number: string;
    city: string;
    country: string;
    country_code: string;
  };
  account: {
    is_approved: boolean; // compte approuvé par les administrateur ou pas
    is_active: boolean; // compte globalement actif ( a payé ou pas )
    is_online: boolean; // avec socket
    is_available: boolean; // disponible ou pas
    is_deleted: boolean;
    are_main_infos_provided: boolean; // a rempli la fiche
    is_subscription_active: boolean;
    is_subscription_expired: boolean;
    is_fresh_new: boolean; // viens de s'inscrire
    is_free: boolean; //pour les comptes gratuit
    is_verified?: boolean;
    changes_requests_rejected: boolean; //savoir si le changement de profil a été refusé
    pending_cancel_conversation: boolean;
    has_conversation: boolean;
    reason_of_desactivation_by_admin: null | string;
    is_banned: boolean;
    reason_banned: string;
  };
  subscriptions?: {
    payment: {
      has_paid: boolean;
      date: string;
    };
    current: {
      id: string;
      date_of_subscription: string;
      current_period_end: string;
      current_period_start: string;
      subscription_plan: {
        id: string;
        name: string;
        description: string;
        duration: "one-month" | "three-months" | "six-months" | "one-year";
        currency: "eur" | "usd";
        amount: number;
      };
    };
    list: {
      id: string;
      subscription_plan: {
        id: string;
        name: string;
        description: string;
        duration: "one-month" | "three-months" | "six-months" | "one-year";
        currency: "eur" | "usd";
        price_for_men: number;
        price_for_women: number;
      };
      current_period_start: string;
      current_period_end: string;
    }[];
  };
  members?: {};
  notifications: TNotification[];
};

export type TFilteredQuery = {
  age_minimum: number | "";
  age_maximum: number | "";
  tenue_vestimentaire_femme: string[];
  //distance_parcouru: '',
  situation_matrimoniale: string[];
  situation_parentale: "yes" | "no" | "any" | "";
  accepte_enfants: "yes" | "no" | "any" | "";
  accepte_married_man: "yes" | "no" | "any" | "";
  profils_salafi: "yes" | "no" | "any" | "";
  accepte_demenager: "yes" | "no" | "any" | "";
  etant_hijra: "yes" | "no" | "any" | "";
  souhait_hijra: "yes" | "no" | "any" | "";
  recherche_mon_ethnie: "yes" | "no" | "any" | "";
  ethniesList: { name: string; checked: boolean }[];
  originList: { name: string; checked: boolean }[];
  countriesResidence: { name: string; checked: boolean }[];
};

export type TSubscriptionPlan = {
  id: string;
  name: string;
  description: string;
  duration: string;
  currency: string;
  price_for_men: number;
  price_for_women: number;
};

export type TCurrentExchange = {
  id: string;
  is_open: boolean;
  creation_date: string;
  sender: {
    gender: "male" | "female";
    age?: number;
    citizenship: string;
    city: string;
    country: string;
    date_of_creation: string;
    description: string;
    has_more: boolean;
    has_visited: boolean;
    home_country: string;
    is_fan: boolean;
    is_favorite: boolean;
    is_online: boolean;
    is_verified: boolean;
    is_visited: boolean;
    profile_id: string;
    profile_wanted: string;
    rate_of_completion: number;
    username: string;
    is_active: boolean;
  };
  receiver: {
    gender: "male" | "female";
    age?: number;
    citizenship: string;
    city: string;
    country: string;
    date_of_creation: string;
    description: string;
    has_more: boolean;
    has_visited: boolean;
    home_country: string;
    is_fan: boolean;
    is_favorite: boolean;
    is_online: boolean;
    is_verified: boolean;
    is_visited: boolean;
    profile_id: string;
    profile_wanted: string;
    rate_of_completion: number;
    username: string;
    is_active: boolean;
  };
  closure: {};
  messages: [];
  duration?: {
    unit: string;
    value: number;
  };
};

/** EXCEPTIONELL EXPORT */

export const initCurrentExchange: TCurrentExchange = {
  id: "",
  is_open: false,
  creation_date: "",
  sender: {
    gender: "male",
    age: 0,
    citizenship: "",
    city: "",
    country: "",
    date_of_creation: "",
    description: "",
    has_more: false,
    has_visited: false,
    home_country: "",
    is_fan: false,
    is_favorite: false,
    is_online: false,
    is_verified: false,
    is_visited: false,
    profile_id: "",
    profile_wanted: "",
    rate_of_completion: 0,
    username: "",
    is_active: true,
  },
  receiver: {
    gender: "male",
    age: 0,
    citizenship: "",
    city: "",
    country: "",
    date_of_creation: "",
    description: "",
    has_more: false,
    has_visited: false,
    home_country: "",
    is_fan: false,
    is_favorite: false,
    is_online: false,
    is_verified: false,
    is_visited: false,
    profile_id: "",
    profile_wanted: "",
    rate_of_completion: 0,
    username: "",
    is_active: true,
  },
  closure: {},
  messages: [],
};

export type TModerationSocket = {
  day: string;
  end_time: string;
  is_holiday: boolean;
  message: string;
  start_time: string;
  status: boolean;
};

export type TGeneralLinks = {
  youtube: string;
  telegram: string;
  facebook: string;
  twitter: string;
  instagram: string;
};

export type TInterfaceSettings = {
  men_group_head: {
    name: string;
    email: string;
    phone: string;
  };
  women_group_head: {
    name: string;
    email: string;
    phone: string;
  };
  email_management: {
    contact_email: string;
    expedition_email: string;
  };
  social_media: {
    facebook: string;
    instagram: string;
    twitter: string;
    telegram: string;
  };
  web_media: {
    youtube: string;
    telegram: string;
  };
  moderation: {
    number_of_messages_per_discussion: number;
    number_of_caracters_per_message: number;
    schedule: string;
  };
  maintenance: {
    website: boolean;
    suspend_moderation: boolean;
  };
};
