import { InlineIcon } from "@iconify/react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  LinearProgress,
  Grow,
  Modal,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import * as moment from "moment";
import classnames from "classnames";
import "moment/locale/fr";
import "./ProfileCard.styles.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import FullProfil from "@/components/FullProfil/FullProfil";
import { LoadingButton } from "@mui/lab";
import { HighlightOffRounded } from "@mui/icons-material";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TCurrentExchange, TUserConnected } from "../../types";
import HistoriqueConversation from "./Modals-content/Historique-conversation/HistoriqueConversation";
import { Toastify } from "@/utils/toast";
import { apiAskForExchange, apiSignalMember } from "../../api/profile.api";
import { store } from "@/store";
import { apiAcceptDemand, apiRemindDemand } from "../../api/exchange.api";
import {
  updateCurrentExchange,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import { FavoriteContext } from "../../pages/Favorite-content/Favorite.content";
import { BlacklistContext } from "../../pages/Blacklist-content/Blacklist.content";
import { ExchangeContext } from "../../pages/Exchange-content/Exchange.content";
import { updateAcceptExchange } from "@/store/reducers/member/member.actions";
import TextDisplayer from "@/components/TextDisplayer/TextDisplayer";

export type TProfileCard = {
  alt?: boolean;
  no_corner_option?: boolean;
  isVerified?: boolean;
  isOnline?: boolean;
  hasMore?: boolean;
  username?: string;
  type?: "consulted" | "non consultés" | "Mes spectateurs" | "";
  progressValue?: number | undefined;
  profil_id: string;
  age?: number;
  town?: string | null;
  country?: string | null;
  origine?: string | null;
  nationalite?: string | null;
  sendStatut?: "sent" | "received" | "";
  description?: string;
  profile_wanted?: string;
  isCloture?: boolean;
  isExchange?: boolean;
  isMutualFavorite?: boolean;
  isSimpleFavorite?: boolean;
  cloture?: { date_cloture: string; motif: string; emitter: string } | null;
  isListeNoire?: boolean;
  conversation_id?: string;
  has_conversation?: boolean;
  date_of_creation?: string;
  is_deleted?: boolean;
  last_connection?: string | null;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const ProfileCardFct: React.FC<PropsFromRedux & TProfileCard> = ({
  userConnected,
  token,
  alt = false,
  no_corner_option = false,
  isVerified,
  isOnline,
  username,
  type,
  progressValue,
  profil_id,
  age,
  town,
  country,
  origine,
  nationalite,
  hasMore,
  sendStatut,
  description,
  profile_wanted,
  isCloture = false,
  isExchange = false,
  isMutualFavorite = false,
  isSimpleFavorite = false,
  cloture = null,
  isListeNoire = false,
  conversation_id = "",
  has_conversation = false,
  date_of_creation = "",
  is_deleted = false,
  last_connection = null,
}) => {
  const [reveal, setReveal] = useState(false);
  const [openFullProfil, setOpenFullProfil] = React.useState(false);
  const [openSignal, setOpenSignal] = React.useState(false);
  const [openConfirmedSignal, setOpenConfirmedSignal] = React.useState(false);
  const [openDenyRequest, setOpenDenyRequest] = React.useState(false);
  const [openConfirmedDenyRequest, setOpenConfirmedDenyRequest] =
    React.useState(false);
  const [openRemoveFavorite, setOpenRemoveFavorite] = React.useState(false);
  const [openRemoveBlacklist, setOpenRemoveBlacklist] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingRemind, setLoadingRemind] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenFullProfil = () => setOpenFullProfil(true);
  const handleCloseFullProfil = () => setOpenFullProfil(false);

  const handleOpenSignal = () => setOpenSignal(true);
  const handleCloseSignal = () => {
    formik.resetForm();
    setOpenSignal(false);
  };

  const handleOpenConfirmedSignal = () => setOpenConfirmedSignal(true);
  const handleCloseConfirmedSignal = () => setOpenConfirmedSignal(false);

  const handleOpenDenyRequest = () => setOpenDenyRequest(true);
  const handleCloseDenyRequest = () => {
    formikDenyRequest.resetForm();
    setOpenDenyRequest(false);
  };

  const handleOpenConfirmedDenyRequest = () =>
    setOpenConfirmedDenyRequest(true);
  const handleCloseConfirmedDenyRequest = () =>
    setOpenConfirmedDenyRequest(false);

  const handleOpenRemoveFavorite = () => setOpenRemoveFavorite(true);
  const handleCloseRemoveFavorite = () => setOpenRemoveFavorite(false);

  const handleOpenRemoveBlacklist = () => setOpenRemoveBlacklist(true);
  const handleCloseRemoveBlacklist = () => setOpenRemoveBlacklist(false);

  const toggleReveal = () => {
    setReveal(!reveal);
  };

  const validationSchema = yup.object({
    motif: yup.string().required(" la raison est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleOpenConfirmedSignal();
      }, 500);
    },
  });

  const formikDenyRequest = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleOpenConfirmedDenyRequest();
      }, 500);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { removeFromBlacklistFct, loadingRemoveB } =
    useContext(BlacklistContext);

  const { removeFromFavoriteFct, loadingRemove } = useContext(FavoriteContext);

  const { rejectDemandFct, loadingReject, cancelDemandFct, loadingCancel } =
    useContext(ExchangeContext);

  const startExchange = () => {
    setLoading1(true);

    apiAskForExchange(profil_id, token)
      .then((response: any) => {
        setLoading1(false);
        console.log(response);
        Toastify(
          ` votre demande d'échange a bien été envoyé à ${username}`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading1(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const signalMember = () => {
    setLoading2(true);

    apiSignalMember({ member_id: profil_id, note: formik.values.motif }, token)
      .then((response: any) => {
        setLoading2(false);
        console.log(response);
        Toastify(
          ` Le signalement de ${username} a bien été envoyé à nos équipes de modération `,
          "success",
          5000
        );

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      })
      .catch((error) => {
        setLoading2(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      });
  };

  const acceptExchange = () => {
    setLoadingAccept(true);

    apiAcceptDemand(profil_id, token)
      .then((response: any) => {
        setLoadingAccept(false);
        console.log(response);
        Toastify(
          ` Vous pouvez désormais échanger avec ${username} `,
          "success",
          5000
        );

        let tempCurrent: TCurrentExchange = response.current;
        store.dispatch(updateCurrentExchange(tempCurrent));
        store.dispatch(updateAcceptExchange(true));
        store.dispatch(updateOpenDiscussion(true));
      })
      .catch((error) => {
        setLoadingAccept(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const remindExchange = () => {
    setLoadingRemind(true);

    apiRemindDemand(profil_id, token)
      .then((response: any) => {
        setLoadingRemind(false);
        console.log(response);
        Toastify(
          ` La relance a bien été effectuée auprès de ${username} `,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingRemind(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <>
      <div
        className={classnames("card-profile", {
          consulted: type === "consulted",
          un_consulted: type === "non consultés",
          followers: type === "Mes spectateurs",
          alt: alt,
          "is-exchange": isExchange,
          "is-cloture": isCloture,
          male: (userConnected as TUserConnected).infos.gender === "male",
          female: (userConnected as TUserConnected).infos.gender === "female",
        })}
      >
        {isVerified ? (
          <>
            <div className="pastille">
              <InlineIcon icon="humbleicons:verified" />
            </div>
          </>
        ) : (
          <>
            <div className="pastille ko">
              <InlineIcon icon="ep:close-bold" />
            </div>
          </>
        )}

        {!isExchange && (
          <Box
            sx={
              alt
                ? { width: "calc(100% - 50px)", marginTop: "10px" }
                : { width: "100%", marginTop: "10px" }
            }
          >
            <LinearProgress
              variant="determinate"
              color={progressValue === 0 ? "inherit" : "success"}
              value={progressValue}
              sx={{ borderRadius: 5 }}
            />
            {progressValue === 0 ? (
              <p className="profil-progress-text ko">
                {" "}
                Profil rempli à <b>{progressValue}%</b>{" "}
              </p>
            ) : (
              <p className="profil-progress-text ">
                {" "}
                Profil rempli à <b>{progressValue}%</b>{" "}
              </p>
            )}
          </Box>
        )}

        {isExchange ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <p className="profil-date-text isExchange">
                  {" "}
                  Inscrit depuis le{" "}
                  <b>
                    {moment.default(new Date(date_of_creation)).format("L")}
                  </b>{" "}
                  <span style={{ margin: "0px 10px" }}> | </span> ID:{" "}
                  <b className="id" onClick={handleOpenFullProfil}>
                    {profil_id}
                  </b>{" "}
                </p>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={8}>
                <p className="profil-date-text">
                  {" "}
                  Inscrit depuis le{" "}
                  <b>
                    {moment.default(new Date(date_of_creation)).format("L")}
                  </b>{" "}
                </p>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <p className="profil-id-text">
                  {" "}
                  ID:{" "}
                  <b className="id" onClick={handleOpenFullProfil}>
                    {profil_id}
                  </b>{" "}
                </p>
              </Grid>
              {last_connection !== null && (
                <Grid item xs={12} sm={12} lg={8}>
                  <p className="profil-date-text">
                    {" "}
                    Dernière connexion le{" "}
                    <b>
                      {moment.default(new Date(last_connection)).format("L")}
                    </b>{" "}
                  </p>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={12}>
              <div className="profil-infos-name-age">
                {isMutualFavorite && (
                  <InlineIcon
                    icon="clarity:favorite-solid"
                    style={{ color: "orange", marginRight: "10px" }}
                  />
                )}
                {isSimpleFavorite && !isMutualFavorite && (
                  <InlineIcon
                    icon="clarity:favorite-line"
                    style={{ color: "orange", marginRight: "10px" }}
                  />
                )}
                {isOnline ? (
                  <div className="dot"></div>
                ) : (
                  <div className="dot offline"></div>
                )}
                <p className="profil-fullname"> {username} </p>
                <p className="profil-age">
                  {" "}
                  {age}
                  <small> ANS</small>{" "}
                </p>
              </div>
            </Grid>
            {has_conversation && (
              <Grid item xs={12} sm={12} lg={12}>
                <small style={{ color: "var(--ui-red)" }}>
                  {" "}
                  <InlineIcon icon="fluent-emoji-high-contrast:handshake" />{" "}
                  &nbsp;<b>Cet utilisateur est déjà en échange</b>{" "}
                </small>
              </Grid>
            )}
            {is_deleted && (
              <Grid item xs={12} sm={12} lg={12}>
                <small style={{ color: "var(--ui-red)" }}>
                  {" "}
                  <InlineIcon icon="streamline:interface-user-delete-actions-close-delete-deny-fail-geometric-human-person-remove-single-up-user" />{" "}
                  &nbsp;<b>Cet utilisateur a supprimé son compte</b>{" "}
                </small>
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={12}>
              <div className="tag-info-part">
                <div className="tag">
                  <InlineIcon icon="akar-icons:location" />
                  <p>
                    {" "}
                    <b>{town ? town : "--"}</b> / {country ? country : "--"}{" "}
                  </p>
                </div>
                <div className="tag">
                  <InlineIcon icon="el:globe" />
                  {origine ? (
                    <p>
                      {" "}
                      ORIGINE <b>{origine}</b>{" "}
                    </p>
                  ) : (
                    <p> -- </p>
                  )}
                </div>
                <div className="tag">
                  <InlineIcon icon="icon-park-outline:passport" />
                  {nationalite ? (
                    <p>
                      {" "}
                      NATIONALITE <b>{nationalite}</b>{" "}
                    </p>
                  ) : (
                    <p> -- </p>
                  )}
                </div>
              </div>
            </Grid>
            {sendStatut === "received" && cloture === null && (
              <Grid item xs={12} sm={12} lg={12}>
                <div className="btn-container">
                  <LoadingButton
                    loading={loadingAccept}
                    onClick={acceptExchange}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                  >
                    Accepter
                  </LoadingButton>
                  <LoadingButton
                    loading={loadingReject}
                    loadingPosition="center"
                    onClick={handleOpenDenyRequest}
                    variant="outlined"
                    color="primary"
                  >
                    Refuser
                  </LoadingButton>
                </div>
              </Grid>
            )}
            {sendStatut === "sent" && cloture === null && (
              <Grid item xs={12} sm={12} lg={12}>
                <div className="btn-container">
                  <LoadingButton
                    loading={loadingCancel}
                    loadingPosition="center"
                    onClick={() => {
                      cancelDemandFct(profil_id, username!);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Annuler
                  </LoadingButton>
                  {!is_deleted && (
                    <LoadingButton
                      loading={loadingRemind}
                      loadingPosition="center"
                      onClick={remindExchange}
                      variant="contained"
                      color="primary"
                    >
                      Faire une relance
                    </LoadingButton>
                  )}
                </div>
              </Grid>
            )}
            {cloture !== null && (
              <Grid item xs={12} sm={12} lg={12}>
                <div className="cloture-part">
                  <p>
                    {" "}
                    Échange clôturé par &nbsp;<b>{cloture.emitter}</b>&nbsp;
                    pour le motif : &nbsp;<b>{cloture.motif}</b>&nbsp;{" "}
                  </p>
                  <div className="bottom-container">
                    <Button
                      variant="contained"
                      onClick={handleOpenModal}
                      startIcon={<InlineIcon icon="ic:round-history" />}
                      color="tertiary"
                    >
                      Historique des conversations
                    </Button>
                    <small>
                      {" "}
                      Échange fermé le <b> {cloture.date_cloture} </b>{" "}
                    </small>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Box>
        {hasMore && !isCloture && (
          <Grow
            in={!reveal}
            style={{ transformOrigin: "0 0 0" }}
            {...(!reveal ? { timeout: 1000 } : {})}
          >
            <div className="more-detail" onClick={toggleReveal}>
              <InlineIcon icon="carbon:add-alt" />
            </div>
          </Grow>
        )}

        {alt && !no_corner_option && (
          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            {...(!reveal ? { timeout: 1000 } : {})}
          >
            <div
              className="remove-favorite"
              onClick={() => {
                isListeNoire
                  ? handleOpenRemoveBlacklist()
                  : handleOpenRemoveFavorite();
              }}
            >
              <InlineIcon icon="fluent:delete-32-regular" />
            </div>
          </Grow>
        )}

        {sendStatut === "sent" && !isCloture && (
          <Grow
            in={!reveal}
            style={{ transformOrigin: "0 0 0" }}
            {...(!reveal ? { timeout: 1000 } : {})}
          >
            <div className="send-favorite">
              <InlineIcon icon="carbon:mail-reply" />
            </div>
          </Grow>
        )}

        {sendStatut === "received" && !isCloture && (
          <Grow
            in={!reveal}
            style={{ transformOrigin: "0 0 0" }}
            {...(!reveal ? { timeout: 1000 } : {})}
          >
            <div className="received-favorite">
              <InlineIcon icon="carbon:mail-reply" flip="horizontal" />
            </div>
          </Grow>
        )}

        <Collapse in={reveal}>
          <>
            <div className="detail-part">
              <p className="title-text"> Description </p>
              <TextDisplayer
                className="detail-text-part"
                rows={4}
                value={description || ""}
              />
              <p className="title-text" style={{ marginTop: "20px" }}>
                {" "}
                Profil recherché{" "}
              </p>
              <TextDisplayer
                className="detail-text-part"
                rows={4}
                value={profile_wanted || ""}
              />
            </div>
            <div className="bottom-part">
              <Grow
                in={reveal}
                style={{ transformOrigin: "0 0 0" }}
                {...(reveal ? { timeout: 300 } : {})}
              >
                <div className="less-detail" onClick={toggleReveal}>
                  <InlineIcon icon="akar-icons:chevron-up" />
                </div>
              </Grow>

              <Grow
                in={reveal}
                style={{ transformOrigin: "0 0 0" }}
                {...(reveal ? { timeout: 400 } : {})}
              >
                <div className="btn-part">
                  <Button
                    color="secondary"
                    className="start"
                    variant="contained"
                    onClick={handleOpenFullProfil}
                    startIcon={<InlineIcon icon="carbon:user-profile" />}
                  >
                    Profil complet
                  </Button>
                </div>
              </Grow>

              {!isExchange && (
                <Grow
                  in={reveal}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(reveal ? { timeout: 1000 } : {})}
                >
                  <div className="btn-part">
                    <LoadingButton
                      loading={loading1}
                      loadingPosition="center"
                      color="secondary"
                      variant="contained"
                      onClick={startExchange}
                      startIcon={
                        <InlineIcon icon="bx:message-rounded-detail" />
                      }
                    >
                      Entrer en échange
                    </LoadingButton>
                  </div>
                </Grow>
              )}

              <Grow
                in={reveal}
                style={{ transformOrigin: "0 0 0" }}
                {...(reveal ? { timeout: 1600 } : {})}
              >
                <div className="btn-part">
                  <Button
                    color="secondary"
                    className="end"
                    variant="contained"
                    onClick={handleOpenSignal}
                    startIcon={<InlineIcon icon="akar-icons:circle-alert" />}
                  >
                    Signaler
                  </Button>
                </div>
              </Grow>
            </div>
          </>
        </Collapse>
      </div>

      {/* FULL PROFIL */}
      <Modal
        open={openFullProfil}
        onClose={handleCloseFullProfil}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <FullProfil
            closeModal={handleCloseFullProfil}
            isOnline={isOnline}
            isVerified={isVerified}
            hasMore={hasMore}
            username={username}
            type={type}
            age={age}
            town={town}
            country={country}
            origine={origine}
            profil_id={profil_id}
            nationalite={nationalite}
            progressValue={progressValue}
            date_of_creation={date_of_creation}
            sendStatut={sendStatut}
          />
        </>
      </Modal>
      {/* End of FULL PROFIL */}

      {/* SIGNAL */}
      <Modal
        open={openSignal}
        onClose={handleCloseSignal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form className="form-raison-part" onSubmit={formik.handleSubmit}>
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseSignal}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison du signalement"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formik.handleChange}
              value={formik.values.motif}
              error={formik.touched.motif && Boolean(formik.errors.motif)}
              helperText={formik.touched.motif && formik.errors.motif}
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseSignal}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    SIGNALER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of SIGNAL */}

      {/* SIGNALER  DIALOG PART */}
      <Dialog
        open={openConfirmedSignal}
        onClose={handleCloseConfirmedSignal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Signalement"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Attention!!!</b> cette action est irréversible.
            <br />
            Vous êtes sur le point de <b>signaler</b> ce profil. Êtes-vous
            sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmedSignal}>Annuler</Button>
          <LoadingButton
            id="id-signal-btn"
            loading={loading2}
            loadingPosition="center"
            onClick={signalMember}
            autoFocus
          >
            Signaler
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of SIGNALER  DIALOG PART */}

      {/* DENY REQUEST */}
      <Modal
        open={openDenyRequest}
        onClose={handleCloseDenyRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form
            className="form-raison-part"
            onSubmit={formikDenyRequest.handleSubmit}
          >
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseDenyRequest}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison du refus"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formikDenyRequest.handleChange}
              value={formikDenyRequest.values.motif}
              error={
                formikDenyRequest.touched.motif &&
                Boolean(formikDenyRequest.errors.motif)
              }
              helperText={
                formikDenyRequest.touched.motif &&
                formikDenyRequest.errors.motif
              }
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseDenyRequest}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    REFUSER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of DENY REQUEST */}

      {/* DENY REQUEST  DIALOG PART */}
      <Dialog
        open={openConfirmedDenyRequest}
        onClose={handleCloseConfirmedDenyRequest}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Refus de demande"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Attention!!!</b> cette action est irréversible.
            <br />
            Vous êtes sur le point de <b>refuser</b> la demande d'échange de ce
            profil. Êtes-vous sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmedDenyRequest}>Annuler</Button>
          <LoadingButton
            id="id-signal-btn"
            loading={loading2}
            loadingPosition="center"
            onClick={() => {
              rejectDemandFct(
                profil_id,
                username!,
                formikDenyRequest.values.motif
              );
            }}
            autoFocus
          >
            Refuser
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of DENY REQUEST  DIALOG PART */}

      {/* RETIRER FAVORIS  DIALOG PART */}
      <Dialog
        open={openRemoveFavorite}
        onClose={handleCloseRemoveFavorite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Retirer des favoris"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <b>Attention!!!</b> cette action est déliquate.<br /> */}
            Vous êtes sur le point de <b> retirer </b> ce profil de vos favoris.
            Êtes-vous sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveFavorite}>Annuler</Button>
          <LoadingButton
            loading={loadingRemove}
            loadingPosition="center"
            variant="contained"
            color="primary"
            id="id-remove-favorite-btn"
            onClick={() => {
              removeFromFavoriteFct(profil_id, username!);
              handleCloseRemoveFavorite();
            }}
            type="submit"
          >
            {" "}
            Retirer{" "}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of RETIRER FAVORIS  DIALOG PART */}

      {/* RETIRER LISTE NOIRE  DIALOG PART */}
      <Dialog
        open={openRemoveBlacklist}
        onClose={handleCloseRemoveBlacklist}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Retirer de la liste noire"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <b>Attention!!!</b> cette action est déliquate.<br /> */}
            Vous êtes sur le point de <b> retirer </b> ce profil de votre liste
            noire. Êtes-vous sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveBlacklist}>Annuler</Button>
          <LoadingButton
            loading={loadingRemoveB}
            loadingPosition="center"
            variant="contained"
            color="primary"
            id="id-remove-blacklist-btn"
            onClick={() => {
              removeFromBlacklistFct(profil_id, username!);
              handleCloseRemoveBlacklist();
            }}
            type="submit"
          >
            {" "}
            Retirer{" "}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of RETIRER LISTE NOIRE  DIALOG PART */}

      {/* HISTORIQUE CONVERSATION */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <HistoriqueConversation
            closeModal={handleCloseModal}
            conversation_id={conversation_id}
          />
        </>
      </Modal>
      {/* End of HISTORIQUE CONVERSATION */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const ProfileCard = connector(ProfileCardFct);

export default ProfileCard;
