
import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationCard from '../../Elements/NotificationCard/NotificationCard';
import * as _ from "lodash";
import "./Notification.styles.scss";
import NoData from '@/components/No-data/NoData';
import { selectUserConnected, selectToken } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TNotification } from '../../types';
import { store } from '@/store';
import { updateNbreNotificationSocket } from '@/store/reducers/general_param/general_param.actions';
import { socketChannels } from '@/config/socketChannels';
import { AppSocketContext } from '@/routes';
import { apiGetNotification, apiRemoveNotification } from '../../api/notification.api';
import { Toastify } from '@/utils/toast';

type PropsFromRedux = ConnectedProps<typeof connector>;

const CtNotificationsFct: React.FC<PropsFromRedux> = ({
  userConnected,
  token,
}) => {

  const { socketIO } = useContext(AppSocketContext);

  const [notifArr, setNotifArr] = useState<TNotification[]>([]);
  const [loading, setLoading] = useState(false);
  

  const fetchMoreData = () => {
    console.log('fetchmore');
  };

  const removeItem = (notification_id: string) => {
    
    apiRemoveNotification(notification_id, token).then((response: any) => {

      console.log(response);

      let tempArr = notifArr;
      let removedItem = _.remove(tempArr, (x) => {
        return x.id === notification_id;
      })
      console.log(removedItem);
      setNotifArr([...tempArr]);

      Toastify(`Notification supprimée avec succès`, 'success', 5000);

    }).catch((error) => {
      console.log('💌',notification_id);
      Toastify(`${error?.response?.data?.message}`, 'error', 5000);
    });

  }

  useEffect(() => {

    setLoading(true);
    apiGetNotification(token).then((response: any) => {
      setLoading(false);
      console.log(response);

      let tempList: TNotification[] = response.items.map((data: any) => {
        return {
          id: data.id,
          body: data.body,
          date: data.date,
          sender: data.sender,
          subject: data.subject,
        }
      });

      setNotifArr([...tempList]);
      store.dispatch(updateNbreNotificationSocket(response.count));

    }).catch((error) => {
      setLoading(false);
      //setNotifArr([...(userConnected as TUserConnected).notifications]);// <-< à enlever après c'est temporaire
      Toastify(`${error?.response?.data?.message}`, 'error', 5000);
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (socketIO) {
      socketIO.on(socketChannels.notification_channel, data => {
        console.log('Socket Notifications ✌️', data);
        setNotifArr([...notifArr, {
          ...data
        }]);
      })
    }
    return () => {
      if (socketIO) {
        socketIO.off(socketChannels.notification_channel);
        store.dispatch(updateNbreNotificationSocket(0));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, notifArr])


  return (
    <div className='ct-notification'>
      <PerfectScrollbar id="scrollableDiv"  >
        <InfiniteScroll
          className="ct-notification__body-part"
          scrollableTarget="scrollableDiv"
          dataLength={notifArr.length}
          next={fetchMoreData}
          hasMore={false}
          loader={
            notifArr.length > 0 ?
              <img src={require("@/assets/images/gif/loadmore.gif")}
                className="load-more"
                alt="load-more" />
              :
              <NoData />
          }
        >
          <>
            {
                loading &&
                <img  src={require("@/assets/images/gif/loadmore.gif")}
                    height="70"
                    width="70"
                    className="load-more"
                    alt="load-more" /> 
            }
            {
              !loading &&
                (notifArr.length === 0) ?
                  <NoData />
                :
                notifArr.map((value, index) => {
                  return <NotificationCard
                    key={index}
                    onClose={() => { removeItem(value.id as string) }}
                    title={value.subject}
                    message={value.body}
                    date={value.date} />
                })
            }
          </>

        </InfiniteScroll>
      </PerfectScrollbar>
    </div>
  )
}



const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});


const connector = connect(mapStateToProps);
const CtNotifications = connector(CtNotificationsFct);

export default CtNotifications;
