export enum general_paramTypes {
  UPDATE_ETHNIES = "UPDATE_ETHNIES",
  UPDATE_SEARCH_QUERY = "UPDATE_SEARCH_QUERY",
  UPDATE_FORM_QUERY = "UPDATE_FORM_QUERY",
  UPDATE_OPEN_DISCUSSION = "UPDATE_OPEN_DISCUSSION",
  UPDATE_CURRENT_EXCHANGE = "UPDATE_CURRENT_EXCHANGE",
  UPDATE_MAX_MESSAGE = "UPDATE_MAX_MESSAGE",
  UPDATE_NBRE_MESSAGE_SENT = "UPDATE_NBRE_MESSAGE_SENT",
  UPDATE_NBRE_MESSAGE_RECEIVE = "UPDATE_NBRE_MESSAGE_RECEIVE",
  UPDATE_MODERATION_SOCKET = "UPDATE_MODERATION_SOCKET",
  UPDATE_NBRE_NOTIFICATION_SOCKET = "UPDATE_NBRE_NOTIFICATION_SOCKET",
  UPDATE_NBRE_LIVE_SOCKET = "UPDATE_NBRE_LIVE_SOCKET",
  UPDATE_MAX_CARACTERE = "UPDATE_MAX_CARACTERE",
  UPDATE_MAIL_MALE = "UPDATE_MAIL_MALE",
  UPDATE_MAIL_FEMALE = "UPDATE_MAIL_FEMALE",
  UPDATE_GENERAL_LINKS = "UPDATE_GENERAL_LINKS",
  UPDATE_IS_MODERATION_SUSPEND = "UPDATE_IS_MODERATION_SUSPEND",
}

export default general_paramTypes;
