import Axios from "axios";
import { API_URL } from "../config";
import { persistor, store } from "@/store";
import { apiGetUserConnected } from "@/features/auth/api/auth.api";
import { getToken, getUserConnected } from "@/store/reducers/member/member.actions";
import { TUserConnected } from "@/features/profile/types";

export const axios = Axios.create({
  baseURL: API_URL,
});


axios.interceptors.response.use(
  (response:any) => {
    return response.data;
  },
  (error) => {

    const code = error.response?.data?.code || error.code;
    const message = error.response?.data?.message || error.message;
    console.log(message);

    if (code === 'membre_introuvable') {
        console.log('deco deco');
        //window.location.href='/';
        store.dispatch({ type: 'CLEAR_STORE' });
        localStorage.clear();
        store.dispatch(getToken(''));
        persistor.purge();
    }

    if (code === 'Failed') {
      //window.location.href='/';
      store.dispatch({ type: 'CLEAR_STORE' });
      localStorage.clear();
      store.dispatch(getToken(''));
      persistor.purge();
    }

    if (code === 'utilisateur_non_autorisé') {
      const token = store.getState().member.token ;
    
      apiGetUserConnected(token).then((response:any)=> {    
         let userConnected = response.data;  
         store.dispatch( getUserConnected( userConnected as TUserConnected ) );
       }).catch((error)=> {
          console.log('😜', error);
      })
    }

    if(error.response.status === 403){

      const token = store.getState().member.token ;
    
      apiGetUserConnected(token).then((response:any)=> {    
         let userConnected = response.data;  
         store.dispatch( getUserConnected( userConnected as TUserConnected ) );
       }).catch((error)=> {
          console.log('😜', error);
      })

    }


    

    return Promise.reject(error);
  }
);


