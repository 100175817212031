
export const socketChannels = {
    user_is_moderation_open: "user/is-moderation-open",
    member_add_message: "member/add-message",
    member_conversation_messages: "member/conversation-messages",
    member_profile_has_changed: "member/profile-has-changed",
    notification_channel : "member/new-news-message",
    live_channel: "user/new-live-session",
    interface_setting_channel: "user/get_setting", 
    current_exchange_channel: "current_exchange_channel",

    new_favorite_notif: "new_favorite_notif",
    new_demand_exchange_notif: "new_demand_exchange_notif",
    accept_demand_channel: "accept_demand_channel",
    cloture_demand_channel: "cloture_demand_channel",
}

