import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox, FormHelperText, ListItemText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const getStyles = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type MultipleSelectChipProps = {
    labelId:string,
    label:string,
    error?: boolean | undefined,
    errorText?: string | string[] | undefined,
    name?: string | undefined,
    value?: "" | string[] | undefined,
    selectOptions: {id:string,name:string}[] | string[] ,
    onChange: (event: SelectChangeEvent<string[]>) => void
    
}

export const  MultipleSelectChip:React.FC<MultipleSelectChipProps> = ({
    labelId,
    label,
    error,
    errorText,
    name,
    value,
    selectOptions,
    onChange
}) => {
  const theme = useTheme();

  const [options, setOptions] = React.useState<string[]>([]);

  const handleOption = (event: SelectChangeEvent<typeof options>) => {
      const { target: { value } } = event;
      setOptions(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
      );
  };

  return (
      <FormControl fullWidth
            error ={error}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
            labelId={labelId}
            multiple

            name={name}
            label={label}
            onChange={(e)=> {
                onChange(e);
                handleOption(e);
              }}
            value={value}
            input={<OutlinedInput id="select-multiple-chip" label={label} />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value,index) => (
                    <Chip key={index} 
                          label={
                            (selectOptions as {id:string,name:string}[])[0].id ?
                              (selectOptions as {id:string,name:string}[]).find(x=> x.id === value)?.name
                            :
                              value
                          } 
                          color="primary" />
                ))}
                </Box>
            )}
            MenuProps={MenuProps}
        >
          {selectOptions.map((elt:any,index) => (
                [
                        <MenuItem
                        key={index}
                        value={elt.id || elt}
                        style={getStyles(elt.name || elt, (value as string[]) , theme)}
                        >
                            <Checkbox checked={options.indexOf(elt.id || elt) > -1} />
                            <ListItemText primary={elt.name || elt} />
                        </MenuItem>
                ]
          ))}
        </Select>
        <FormHelperText 
            error={error}>
            {errorText}
        </FormHelperText>
      </FormControl>
  );
}
