import { Box, Button, Grid, Grow, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./Fonctionnement.styles.scss";

type TActiveOperation = "0" | "1" | "2" | "3" | "4" | "5" | "6";

const CtFonctionnement: React.FC = () => {
  const [activeButton, setActiveButton] = useState<TActiveOperation>("0");
  const ps = useRef();

  const scrollTop = () => {
    const curr = (ps as React.MutableRefObject<any>).current;
    if (curr) {
      console.log("oohh");
      curr.scrollTop = 0;
    }
  };

  return (
    <PerfectScrollbar
      className="ct-fonctionnement"
      containerRef={(el) => ((ps as React.MutableRefObject<any>).current = el)}
    >
      <Box>
        <Grid container rowSpacing={4} columnSpacing={2}>
          {/* Video part */}
          <Grid item xs={12} sm={12} lg={12}>
            {activeButton === "0" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Présentation de la plateforme{" "}
                </Typography>
                <Grow in={activeButton === "0"}>
                  <iframe
                    src="https://player.vimeo.com/video/805500174 "
                    title="Présentation de la plateforme "
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
            {activeButton === "1" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Comment remplir son profil ?{" "}
                </Typography>
                <Grow in={activeButton === "1"}>
                  <iframe
                    src="https://player.vimeo.com/video/805502108"
                    title="Comment remplir son profil ?"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
            {activeButton === "2" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Comment faire les causes ?{" "}
                </Typography>
                <Grow in={activeButton === "2"}>
                  <iframe
                    src="https://player.vimeo.com/video/805510844"
                    title="Comment faire les causes ?"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
            {activeButton === "3" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Comment faire une recherche ?{" "}
                </Typography>
                <Grow in={activeButton === "3"}>
                  <iframe
                    src="https://player.vimeo.com/video/805508859"
                    title="Comment faire une recherche ?"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
            {activeButton === "4" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Comment gérer les favoris ?{" "}
                </Typography>
                <Grow in={activeButton === "4"}>
                  <iframe
                    src="https://player.vimeo.com/video/805512096 "
                    title="Comment gérer les favoris ?"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
            {activeButton === "5" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Comment faire des échanges ?{" "}
                </Typography>
                <Grow in={activeButton === "5"}>
                  <iframe
                    src="https://player.vimeo.com/video/805506720"
                    title="Comment faire des échanges ?"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
            {activeButton === "6" && (
              <>
                <Typography variant="caption" color="secondary">
                  {" "}
                  Comment sommes-nous en contact?{" "}
                </Typography>
                <Grow in={activeButton === "6"}>
                  <iframe
                    src="https://player.vimeo.com/video/805504653"
                    title="Comment sommes-nous en contact?"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </Grow>
              </>
            )}
          </Grid>

          {/* Button part */}
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "0" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("0");
                scrollTop();
              }}
              color="primary"
            >
              Présentation de <br /> la plateforme
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "1" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("1");
                scrollTop();
              }}
              color="primary"
            >
              Comment remplir <br /> son profil ?
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "2" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("2");
                scrollTop();
              }}
              color="primary"
            >
              Comment faire <br /> les causes?
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "3" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("3");
                scrollTop();
              }}
              color="primary"
            >
              Comment faire <br /> une recherche ?
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "4" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("4");
                scrollTop();
              }}
              color="primary"
            >
              Comment gérer <br /> les favoris ?
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "5" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("5");
                scrollTop();
              }}
              color="primary"
            >
              Comment faire <br /> des échanges ?
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Button
              variant={activeButton === "6" ? "outlined" : "contained"}
              onClick={() => {
                setActiveButton("6");
                scrollTop();
              }}
              color="primary"
            >
              Comment sommes-nous <br /> en contact ?
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PerfectScrollbar>
  );
};

export default CtFonctionnement;
