import ChatMessage, {
  TChatMessageProps,
} from "@/components/ChatMessage/ChatMessage";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { InlineIcon } from "@iconify/react";
import { HighlightOffRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./HistoriqueConversation.style.scss";
import { apiGetConversation } from "@/features/profile/api/exchange.api";
import { Toastify } from "@/utils/toast";

type THistoriqueConversationProps = {
  closeModal: () => void;
  conversation_id: string;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const HistoriqueConversationFct: React.FC<
  THistoriqueConversationProps & PropsFromRedux
> = ({ closeModal, token, conversation_id }) => {
  const [discussionList, setDiscussionList] = useState<TChatMessageProps[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadConversation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConversation = () => {
    setLoading(true);

    apiGetConversation(conversation_id, token)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        setDiscussionList([...response.messages]);
      })
      .catch((error) => {
        setLoading(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <div className="historique-conversation-scroll-container">
      <div className="historique-conversation-card">
        <IconButton
          className="close-icon"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          <HighlightOffRounded />
        </IconButton>

        <img src={require("@/assets/images/png/logo-mini.png")} alt="logo" />

        <p className="m-title">
          <InlineIcon icon="tabler:tilde" flip="horizontal" />
          &nbsp; Historique de conversation &nbsp;
          <InlineIcon icon="tabler:tilde" />
        </p>

        <div className="discussion-container">
          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            discussionList.map((msg, index) => {
              return (
                <ChatMessage
                  key={index}
                  message={msg.message}
                  reason={msg.reason}
                  fromStaff={msg.fromStaff}
                  pending={msg.pending}
                  isValid={msg.isValid}
                  type={msg.type}
                  gender={msg.gender}
                  time={msg.time}
                  isRead={msg.isRead}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const HistoriqueConversation = connector(HistoriqueConversationFct);

export default HistoriqueConversation;
