import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import { InlineIcon } from "@iconify/react";
import { Button, IconButton } from '@mui/material';
import { HighlightOffRounded } from '@mui/icons-material';
import { selectUserConnected } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TUserConnected } from '@/features/profile/types';
import './ModalRemindPay.styles.scss';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/config';



type TModalProps = {
  open: boolean,
  type: 'abonnement' | 'reabonnement',
  fromAccount?: boolean,
}
type PropsFromRedux = ConnectedProps<typeof connector>;


const CModalRemindPayFct: React.FC<TModalProps & PropsFromRedux> = ({
  open,
  type,
  userConnected,
  fromAccount = false,
}) => {

  const [openM, setOpenM] = useState(open);
  const navigate = useNavigate();

  const proceedPayment = () => {
    setOpenM(false);

    if(!fromAccount){
      if (!(userConnected as TUserConnected).account.is_active && !(userConnected as TUserConnected).account.is_approved) {
        navigate(routePaths.profileChildren.myAccountAbsolute, { replace: true });

      } else if ((typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string') ) {

        navigate(routePaths.profileChildren.myAccountAbsolute, { replace: true });
      }
      else {

        navigate(routePaths.profileChildren.subscriptionChildren.desactivateSubscriptionAbsolute, { replace: true });

      }
    }else {
      if(
        (userConnected as TUserConnected).account.is_approved &&
        !(
          (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string')
        )
        ){
        navigate(routePaths.profileChildren.subscriptionChildren.desactivateSubscriptionAbsolute, { replace: true });
      }
    }

  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openM}
      onClose={proceedPayment}
    >
      <Grow in={openM}>
        <div className='c-modal-remind-pay '>
          <IconButton className='close-icon'
            size="large"
            color='inherit'
            onClick={proceedPayment}>
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require("@/assets/images/png/avatar-" + (userConnected as TUserConnected).infos.gender + ".png")}
            alt="sidebar-logo"
            className="modal-avatar"
          />

          {
            // (userConnected as TUserConnected).account.is_deleted &&
            // ((userConnected as TUserConnected).account.reason_of_desactivation_by_admin === null) &&
            // <>
            //   <h3><b> EN ATTENTE DE SUPPRESSION </b></h3>
            //   <br />
            //   <p>
            //     Votre compte en cours de suppression au près de nos modérateurs
            //     <br />
            //     Après suppression, pour profiter pleinement de l'application à nouveau vous devrez vous réinscrire.&nbsp;<br />
            //     <b> Zawaj Sunnah vous remercie d'avoir été des notres.</b>
            //   </p>
            // </>
          }

          {
            // blocage pour mauvais comportement
            (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string')  
            &&
            <>
              <h3><b> ACCÈS REFUSÉ </b></h3>
              <br />

              <p>
                <span className="bold">COMPTE UTILISATEUR </span> <span className="bold danger">DÉSACTIVÉ</span>
                <br />
                <span className="bold danger">Votre compte a été désactivé pour non respect de notre règlement </span>
                <br />
                <br />
                Notre équipe de modération vous a fait la remarque suivante :<br />
                <span className="bold">{(userConnected as TUserConnected).account.reason_of_desactivation_by_admin}</span>
                <br />
                <br />
                Vous pouvez contacter l'administration : &nbsp;<br />
                <a href="mailto:Zawaj.sounnah@gmail.com" target="_blank" rel="noreferrer">
                  <b>Zawaj.sounnah@gmail.com</b>
                </a>
                <br />
                <br />
                <br />
                <a href="https://zawaj-sounnah.com/" target="_blank" rel="noreferrer" className="link-gray">Aller sur zawaj-sounnah.com</a>
              </p>
            </>
          }

          {
            !(userConnected as TUserConnected).account.is_active &&
            !(userConnected as TUserConnected).account.is_approved &&
            !(
              (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string')
            )&&
            <>
              <h3><b> EN ATTENTE DE VALIDATION </b></h3>
              <br />
              <p>
                Votre compte  en cours de validation ( <b> rassurez-vous d'avoir bien rempli votre profil </b> ) au près de nos modérateurs
                <br />
                Après validation, pour profiter pleinement de l'application vous devrez procéder au &nbsp;
                <b>  paiement de votre abonnement</b>
                <br />
                Cependant nous vous invitons à voir les vidéos de &nbsp;
                <b onClick={() => {
                  navigate(routePaths.profileChildren.fonctionnementAbsolute, { replace: true });
                }}> fonctionnement du site </b>
              </p>
            </>
          }

          {
            type === 'abonnement' &&
            (userConnected as TUserConnected).account.is_approved &&
            !(
              (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string')
            )&&
            <>
              <h3><b> ABONNEMENT </b></h3>
              <br />
              <p>
                Votre compte a été validé par nos modérateurs
                <br />
                Pour profiter pleinement de l'application veuillez procéder au &nbsp;
                <b>  paiement de votre abonnement</b>
              </p>

            </>
          }

          {
            type === 'reabonnement' &&
            (userConnected as TUserConnected).account.is_approved &&
            !(
              (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string')
            )&&
            <>
              <h3><b> RÉABONNEMENT </b></h3>
              <br />
              <p>
                Votre abonnement est soit arrivé à expiration soit inexistant.
                <br />
                Pour profiter pleinement de l'application veuillez procéder au &nbsp;
                <b>paiement de votre abonnement</b>
              </p>

            </>
          }

          {
            (userConnected as TUserConnected).account.is_approved
            &&
            !(
              (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string')
            ) &&
            !(userConnected as TUserConnected).account.is_subscription_active &&
            <Button
              className='btn-modal'
              variant="contained"
              color="primary"
              onClick={proceedPayment}
              endIcon={
                <InlineIcon icon="fluent:payment-32-regular" />
              }
            >
              Procéder au paiement
            </Button>
          }


        </div>
      </Grow>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected
});


const connector = connect(mapStateToProps);
const CModalRemindPay = connector(CModalRemindPayFct);

export default CModalRemindPay;