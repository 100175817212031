import { InlineIcon } from "@iconify/react";
import {
  Button,
  Chip,
  InputAdornment,
  SpeedDial,
  SpeedDialAction,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Live.styles.scss";
import LiveCard, { TLiveCard } from "../../Elements/LiveCard/LiveCard";
import NoData from "@/components/No-data/NoData";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import { TUserConnected } from "../../types";
import { apiGetLive } from "../../api/live.api";
import { Toastify } from "@/utils/toast";
import * as moment from "moment";
import "moment/locale/fr";
import { updateNbreLiveSocket } from "@/store/reducers/general_param/general_param.actions";
import { store } from "@/store";
import { socketChannels } from "@/config/socketChannels";
import { AppSocketContext } from "@/routes";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

type TFilteredValue = "à venir" | "passés" | "En direct" | "";

type PropsFromRedux = ConnectedProps<typeof connector>;
const CtLiveFct: React.FC<PropsFromRedux> = ({ userConnected, token }) => {
  const { socketIO } = useContext(AppSocketContext);

  const [activeButton, setActiveButton] = useState<TFilteredValue>("");

  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchedText, setSearchedText] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
  };

  const actions = [
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Tout" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Passés" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "En_direct" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "À_venir" },
  ];

  const [loading, setLoading] = useState(false);
  const [fakeItems, setFakeItems] = useState<TLiveCard[]>([]);
  const [page, setPage] = useState(1);
  const [hasmore, setHasmore] = useState(true);

  const fetchMoreData = () => {
    setPage(page + 1);
    console.log(page);
    //setLoading(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetLive(token, page, 6)
        .then((response: any) => {
          //setLoading(false);
          console.log(response);

          if (page === response.number_of_pages) {
            setHasmore(false);
          }

          let tempList: TLiveCard[] = response.items.map((data: any) => {
            return {
              title: data.subject,
              host: data.speaker.name,
              type:
                moment
                  .default(data.date_time)
                  .diff(moment.default(new Date()), "hours") > 0
                  ? "à venir"
                  : moment
                      .default(data.date_time)
                      .diff(moment.default(new Date()), "hours") === 0
                  ? "En direct"
                  : "passés",
              link: data.link,
              note: data.note,
              live_date: data.date_time,
            };
          });

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          //setLoading(false);
          //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
        });
  };

  useEffect(() => {
    store.dispatch(updateNbreLiveSocket(0));

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      setLoading(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetLive(token, 1, 6)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList: TLiveCard[] = response.items.map((data: any) => {
            return {
              title: data.subject,
              host: data.speaker.name,
              type:
                moment
                  .default(data.date_time)
                  .diff(moment.default(new Date()), "hours") > 0
                  ? "à venir"
                  : moment
                      .default(data.date_time)
                      .diff(moment.default(new Date()), "hours") === 0
                  ? "En direct"
                  : "passés",
              link: data.link,
              note: data.note,
              live_date: data.date_time,
            };
          });

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          setLoading(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketIO) {
      socketIO.on(socketChannels.live_channel, (data) => {
        console.log("Socket Lives ✌️", data);

        setFakeItems([
          ...fakeItems,
          {
            title: data.subject,
            host: data.speaker.name,
            type:
              moment
                .default(data.date_time)
                .diff(moment.default(new Date()), "hours") > 0
                ? "à venir"
                : moment
                    .default(data.date_time)
                    .diff(moment.default(new Date()), "hours") === 0
                ? "En direct"
                : "passés",
            link: data.link,
            note: data.note,
            live_date: data.date_time,
          },
        ]);
      });
    }
    return () => {
      if (socketIO) {
        socketIO.off(socketChannels.live_channel);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, fakeItems]);

  return (
    <>
      {(userConnected as TUserConnected).account.is_active && (
        <div className="ct-live">
          <div className="ct-live__filter-part">
            <TextField
              placeholder="Titre du live / Nom de l'hôte"
              variant="outlined"
              label="Rechercher"
              name="recherche"
              className="searchInput"
              type="text"
              value={searchedText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            {matches_sm && (
              <>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  className="ct-live__filter-part__speed-dial"
                  direction="down"
                  icon={
                    <InlineIcon icon="ep:filter" style={{ fontSize: "20px" }} />
                  }
                >
                  {actions.map((action, index) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      onClick={() => {
                        if (index === 0) {
                          // Tout
                          setActiveButton("");
                        }
                        if (index === 1) {
                          // Imam
                          setActiveButton("passés");
                        }
                        if (index === 2) {
                          // Prédicateur
                          setActiveButton("En direct");
                        }
                        if (index === 3) {
                          // Prédicateur
                          setActiveButton("à venir");
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
                {activeButton !== "" && (
                  <Chip
                    label={activeButton.toUpperCase()}
                    onDelete={() => {
                      setActiveButton("");
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
              </>
            )}

            {!matches_sm && (
              <>
                <Button
                  variant={activeButton === "passés" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("passés");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Passés
                </Button>
                <Button
                  variant={
                    activeButton === "En direct" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("En direct");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  En direct
                </Button>
                <Button
                  variant={
                    activeButton === "à venir" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("à venir");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  À venir
                </Button>
                <Button
                  variant={activeButton === "" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Tout
                </Button>
              </>
            )}
          </div>

          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            <PerfectScrollbar id="scrollableDiv">
              <InfiniteScroll
                className="ct-live__body-part"
                scrollableTarget="scrollableDiv"
                dataLength={fakeItems.length}
                next={fetchMoreData}
                hasMore={hasmore}
                loader={
                  fakeItems
                    .filter((value) => {
                      const fullname = value.title + value.host;
                      return (
                        fullname
                          .toLocaleLowerCase()
                          .indexOf(searchedText.toLocaleLowerCase()) !== -1
                      );
                    })
                    .filter((value) => {
                      return value.type.indexOf(activeButton) !== -1;
                    }).length > 0 ? (
                    <img
                      src={require("@/assets/images/gif/loadmore.gif")}
                      className="load-more"
                      alt="load-more"
                    />
                  ) : (
                    <NoData />
                  )
                }
              >
                {fakeItems
                  .filter((value) => {
                    const fullname = value.title + value.host;
                    return (
                      fullname
                        .toLocaleLowerCase()
                        .indexOf(searchedText.toLocaleLowerCase()) !== -1
                    );
                  })
                  .filter((value) => {
                    return value.type.indexOf(activeButton) !== -1;
                  })
                  .map((value, index) => {
                    return (
                      <LiveCard
                        key={index}
                        live_date={value.live_date}
                        link={value.link}
                        note={value.note}
                        title={value.title}
                        host={value.host}
                        type={value.type}
                      />
                    );
                  })}
              </InfiniteScroll>
            </PerfectScrollbar>
          )}
        </div>
      )}
      {(userConnected as TUserConnected).account.changes_requests_rejected && (
        <CModalRejectedModif
          open={
            (userConnected as TUserConnected).account.changes_requests_rejected
          }
        />
      )}
      <CModalRemindPay
        open={
          typeof (userConnected as TUserConnected).account
            .reason_of_desactivation_by_admin === "string"
            ? true
            : !(userConnected as TUserConnected).account.is_free &&
              (!(userConnected as TUserConnected).account.is_active ||
                !(userConnected as TUserConnected).account
                  .is_subscription_active)
        }
        type={
          (userConnected as TUserConnected).account.is_subscription_expired
            ? "reabonnement"
            : "abonnement"
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const CtLive = connector(CtLiveFct);

export default CtLive;
