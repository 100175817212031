import {
  TextField,
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Switch,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Toastify } from "@/utils/toast";
import * as yup from "yup";
import "./Aspect.step.styles.scss";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  TAdditionnalInfosReligiousAspect,
  TCountry,
  TUserConnected,
} from "@/features/profile/types";
import { InlineIcon } from "@iconify/react";
import { MultipleSelectChip } from "@/components/MultipleSelectChip/MultipleSelectChip";
import {
  apiSaveAspectFemale,
  apiSaveAspectMale,
} from "@/features/profile/api/profile.api";
import { store } from "@/store";
import {
  updateIsApproved,
  updateNewModificationSubmit,
  updateProgress,
  updateReligiousAspects,
} from "@/store/reducers/member/member.actions";
import classNames from "classnames";
import { checkTrueOrFalse } from "../../../utils/account.utils";

type PropsFromElement = {
  chooseStep: React.Dispatch<React.SetStateAction<number>>;
  scrollTop: () => void;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const AspectStepFct: React.FC<PropsFromRedux & PropsFromElement> = ({
  userConnected,
  token,
  chooseStep,
  scrollTop,
}) => {
  const [loading, setLoading] = useState(false);

  const tenues_vestimentaires_femmes_list = [
    "Ne porte pas encore le voile",
    "Porte le turban",
    "Porte le Hijab",
    "Porte le Jilbab",
    "Porte le Niqab",
    "L'Abaya",
  ];

  const [origine, setOrigine] = useState<TCountry>({
    country:
      (userConnected as TUserConnected).additional_infos.religious_aspects
        ?.hijra_country || "",
    abbreviation:
      (userConnected as TUserConnected).additional_infos.religious_aspects
        ?.hijra_country_code || "",
  });

  const handleOrigineChange = (event: any, value: TCountry | null) => {
    console.log(value);
    if (value == null) {
      setOrigine({ country: "", abbreviation: "" });
      formik.setFieldValue("pays_hijra", "");
    } else {
      setOrigine(value as TCountry);
    }
  };

  const [countries, setCountries] = React.useState<
    Array<{ country: string; abbreviation: string }>
  >([]);

  useEffect(() => {
    const countries_json_data = require("@/assets/json/countries.json");
    setCountries(countries_json_data);

    console.log(
      "Heyy",
      (userConnected as TUserConnected).additional_infos.religious_aspects
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tenue_vestimentaire_femmeArrayManager = (
    not_wear_headscarf: boolean,
    wear_turban: boolean,
    wear_hijab: boolean,
    wear_jilbab: boolean,
    wear_niqab: boolean,
    wear_abaya: boolean
  ): string[] => {
    let result: string[] = [];

    not_wear_headscarf && result.push("Ne porte pas encore le voile");
    wear_turban && result.push("Porte le turban");
    wear_hijab && result.push("Porte le Hijab");
    wear_jilbab && result.push("Porte le Jilbab");
    wear_niqab && result.push("Porte le Niqab");
    wear_abaya && result.push("L'Abaya");

    return result;
  };

  const originInputref = useRef(null);

  const validationSchema = yup.object({
    savants: yup
      .string()
      .required(" Les savants que vous suivez sont obligatoires "),
    predicateurs: yup
      .string()
      .required(" Les prédicateurs que vous écoutez sont obligatoires "),
    tenue_vestimentaire_homme: yup.string().test({
      name: "required_tenue_homme",
      message: " la tenue de l'homme est obligatoire",
      test: function (value) {
        let result = true;
        if (
          (userConnected as TUserConnected).infos.gender === "male" &&
          value === undefined
        ) {
          result = false;
        }
        return result;
      },
    }),
    tenue_vestimentaire_femme: yup.array().test({
      name: "required_tenue_femme",
      message: " la tenue de la femme est obligatoire",
      test: function (value) {
        let result = true;
        if (
          (userConnected as TUserConnected).infos.gender === "female" &&
          value?.length === 0
        ) {
          result = false;
        }
        return result;
      },
    }),
    pays_hijra: yup.string().when("origine", (val, schema) => {
      if (formik.values.hijra) {
        if (originInputref.current) {
          let origin_val =
            (originInputref as React.MutableRefObject<any>).current.value || "";
          if (origin_val === "") {
            return yup.string().required("le pays est obligatoire");
          } else if (getAbbreviation(origin_val) === "") {
            setOrigine({
              country: origin_val,
              abbreviation: getAbbreviation(origin_val),
            });
            return yup.string().test({
              name: "incorrect_country",
              message: " Ce pays n'est pas valide ",
              test: function (value) {
                let result = true;
                if (getAbbreviation(origin_val) === "") {
                  result = false;
                }
                return result;
              },
            });
          } else {
            setOrigine({
              country: origin_val,
              abbreviation: getAbbreviation(origin_val),
            });
            return yup.string().notRequired();
          }
        } else {
          return yup.string().required("le pays est obligatoire");
        }
      } else {
        return yup.string().notRequired();
      }
    }),
  });

  const formik = useFormik({
    initialValues: {
      salafi: checkTrueOrFalse(
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .is_salafi
      ),
      tenue_vestimentaire_femme:
        tenue_vestimentaire_femmeArrayManager(
          (userConnected as TUserConnected).additional_infos.religious_aspects
            .not_wear_headscarf,
          (userConnected as TUserConnected).additional_infos.religious_aspects
            .wear_turban,
          (userConnected as TUserConnected).additional_infos.religious_aspects
            .wear_hijab,
          (userConnected as TUserConnected).additional_infos.religious_aspects
            .wear_jilbab,
          (userConnected as TUserConnected).additional_infos.religious_aspects
            .wear_niqab,
          (userConnected as TUserConnected).additional_infos.religious_aspects
            .wear_abaya
        ) || [],
      tenue_vestimentaire_homme:
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .male_dress_code || "",
      savants:
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .favorite_scholars || "",
      predicateurs:
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .favorite_preachers || "",
      jurisprudence:
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .have_school_of_jurisprudence || "",
      hijra: checkTrueOrFalse(
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .is_in_hijra
      ),
      pays_hijra: origine.country || "",
      souhait_hijra: checkTrueOrFalse(
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .like_to_make_hijra
      ),
      temps_hijra:
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .when_to_make_hijra || "",
      demenager: checkTrueOrFalse(
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .agree_to_move
      ),
      accept_married_man: checkTrueOrFalse(
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .accept_married_man
      ),
      have_mahram: checkTrueOrFalse(
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .have_mahram
      ),
      mahram_phone_number:
        (userConnected as TUserConnected).additional_infos.religious_aspects
          .mahram_phone_number || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      if ((userConnected as TUserConnected).infos.gender === "female") {
        const dataToSend = {
          is_salafi: values.salafi,
          male_dress_code: "",
          not_wear_headscarf:
            (values.tenue_vestimentaire_femme as string[]).indexOf(
              "Ne porte pas encore le voile"
            ) > -1,
          wear_turban:
            (values.tenue_vestimentaire_femme as string[]).indexOf(
              "Porte le turban"
            ) > -1,
          wear_hijab:
            (values.tenue_vestimentaire_femme as string[]).indexOf(
              "Porte le Hijab"
            ) > -1,
          wear_jilbab:
            (values.tenue_vestimentaire_femme as string[]).indexOf(
              "Porte le Jilbab"
            ) > -1,
          wear_niqab:
            (values.tenue_vestimentaire_femme as string[]).indexOf(
              "Porte le Niqab"
            ) > -1,
          wear_abaya:
            (values.tenue_vestimentaire_femme as string[]).indexOf("L'Abaya") >
            -1,
          favorite_scholars: values.savants,
          favorite_preachers: values.predicateurs,
          have_school_of_jurisprudence: values.jurisprudence,
          is_in_hijra: values.hijra,
          hijra_country: origine.country,
          hijra_country_code: origine.abbreviation,
          like_to_make_hijra: values.souhait_hijra,
          when_to_make_hijra: values.temps_hijra,
          agree_to_move: values.demenager,
          accept_married_man: values.accept_married_man,
          have_mahram: values.have_mahram,
          mahram_phone_number: values.mahram_phone_number,
        };
        console.log(dataToSend);
        aspectStepSubmit(dataToSend);
      } else {
        const dataToSend = {
          is_salafi: values.salafi,
          male_dress_code: values.tenue_vestimentaire_homme,
          not_wear_headscarf: false,
          wear_turban: false,
          wear_hijab: false,
          wear_jilbab: false,
          wear_niqab: false,
          wear_abaya: false,
          favorite_scholars: values.savants,
          favorite_preachers: values.predicateurs,
          have_school_of_jurisprudence: values.jurisprudence,
          is_in_hijra: values.hijra,
          hijra_country: origine.country,
          hijra_country_code: origine.abbreviation,
          like_to_make_hijra: values.souhait_hijra,
          when_to_make_hijra: values.temps_hijra,
          agree_to_move: values.demenager,
          accept_married_man: false,
          have_mahram: false,
          mahram_phone_number: "",
        };
        console.log(dataToSend);
        aspectStepSubmit(dataToSend);
      }
    },
  });

  const aspectStepSubmit = (data: any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    if ((userConnected as TUserConnected).infos.gender === "female") {
      apiSaveAspectFemale(data, token)
        .then((response: any) => {
          setLoading(false);
          chooseStep(2);
          scrollTop();

          if (!(response.code === "aucune_information_fournie")) {
            store.dispatch(updateProgress(response.rate_of_completion));
            store.dispatch(
              updateReligiousAspects(
                response.payload as TAdditionnalInfosReligiousAspect
              )
            );

            Toastify(
              `Les informations de votre aspect réligieux ont bien été soumises à notre équipe de modération`,
              "success",
              5000
            );
            store.dispatch(updateIsApproved(false));
          }
        })
        .catch((error: any) => {
          setLoading(false);

          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    } else {
      apiSaveAspectMale(data, token)
        .then((response: any) => {
          setLoading(false);
          console.log(response);
          chooseStep(2);
          scrollTop();

          if (!(response.code === "aucune_information_fournie")) {
            store.dispatch(updateProgress(response.rate_of_completion));
            store.dispatch(
              updateReligiousAspects(
                response.payload as TAdditionnalInfosReligiousAspect
              )
            );

            Toastify(
              `Les informations de votre aspect réligieux ont bien été mises à jour`,
              "success",
              5000
            );
            store.dispatch(updateIsApproved(false));
            store.dispatch(updateNewModificationSubmit(true));
          }
        })
        .catch((error: any) => {
          setLoading(false);

          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    }
  };

  const reset = () => {
    formik.setValues({
      salafi: false,
      tenue_vestimentaire_femme: [],
      tenue_vestimentaire_homme: "",
      savants: "",
      predicateurs: "",
      jurisprudence: "",
      hijra: false,
      pays_hijra: "",
      souhait_hijra: false,
      temps_hijra: "",
      demenager: false,
      accept_married_man: false,
      have_mahram: false,
      mahram_phone_number: "",
    });
    setOrigine({ country: "", abbreviation: "" });
  };

  const submitClick = () => {
    if (Object.keys(formik.errors).length > 0) {
      Toastify(
        `Il y a une erreur dans le formulaire. Veuillez remplir correctement le formulaire svp!`,
        "warning",
        5000
      );
    }
  };

  const getAbbreviation = (selected_country: string): string => {
    let result = "";

    result =
      countries.find((x) => x.country === selected_country)?.abbreviation || "";

    return result;
  };

  return (
    <form className="form-part-aspect" onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={6}>
            <div className="switch-container">
              <Typography> Êtes-vous salafi ? </Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.salafi ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="salafi"
                      checked={formik.values.salafi}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={6}>
            {(userConnected as TUserConnected).infos.gender === "male" && (
              <>
                <TextField
                  placeholder="Votre tenue vestimentaire"
                  variant="outlined"
                  label="Votre tenue vestimentaire"
                  name="tenue_vestimentaire_homme"
                  onChange={formik.handleChange}
                  value={formik.values.tenue_vestimentaire_homme}
                  error={
                    formik.touched.tenue_vestimentaire_homme &&
                    Boolean(formik.errors.tenue_vestimentaire_homme)
                  }
                  helperText={
                    formik.touched.tenue_vestimentaire_homme &&
                    formik.errors.tenue_vestimentaire_homme
                  }
                  type="text"
                />
              </>
            )}

            {(userConnected as TUserConnected).infos.gender === "female" && (
              <>
                <MultipleSelectChip
                  name="tenue_vestimentaire_femme"
                  selectOptions={tenues_vestimentaires_femmes_list}
                  value={formik.values.tenue_vestimentaire_femme}
                  onChange={formik.handleChange}
                  labelId="id-select-tenue-femme"
                  label="Votre tenue vestimentaire"
                  errorText={formik.errors.tenue_vestimentaire_femme}
                  error={
                    formik.touched.tenue_vestimentaire_femme &&
                    Boolean(formik.errors.tenue_vestimentaire_femme)
                  }
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div
              className={classNames("text-area", {
                error: formik.touched.savants && Boolean(formik.errors.savants),
              })}
            >
              <Typography className="text-area-label">
                {" "}
                Les savants que vous suivez
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="savants"
                onChange={formik.handleChange}
                value={formik.values.savants}
              ></textarea>
              {formik.touched.savants && Boolean(formik.errors.savants) && (
                <FormHelperText> {formik.errors.savants} </FormHelperText>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div
              className={classNames("text-area", {
                error:
                  formik.touched.predicateurs &&
                  Boolean(formik.errors.predicateurs),
              })}
            >
              <Typography className="text-area-label">
                {" "}
                Les prédicateurs que vous écoutez{" "}
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="predicateurs"
                onChange={formik.handleChange}
                value={formik.values.predicateurs}
              ></textarea>
              {formik.touched.predicateurs &&
                Boolean(formik.errors.predicateurs) && (
                  <FormHelperText>
                    {" "}
                    {formik.errors.predicateurs}{" "}
                  </FormHelperText>
                )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              placeholder="Suivez-vous une école de jurisprudence ? Si oui laquelle?"
              variant="outlined"
              label="Suivez-vous une école de jurisprudence ?"
              name="jurisprudence"
              onChange={formik.handleChange}
              value={formik.values.jurisprudence}
              error={
                formik.touched.jurisprudence &&
                Boolean(formik.errors.jurisprudence)
              }
              helperText={
                formik.touched.jurisprudence && formik.errors.jurisprudence
              }
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={6}>
            <div className="switch-container">
              <Typography> Êtes-vous en hijra ? </Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.hijra ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="hijra"
                      checked={formik.values.hijra}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>

          {formik.values.hijra && (
            <>
              <Grid item xs={12} sm={12} lg={6}>
                <Autocomplete
                  id="country-select-origine"
                  options={countries}
                  value={origine}
                  onChange={handleOrigineChange}
                  autoHighlight
                  getOptionLabel={(option) => option.country}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <InlineIcon
                        style={{ borderRadius: "50%" }}
                        icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                      />{" "}
                      &nbsp;
                      {option.country}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <>
                      <InlineIcon
                        className="flag"
                        icon={`flag:${origine.abbreviation.toLowerCase()}-1x1`}
                      />
                      <TextField
                        {...params}
                        placeholder="Dans quel pays êtes-vous ?"
                        variant="outlined"
                        label="Dans quel pays êtes-vous ?"
                        name="pays_hijra"
                        inputRef={originInputref}
                        onChange={formik.handleChange}
                        value={formik.values.pays_hijra}
                        error={
                          formik.touched.pays_hijra &&
                          Boolean(formik.errors.pays_hijra)
                        }
                        helperText={
                          formik.touched.pays_hijra && formik.errors.pays_hijra
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
            </>
          )}

          {!formik.values.hijra && (
            <>
              <Grid item xs={12} sm={12} lg={12} xl={6}>
                <div className="switch-container">
                  <Typography>
                    {" "}
                    Souhaitez-vous faire la hijra ? Si oui, quand ?{" "}
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.souhait_hijra ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="souhait_hijra"
                          checked={formik.values.souhait_hijra}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              {formik.values.souhait_hijra && (
                <>
                  <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                      placeholder="Dans combien de temps ?"
                      variant="outlined"
                      label="Dans combien de temps ?"
                      name="temps_hijra"
                      onChange={formik.handleChange}
                      value={formik.values.temps_hijra}
                      error={
                        formik.touched.temps_hijra &&
                        Boolean(formik.errors.temps_hijra)
                      }
                      helperText={
                        formik.touched.temps_hijra && formik.errors.temps_hijra
                      }
                      type="text"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          {(userConnected as TUserConnected).infos.gender === "male" && (
            <>
              <Grid item xs={12} sm={12} lg={12}>
                <div className="switch-container">
                  <Typography> Acceptez-vous de déménager ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.demenager ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="demenager"
                          checked={formik.values.demenager}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
            </>
          )}

          {(userConnected as TUserConnected).infos.gender === "female" && (
            <>
              <Grid item xs={12} sm={12} lg={6}>
                <div className="switch-container">
                  <Typography> Acceptez-vous de déménager ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.demenager ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="demenager"
                          checked={formik.values.demenager}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <div className="switch-container">
                  <Typography> Acceptez-vous les hommes mariés ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.accept_married_man ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="accept_married_man"
                          checked={formik.values.accept_married_man}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={formik.values.have_mahram ? 6 : 12}
              >
                <div className="switch-container">
                  <Typography> Avez-vous un Tuteur ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.have_mahram ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="have_mahram"
                          checked={formik.values.have_mahram}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              {formik.values.have_mahram && (
                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Téléphone de votre Tuteur"
                    variant="outlined"
                    label="Téléphone de votre Tuteur"
                    name="mahram_phone_number"
                    onChange={formik.handleChange}
                    value={formik.values.mahram_phone_number}
                    error={
                      formik.touched.mahram_phone_number &&
                      Boolean(formik.errors.mahram_phone_number)
                    }
                    helperText={
                      formik.touched.mahram_phone_number &&
                      formik.errors.mahram_phone_number
                    }
                    type="text"
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>

      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Grid item xs={12} sm={12} lg={3} xl={2}>
          <Button
            variant="outlined"
            color="tertiary"
            onClick={() => {
              reset();
            }}
          >
            {" "}
            Effacer
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={5}>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="primary"
            type="submit"
            onClick={submitClick}
          >
            {" "}
            Valider et passer à pratiques réligieuses
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const AspectStep = connector(AspectStepFct);

export default AspectStep;
