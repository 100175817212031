import ListManager from "@/components/ListManager/ListManager";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { Toastify } from "@/utils/toast";
import { Box, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  apiCancelDemand,
  apiEndUpExchange,
  apiGetAllExchange,
  apiRejectDemand,
} from "../../api/exchange.api";
import { TProfileCard } from "../../Elements/ProfileCard/ProfileCard";
import {
  initCurrentExchange,
  TCurrentExchange,
  TUserConnected,
} from "../../types";
import EchangeInfos from "./components/Echange-Infos/EchangeInfos";
import DetailEchange from "./Detail-echange/DetailEchange";
import * as moment from "moment";
import "./Exchange.styles.scss";
import { store } from "@/store";
import {
  selectCurrentExchange,
  selectOpenDiscussion,
} from "@/store/reducers/general_param/general_param.selector";
import {
  updateCurrentExchange,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import * as _ from "lodash";
import {
  updateExchangeNotif,
  updatePendingCancelConversation,
} from "@/store/reducers/member/member.actions";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

export const ExchangeContext = createContext<{
  rejectDemandFct: (profil_id: string, username: string, motif: string) => void;
  loadingReject: boolean;
  cancelDemandFct: (profil_id: string, username: string) => void;
  loadingCancel: boolean;
  endUpExchangeFct: (id: string, motif: string, username: string) => void;
  loadingEndup: boolean;
}>({
  rejectDemandFct: () => {},
  loadingReject: false,
  cancelDemandFct: () => {},
  loadingCancel: false,
  endUpExchangeFct: () => {},
  loadingEndup: false,
});

type PropsFromRedux = ConnectedProps<typeof connector>;
const CtExchangeFct: React.FC<PropsFromRedux> = ({
  userConnected,
  token,
  openDiscussion,
  currentExchange,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingEndup, setLoadingEndup] = useState(false);
  const [demandList, setDemandList] = useState<TProfileCard[]>([]);
  const [closedDemandList, setClosedDemandList] = useState<TProfileCard[]>([]);
  const [navState, setNavState] = React.useState<"pending" | "done">("pending");

  const { socketIO } = useContext(AppSocketContext);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newNavState: "pending" | "done"
  ) => {
    setNavState(newNavState);
  };

  useEffect(() => {
    store.dispatch(updateExchangeNotif(0)); // <---

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      setLoading(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetAllExchange(token)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList1: TProfileCard[] = response.requests_sent
            .filter((x: any) => x !== null)
            .map((data: any) => {
              return {
                isVerified: data.is_verified,
                username: data.username,
                type:
                  (data.has_visited && "Mes spectateurs") ||
                  (data.is_visited ? "consulted" : "non consultés"),
                progressValue: data.rate_of_completion,
                profil_id: data.profile_id,
                isOnline: data.is_online,
                age: data.age,
                town: data.city,
                country: data.country,
                origine: data.home_country,
                nationalite: data.citizenship,
                hasMore: data.has_more,
                sendStatut: "sent",
                description: data.description,
                profile_wanted: data.profile_wanted,
                isMutualFavorite: data.is_favorite && data.is_fan,
                isSimpleFavorite: data.is_favorite,
                date_of_creation: data.date_of_creation,
                cloture: null,
                isListeNoire: false,
                has_conversation: data.has_conversation,
                is_deleted: data.is_deleted,
              };
            });

          let tempList2: TProfileCard[] = response.requests_received
            .filter((x: any) => x !== null)
            .map((data: any) => {
              return {
                isVerified: data.is_verified,
                username: data.username,
                type:
                  (data.has_visited && "Mes spectateurs") ||
                  (data.is_visited ? "consulted" : "non consultés"),
                progressValue: data.rate_of_completion,
                profil_id: data.profile_id,
                isOnline: data.is_online,
                age: data.age,
                town: data.city,
                country: data.country,
                origine: data.home_country,
                nationalite: data.citizenship,
                hasMore: data.has_more,
                sendStatut: "received",
                description: data.description,
                profile_wanted: data.profile_wanted,
                isMutualFavorite: data.is_favorite && data.is_fan,
                isSimpleFavorite: data.is_fan,
                date_of_creation: data.date_of_creation,
                cloture: null,
                isListeNoire: false,
                has_conversation: data.has_conversation,
                is_deleted: data.is_deleted,
              };
            });

          let tempList3: TProfileCard[] = response.closed
            .filter((x: any) => x !== null)
            .map((data: any) => {
              return {
                isVerified: data.is_verified,
                username: data.username,
                type:
                  (data.has_visited && "Mes spectateurs") ||
                  (data.is_visited ? "consulted" : "non consultés"),
                progressValue: data.rate_of_completion,
                profil_id: data.profile_id,
                isOnline: data.is_online,
                age: data.age,
                town: data.city,
                country: data.country,
                origine: data.home_country,
                nationalite: data.citizenship,
                hasMore: data.has_more,
                sendStatut: "received",
                description: data.description,
                profile_wanted: data.profile_wanted,
                isMutualFavorite: data.is_favorite && data.is_fan,
                isSimpleFavorite: data.is_fan,
                date_of_creation: data.date_of_creation,
                isListeNoire: false,
                isCloture: true,
                cloture: {
                  date_cloture: moment
                    .default(new Date(data.cloture.date_cloture))
                    .format("LLL"),
                  motif: data.cloture.motif,
                  emitter: data.cloture.emitter,
                },
                conversation_id: data.conversation_id,
              };
            });

          if (response.current) {
            let tempCurrent: TCurrentExchange = response.current;
            console.log("😶😶😶", tempCurrent);
            store.dispatch(updateCurrentExchange(tempCurrent));
          } else {
            store.dispatch(updateCurrentExchange(initCurrentExchange));
            store.dispatch(updateOpenDiscussion(false));
          }

          setDemandList([...tempList1, ...tempList2]);

          setClosedDemandList([...tempList3]);
        })
        .catch((error) => {
          setLoading(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //console.log('||| ⭐️',socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.new_demand_exchange_notif, (data) => {
        console.log("socket exchange new demand => ", data);
        let temp_demandList = demandList as TProfileCard[];
        false &&
          temp_demandList.push({
            isVerified: data.is_verified,
            username: data.username,
            type:
              (data.has_visited && "Mes spectateurs") ||
              (data.is_visited ? "consulted" : "non consultés"),
            progressValue: data.rate_of_completion,
            profil_id: data.profile_id,
            isOnline: data.is_online,
            age: data.age,
            town: data.city,
            country: data.country,
            origine: data.home_country,
            nationalite: data.citizenship,
            hasMore: data.has_more,
            sendStatut: "received",
            description: data.description,
            profile_wanted: data.profile_wanted,
            isMutualFavorite: data.is_favorite && data.is_fan,
            isSimpleFavorite: data.is_fan,
            date_of_creation: data.date_of_creation,
            cloture: null,
            isListeNoire: false,
            has_conversation: data.has_conversation,
          });
        false && setDemandList([...temp_demandList]);
      });

      socketIO.on(socketChannels.accept_demand_channel, (data) => {
        console.log("socket exchange accept demand => ", data);
        let tempCurrent: TCurrentExchange = data;
        false && store.dispatch(updateCurrentExchange(tempCurrent));
        false && store.dispatch(updateOpenDiscussion(true));
      });

      socketIO.on(socketChannels.cloture_demand_channel, (data) => {
        console.log("socket exchange cloture demand => ", data);
        store.dispatch(updateCurrentExchange(initCurrentExchange));
        store.dispatch(updateOpenDiscussion(false));
        store.dispatch(updatePendingCancelConversation(true));
      });
    }

    return () => {
      if (socketIO) {
        socketIO.off(socketChannels.new_demand_exchange_notif);
        socketIO.off(socketChannels.accept_demand_channel);
        socketIO.off(socketChannels.cloture_demand_channel);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, demandList]);

  const rejectDemandFct = (
    profil_id: string,
    username: string,
    motif: string
  ) => {
    setLoadingReject(true);

    apiRejectDemand(profil_id, token, motif)
      .then((response: any) => {
        setLoadingReject(false);
        console.log(response);

        let tempArr = demandList;
        let removedItem = _.remove(tempArr, (x) => {
          return x.profil_id === profil_id;
        });
        console.log(removedItem);
        setDemandList([...tempArr]);

        Toastify(
          ` La demande d'échange de ${username} a bien été refusée `,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingReject(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const cancelDemandFct = (profil_id: string, username: string) => {
    setLoadingCancel(true);

    apiCancelDemand(profil_id, token)
      .then((response: any) => {
        setLoadingCancel(false);
        console.log(response);

        let tempArr = demandList;
        let removedItem = _.remove(tempArr, (x) => {
          return x.profil_id === profil_id;
        });
        console.log(removedItem);
        setDemandList([...tempArr]);

        Toastify(
          ` La demande d'échange de ${username} a bien été annulée `,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingCancel(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const endUpExchangeFct = (id: string, motif: string, username: string) => {
    setLoadingEndup(true);

    apiEndUpExchange({ id: id, note: motif }, token)
      .then((response: any) => {
        setLoadingEndup(false);
        console.log(response);

        let tempArr = closedDemandList;
        let tempCurrent: any;
        if (
          (userConnected as TUserConnected).infos.username ===
          (currentExchange as TCurrentExchange).sender.username
        ) {
          tempCurrent = (currentExchange as TCurrentExchange).receiver;
        } else {
          tempCurrent = (currentExchange as TCurrentExchange).sender;
        }
        tempArr.push({
          conversation_id: id,
          isVerified: tempCurrent.is_verified,
          username: tempCurrent.username,
          type:
            (tempCurrent.has_visited && "Mes spectateurs") ||
            (tempCurrent.is_visited ? "consulted" : "non consultés"),
          progressValue: tempCurrent.rate_of_completion,
          profil_id: tempCurrent.profile_id,
          isOnline: tempCurrent.is_online,
          age: tempCurrent.age,
          town: tempCurrent.city,
          country: tempCurrent.country,
          origine: tempCurrent.home_country,
          nationalite: tempCurrent.citizenship,
          hasMore: tempCurrent.has_more,
          sendStatut: "received",
          description: tempCurrent.description,
          profile_wanted: tempCurrent.profile_wanted,
          isMutualFavorite: tempCurrent.is_favorite && tempCurrent.is_fan,
          isSimpleFavorite: tempCurrent.is_fan,
          date_of_creation: tempCurrent.date_of_creation,
          isListeNoire: false,
          isCloture: true,
          cloture: {
            date_cloture: moment.default(new Date()).format("LLL"),
            motif: motif,
            emitter: (userConnected as TUserConnected).infos.username,
          },
        });
        setClosedDemandList([...tempArr]);

        Toastify(
          ` La clôture d'échange avec ${username} a bien été envoyé à nos équipes de modération `,
          "success",
          5000
        );

        store.dispatch(updateCurrentExchange(initCurrentExchange));
        store.dispatch(updateOpenDiscussion(false));
        store.dispatch(updatePendingCancelConversation(true));
      })
      .catch((error) => {
        setLoadingEndup(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <ExchangeContext.Provider
      value={{
        rejectDemandFct: rejectDemandFct,
        loadingReject: loadingReject,
        cancelDemandFct: cancelDemandFct,
        loadingCancel: loadingCancel,
        endUpExchangeFct: endUpExchangeFct,
        loadingEndup: loadingEndup,
      }}
    >
      <>
        {(typeof (userConnected as TUserConnected).account
          .reason_of_desactivation_by_admin === "string"
          ? false
          : (userConnected as TUserConnected).account.is_free ||
            ((userConnected as TUserConnected).account.is_active &&
              (userConnected as TUserConnected).account
                .is_subscription_active)) && (
          <div className="ct-exchange">
            <div className="ct-exchange__body-part">
              {openDiscussion ? (
                <DetailEchange
                  currentExchange={currentExchange as TCurrentExchange}
                />
              ) : (
                <div className="ct-exchange__body-part__recap-container">
                  <Box>
                    <Grid container rowSpacing={4} columnSpacing={2}>
                      <Grid item xs={12} sm={12} lg={12} xl={4}>
                        <EchangeInfos
                          isBooked={
                            (currentExchange as TCurrentExchange).is_open
                          }
                          currentExchange={currentExchange as TCurrentExchange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={8}>
                        <div className="nav-part">
                          <ToggleButtonGroup
                            color="primary"
                            className="yel-toggle-group"
                            value={navState}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                          >
                            <ToggleButton value="pending">
                              {" "}
                              Demande(s) en cours ({demandList.length}){" "}
                            </ToggleButton>
                            <ToggleButton value="done">
                              Échange(s) clôturé(s) ({closedDemandList.length}){" "}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                        {navState === "pending" && (
                          <ListManager
                            title="Demande(s) en cours"
                            subtitle=""
                            profileList={demandList}
                            loading={loading}
                            isForExchange={true}
                            isInProgress={true}
                            isForCloture={false}
                            alt={false}
                          />
                        )}
                        {navState === "done" && (
                          <ListManager
                            title="Échange(s) clôturé(s)"
                            subtitle=""
                            profileList={closedDemandList}
                            loading={loading}
                            isForExchange={true}
                            isInProgress={false}
                            isForCloture={true}
                            alt={false}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              )}
            </div>
          </div>
        )}
        {(userConnected as TUserConnected).account
          .changes_requests_rejected && (
          <CModalRejectedModif
            open={
              (userConnected as TUserConnected).account
                .changes_requests_rejected
            }
          />
        )}

        <CModalRemindPay
          open={
            typeof (userConnected as TUserConnected).account
              .reason_of_desactivation_by_admin === "string"
              ? true
              : !(userConnected as TUserConnected).account.is_free &&
                (!(userConnected as TUserConnected).account.is_active ||
                  !(userConnected as TUserConnected).account
                    .is_subscription_active)
          }
          type={
            (userConnected as TUserConnected).account.is_subscription_expired
              ? "reabonnement"
              : "abonnement"
          }
        />
      </>
    </ExchangeContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  openDiscussion: selectOpenDiscussion,
  currentExchange: selectCurrentExchange,
});

const connector = connect(mapStateToProps);
const CtExchange = connector(CtExchangeFct);

export default CtExchange;
