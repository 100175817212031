import { YouTube, FacebookRounded, Twitter, Instagram, Send } from '@mui/icons-material';
import PerfectScrollbar from "react-perfect-scrollbar";
import React from 'react';
import "./ImamSpeakerCard.styles.scss";

export type TImamSpeakerCard = {
    name: string;
    firstname: string;
    title: 'imam' | 'prédicateur';
    cursus: string;
    reference: string;
    youtube: string;
    facebook: string;
    twitter: string;
    instagram: string;
    telegram: string;
}

const  ImamSpeakerCard:React.FC<TImamSpeakerCard> = ({
    name,
    firstname,
    title,
    cursus,
    reference,
    youtube,
    facebook,
    twitter,
    instagram,
    telegram,
}) => {

  return (
    <div className="card-imam-speaker">
        <div className="head-part">
            {
                title === 'imam' &&
                <img  src={require("@/assets/images/png/imam.png")}
                alt="logo-mini"/>
            }
            {
                title === 'prédicateur' &&
                <img  src={require("@/assets/images/png/speaker.png")}
                alt="logo-mini"/>
            }

            <div className="head-text">
                <h2> {name} {firstname} </h2>
                <p> {title} </p>
            </div>
        </div>
        <div className="text-bloc">
            <b> Cursus Réligieux </b>
            <PerfectScrollbar className="paragraphe"> 
                {cursus}
            </PerfectScrollbar>
        </div>
        <div className="text-bloc">
            <b> Références Réligieuses </b>
            <PerfectScrollbar className="paragraphe"> 
                {reference} 
            </PerfectScrollbar>
        </div>
        <div className="social-part">
            <a
                className="rounded-square"
                href={youtube}
                target="_blank"
                rel="noreferrer"
            >
                <YouTube color="secondary" />
            </a>
            <a
                className="rounded-square"
                href={telegram}
                target="_blank"
                rel="noreferrer"
            >
                <Send color="secondary" />
            </a> 
            <a
                className="rounded-square"
                href={facebook}
                target="_blank"
                rel="noreferrer"
            >
                <FacebookRounded color="secondary" />
            </a>
            <a
                className="rounded-square"
                href={twitter}
                target="_blank"
                rel="noreferrer"
            >
                <Twitter color="secondary" />
            </a>
            <a
                className="rounded-square"
                href={instagram}
                target="_blank"
                rel="noreferrer"
            >
                <Instagram color="secondary" />
            </a>
        </div>
        
    </div>
  )
}

export default ImamSpeakerCard;