import React, { useContext, useState } from "react";
import {
  selectUserConnected,
  selectToken,
  selectMessageNotif,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./EchangeInfos.styles.scss";
import { InlineIcon } from "@iconify/react";
import * as moment from "moment";
import "moment/locale/fr";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import FullProfil from "@/components/FullProfil/FullProfil";
import ReglementDuChat from "./Modals-components/Reglement-du-chat/ReglementDuChat";
import QuestionAPoser from "./Modals-components/Questions-a-poser/QuestionAPoser";
import CommentSeVoir from "./Modals-components/Comment-se-voir/CommentSeVoir";
import {
  TCurrentExchange,
  TUserConnected,
  initCurrentExchange,
} from "@/features/profile/types";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import { HighlightOffRounded } from "@mui/icons-material";
import { apiSignalMember } from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import { store } from "@/store";
import {
  updateNbreMessageReceive,
  updateNbreMessageSent,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import { ExchangeContext } from "../../Exchange.content";
import { styled } from "@mui/material/styles";
import { relaunch } from "@/features/profile/api/exchange.api";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

type TEchangeInfos = {
  isBooked?: boolean;
  isDiscussing?: boolean;
  currentExchange?: TCurrentExchange;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const EchangeInfosFct: React.FC<TEchangeInfos & PropsFromRedux> = ({
  userConnected,
  token,
  isBooked = false,
  isDiscussing = false,
  currentExchange,
  nbre_message_notif,
}) => {
  const [openActionType, setOpenActionType] = React.useState<
    "profil" | "règlement" | "question" | "voir"
  >("profil");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [openSignal, setOpenSignal] = React.useState(false);
  const handleOpenSignal = () => setOpenSignal(true);
  const handleCloseSignal = () => {
    formik.resetForm();
    setOpenSignal(false);
  };

  const [openConfirmedSignal, setOpenConfirmedSignal] = React.useState(false);
  const handleOpenConfirmedSignal = () => setOpenConfirmedSignal(true);
  const handleCloseConfirmedSignal = () => setOpenConfirmedSignal(false);

  const [openEndUp, setOpenEndUp] = React.useState(false);
  const handleOpenEndUp = () => setOpenEndUp(true);
  const handleCloseEndUp = () => {
    formikEndUp.resetForm();
    setOpenEndUp(false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [openEndUpExchange, setOpenEndUpExchange] = React.useState(false);
  const handleOpenEndUpExchange = () => setOpenEndUpExchange(true);
  const handleCloseEndUpExchange = () => setOpenEndUpExchange(false);

  const handleOpenAction = (
    actionType: "profil" | "règlement" | "question" | "voir"
  ) => {
    handleOpenModal();
    setOpenActionType(actionType);
  };

  const validationSchema = yup.object({
    motif: yup.string().required(" la raison est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleOpenConfirmedSignal();
      }, 100);
    },
  });

  const validationSchemaEndUp = yup.object({
    motif: yup
      .string()
      .min(
        10,
        "Votre message est trop court, par respect pour l'utilisateur veuillez écrire un réel motif de clôture."
      )
      .required(" la raison est obligatoire  "),
  });

  const formikEndUp = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchemaEndUp,
    onSubmit: (values: any) => {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
        handleOpenEndUpExchange();
      }, 100);
    },
  });

  const signalMember = () => {
    setLoading3(true);

    apiSignalMember(
      {
        member_id: testSender()
          ? (currentExchange as TCurrentExchange).receiver.profile_id
          : (currentExchange as TCurrentExchange).sender.profile_id,
        note: formik.values.motif,
      },
      token
    )
      .then((response: any) => {
        setLoading3(false);
        console.log(response);
        Toastify(
          ` Le signalement de ${
            testSender()
              ? (currentExchange as TCurrentExchange).receiver.username
              : (currentExchange as TCurrentExchange).sender.username
          } a bien été envoyé à nos équipes de modération `,
          "success",
          5000
        );

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      })
      .catch((error) => {
        setLoading3(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      });
  };

  const { endUpExchangeFct, loadingEndup } = useContext(ExchangeContext);

  const endUpExchange = () => {
    endUpExchangeFct(
      (currentExchange as TCurrentExchange).id,
      formikEndUp.values.motif,
      testSender()
        ? cleanCurrentExchange().receiver.username
        : cleanCurrentExchange().sender.username
    );
    formikEndUp.resetForm();
    handleCloseEndUp();
    handleCloseEndUpExchange();

    store.dispatch(updateNbreMessageSent(0));
    store.dispatch(updateNbreMessageReceive(0));
  };

  const cleanCurrentExchange = (): TCurrentExchange => {
    let result: TCurrentExchange = initCurrentExchange;

    if ((currentExchange as TCurrentExchange).receiver === undefined) {
      result = {
        ...(currentExchange as TCurrentExchange),
        receiver: initCurrentExchange.receiver,
      };
    } else if ((currentExchange as TCurrentExchange).sender === undefined) {
      result = {
        ...(currentExchange as TCurrentExchange),
        sender: initCurrentExchange.sender,
      };
    } else {
      result = currentExchange as TCurrentExchange;
    }

    return result;
  };

  const testSender = (): boolean => {
    return (
      (userConnected as TUserConnected).member_id ===
      cleanCurrentExchange().sender.profile_id
    );
  };

  const testInterlocuteurAbonnement = (): boolean => {
    let result = false;
    console.log("😐😐😐", currentExchange);

    if (
      (userConnected as TUserConnected).infos.username ===
      cleanCurrentExchange().sender.username
    ) {
      if (cleanCurrentExchange().receiver.is_active !== undefined) {
        result = !cleanCurrentExchange().receiver.is_active;
      }
    }

    if (
      (userConnected as TUserConnected).infos.username ===
      cleanCurrentExchange().receiver.username
    ) {
      if (cleanCurrentExchange().sender.is_active !== undefined) {
        result = !cleanCurrentExchange().sender.is_active;
      }
    }

    return result;
  };

  const relancer = () => {
    relaunch(token);
    Toastify(`Relance effectuée avec succès`, "success", 5000);
  };

  return (
    <>
      <div className="c-echange-infos">
        <div className="icon-circle">
          <InlineIcon icon="fluent-emoji-high-contrast:handshake" />
          {/* <InlineIcon icon="la:handshake-solid" /> */}
        </div>
        {!isBooked && !isDiscussing && <p> Vous n'êtes pas en échange </p>}

        {isBooked && !isDiscussing && (
          <>
            {testInterlocuteurAbonnement() && (
              <small
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "500",
                  margin: "10px auto",
                  color: "var(--ui-primary)",
                }}
              >
                <b>
                  ⚠️&nbsp;
                  {testSender()
                    ? cleanCurrentExchange().receiver.username.toUpperCase()
                    : cleanCurrentExchange().sender.username.toUpperCase()}
                </b>
                &nbsp; avec qui vous êtes en échange ne dispose plus
                d'abonnement
              </small>
            )}

            <p> Je suis actuellement en échange avec </p>
            <span className="name">
              {testSender()
                ? cleanCurrentExchange().receiver.username.toUpperCase()
                : cleanCurrentExchange().sender.username.toUpperCase()}
            </span>
            <small>
              {" "}
              Depuis le{" "}
              <b>
                {moment
                  .default(cleanCurrentExchange().creation_date)
                  .format("LLL")}
              </b>{" "}
            </small>
            <div className="btn-container">
              <Button
                variant="outlined"
                style={{ color: "var(--ui-medium)" }}
                color="tertiary"
                onClick={handleOpenEndUp}
              >
                Clôturer l'échange
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  store.dispatch(updateOpenDiscussion(true));
                }}
                color="primary"
              >
                <StyledBadge
                  overlap="circular"
                  variant="dot"
                  color="secondary"
                  sx={{ marginLeft: "auto" }}
                  badgeContent={nbre_message_notif}
                >
                  <>Accéder à l'échange</>
                </StyledBadge>
              </Button>
            </div>
          </>
        )}

        {isDiscussing && (
          <>
            <Button
              color="primary"
              className="return"
              startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
              onClick={() => {
                store.dispatch(updateOpenDiscussion(false));
              }}
            >
              Retour
            </Button>

            {testInterlocuteurAbonnement() && (
              <small
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "500",
                  margin: "10px auto",
                  color: "var(--ui-primary)",
                }}
              >
                <b>
                  ⚠️&nbsp;
                  {testSender()
                    ? cleanCurrentExchange().receiver.username.toUpperCase()
                    : cleanCurrentExchange().sender.username.toUpperCase()}
                </b>
                &nbsp; avec qui vous êtes en échange ne dispose plus
                d'abonnement
              </small>
            )}

            <p> Je suis actuellement en échange avec </p>
            <span className="name">
              {testSender()
                ? cleanCurrentExchange().receiver.username.toUpperCase()
                : cleanCurrentExchange().sender.username.toUpperCase()}
            </span>
            <small className="inside">
              {" "}
              Depuis le{" "}
              <b>
                {moment
                  .default(cleanCurrentExchange().creation_date)
                  .format("LLL")}
              </b>{" "}
            </small>
            <Button
              variant="contained"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("profil");
              }}
              color="primary"
            >
              Voir le profil complet
            </Button>
            <Button
              variant="outlined"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("règlement");
              }}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Règlement du chat
            </Button>

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("question");
              }}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Questions à lui poser
            </Button>

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("voir");
              }}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Comment se voir
            </Button>

            {false && (
              <Button
                variant="outlined"
                className="btn-inside"
                onClick={() => {}}
                style={{ color: "var(--ui-medium)" }}
                color="tertiary"
              >
                Actualiser la page
              </Button>
            )}

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={handleOpenEndUp}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Clôturer l'échange
            </Button>

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={handleOpenSignal}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Faire un signalement
            </Button>

            <Button
              variant="contained"
              className="btn-inside"
              onClick={relancer}
              color="primary"
            >
              Envoyer une notification
            </Button>
          </>
        )}
      </div>

      {/* MODALS PART */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {openActionType === "profil" && (
            <>
              {testSender() ? (
                <FullProfil
                  closeModal={handleCloseModal}
                  isOnline={true}
                  isVerified={true}
                  hasMore={true}
                  date_of_creation={
                    (userConnected as TUserConnected).infos.date_of_creation
                  }
                  username={cleanCurrentExchange().receiver.username}
                  type={
                    (cleanCurrentExchange().receiver.has_visited &&
                      "Mes spectateurs") ||
                    (cleanCurrentExchange().receiver.is_visited
                      ? "consulted"
                      : "non consultés")
                  }
                  age={cleanCurrentExchange().receiver.age}
                  town={cleanCurrentExchange().receiver.city}
                  country={cleanCurrentExchange().receiver.country}
                  origine={cleanCurrentExchange().receiver.home_country}
                  profil_id={cleanCurrentExchange().receiver.profile_id}
                  nationalite={cleanCurrentExchange().receiver.citizenship}
                  progressValue={
                    cleanCurrentExchange().receiver.rate_of_completion
                  }
                  sendStatut={""}
                />
              ) : (
                <FullProfil
                  closeModal={handleCloseModal}
                  isOnline={true}
                  isVerified={true}
                  hasMore={true}
                  date_of_creation={
                    (userConnected as TUserConnected).infos.date_of_creation
                  }
                  username={cleanCurrentExchange().sender.username}
                  type={
                    (cleanCurrentExchange().sender.has_visited &&
                      "Mes spectateurs") ||
                    (cleanCurrentExchange().sender.is_visited
                      ? "consulted"
                      : "non consultés")
                  }
                  age={cleanCurrentExchange().sender.age}
                  town={cleanCurrentExchange().sender.city}
                  country={cleanCurrentExchange().sender.country}
                  origine={cleanCurrentExchange().sender.home_country}
                  profil_id={cleanCurrentExchange().sender.profile_id}
                  nationalite={cleanCurrentExchange().sender.citizenship}
                  progressValue={
                    cleanCurrentExchange().sender.rate_of_completion
                  }
                  sendStatut={""}
                />
              )}
            </>
          )}
          {openActionType === "règlement" && (
            <>
              <ReglementDuChat closeModal={handleCloseModal} />
            </>
          )}
          {openActionType === "question" && (
            <>
              <QuestionAPoser closeModal={handleCloseModal} />
            </>
          )}
          {openActionType === "voir" && (
            <>
              <CommentSeVoir closeModal={handleCloseModal} />
            </>
          )}
        </>
      </Modal>
      {/* End of MODALS PARTS */}

      {/* SIGNAL */}
      <Modal
        open={openSignal}
        onClose={handleCloseSignal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form className="form-raison-part" onSubmit={formik.handleSubmit}>
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseSignal}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison du signalement"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formik.handleChange}
              value={formik.values.motif}
              error={formik.touched.motif && Boolean(formik.errors.motif)}
              helperText={formik.touched.motif && formik.errors.motif}
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseSignal}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    SIGNALER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of SIGNAL */}

      {/* SIGNALER  DIALOG PART */}
      <Dialog
        open={openConfirmedSignal}
        onClose={handleCloseConfirmedSignal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Signalement"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Attention!!!</b> cette action est irréversible.
            <br />
            Vous êtes sur le point de <b>signaler</b> ce profil. Êtes-vous
            sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmedSignal}>Annuler</Button>
          <LoadingButton
            id="id-signal-btn"
            loading={loading3}
            loadingPosition="center"
            onClick={signalMember}
            autoFocus
          >
            Signaler
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of SIGNALER  DIALOG PART */}

      {/* ENDUP */}
      <Modal
        open={openEndUp}
        onClose={handleCloseEndUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form
            className="form-raison-part"
            onSubmit={formikEndUp.handleSubmit}
          >
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseEndUp}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison de la clôture"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formikEndUp.handleChange}
              value={formikEndUp.values.motif}
              error={
                formikEndUp.touched.motif && Boolean(formikEndUp.errors.motif)
              }
              helperText={formikEndUp.touched.motif && formikEndUp.errors.motif}
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseEndUp}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading2}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    CLÔTURER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of ENDUP */}

      {/* CLOTURER  DIALOG PART */}
      <Dialog
        open={openEndUpExchange}
        onClose={handleCloseEndUpExchange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Clôturer les échanges"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <b>Attention!!!</b> cette action est déliquate.<br /> */}
            Vous êtes sur le point de <b> clôturer </b> vos échanges avec{" "}
            <b>{cleanCurrentExchange().receiver.username}</b> . Êtes-vous sûr(e)
            de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEndUpExchange}>Annuler</Button>
          <LoadingButton
            loading={loadingEndup}
            loadingPosition="center"
            variant="contained"
            color="primary"
            id="id-remove-blacklist-btn"
            onClick={endUpExchange}
            type="submit"
          >
            {" "}
            Clôturer{" "}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of CLOTURER DIALOG PART */}
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  nbre_message_notif: selectMessageNotif,
});

const connector = connect(mapStateToProps);
const EchangeInfos = connector(EchangeInfosFct);

export default EchangeInfos;
