import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const  apiGetFavorite = ( access_token: string  ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/members/all/favorites-fans',config);
}

export const  apiGetInterestedInMe = ( access_token: string  ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/test',config);
}

export const  apiAddToFavorite = ( member_id:string, access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/members/like/'+member_id);
}

export const  apiRemoveFromFavorite = ( member_id:string, access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/members/dislike/'+member_id);
}

