import { InlineIcon } from '@iconify/react';
import { HighlightOffRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './ReglementDuChat.style.scss';

type TReglementDuChatProps ={
    closeModal : ()=> void,
}
const ReglementDuChat: React.FC<TReglementDuChatProps> = ({
    closeModal
}) => {
    return (
        <PerfectScrollbar className='reglement-scroll-container'>
            <div className="reglement-card">
                <IconButton className='close-icon'
                    size="large"
                    color='secondary'
                    onClick={closeModal}>
                    <HighlightOffRounded />
                </IconButton>

                <img src={require('@/assets/images/png/logo-mini.png')} alt="logo"/>

                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                            Règlement du service d'intermédiaire 
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                        Zawaj sounnah met à disposition son site pour que vous puissiez échanger avec les membres dans les règles de l'islam.
                        <br/>
                        Nos modérateurs sont présents et veillent à ce que votre discussion reste dans un cadre licite. C'est pour cela qu'il y a des règles d'usage à respecter.
                </p>


                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        L'échange de numéro 
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                        Il est strictement interdit de donner vos numéros personnels, réseaux sociaux ou toutes informations qui permettraient d'entrer en contact avec vous directement. Si vous souhaitez impliquer votre famille ou des intermédiaires, il faut donner le numéro de la personne concernée en précisant qui est cette personne pour vous
                        <br/>
                        Tout membre qui nous ment lors des échanges de numéro d'intermédiaire ou ruse pour cela sera exclu du site.
                </p>


                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        La complaisance est interdite 
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                    Vous êtes des étrangers les uns pour les autres, il n'est donc pas permis de faire preuve de complaisance. De dire des mots doux ou flirter. Vous devez poser les questions les plus pertinentes et rester sur l'essentiel !
                    <br/>
                    Tout message qui sera jugé complaisant sera refusé par le service de modération.
                </p>


                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Les lol,mdr,ptdr, … l'usage de smiley (ainsi que leurs diminutifs) sont interdits
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                        Nous avons questionné les savants sur le fait de laisser passer ce genre d'expressions populaires. Ils nous on dit que vous n'êtes pas là pour rigoler, ou faire rire. Vous êtes là pour le mariage char3i, c'est pourquoi toutes ces formules de rires sont interdites dans le chat. Si vous ne voulez pas voir vos messages retirés alors ne les utilisez pas.
                </p>


                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Les descriptions physiques
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                        Sur la plate-forme, l'équipe de modération lit tous vos messages. La pudeur est incombée à la femme d'autant plus en dehors de son foyer, en espace public, aux milieux de personnes étrangères. Les soeurs peuvent donner leur taille et leur poids ou la corpulence seulement. (Les mensurations n'étant pas autorisées non plus). En dehors de cela, tout autre qualificatif sur le physique sera rejeté.
                </p>


                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        La transmission de liens
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                        Les modérateurs se réservent le droit de refuser les liens de sites qu'ils ne connaissent pas. Ils peuvent cependant autoriser les liens de sites de fatwa comme 3ilm char3i.
                </p>


                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Les fatwa, rappels, hadith …    
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                Toutes paroles liées à la science religieuse sans la source, le dalil seront rejetées.
                </p>

                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        La communication autre que le français    
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                        Nous acceptons les salutations, remerciements… formules basiques en arabes néanmoins la plupart de nos membres étant francophones, et vos messages étant lus et contrôlés afin de vérifier que le contenu soit recevable, tout message dans une langue étrangère ne sera pas validé.
                </p>

                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        La promotion de personnes déviantes     
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <p className="m-body"> 
                    Étant un site suivant le coran et la sounnah selon la compréhension des pieux prédécesseurs, nous veillons à la croyance de nos membres et leur préservation. Nous ne sommes pas dans un état d'esprit de divison et nous estimons que l'aide au mariage chari est un droit réservé à toute personne sincère dans cette démarche. Néanmoins, nous ne pouvons promouvoir des savants et prédicateurs contre qui des mises en garde ont été établies.
                    <br/><br/>
                    <b>Qu'Allah raffermisse nos cœurs sur la droiture.</b>
                </p>
            </div>
        </PerfectScrollbar>
    )
}

export default ReglementDuChat
