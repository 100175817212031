import { routePaths } from "@/config";
import { TUserConnected } from "@/features/profile/types";
import {
  selectUserConnected,
  selectProgress,
  selectNewModificationSubmit,
} from "@/store/reducers/member/member.selector";
import { InlineIcon } from "@iconify/react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import AspectStep from "./Aspect-step/Aspect.step";
import "./Formulaire.styles.scss";
import PratiquesStep from "./Pratiques-step/Pratiques.step";
import ProfilStep from "./Profil-step/Profil.step";
import SanteStep from "./Sante-step/Sante.step";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const FormulaireFct: React.FC<PropsFromRedux> = ({
  userConnected,
  progress,
  new_modification_submit,
}) => {
  const ps = useRef();

  const theme = useTheme();
  const matches_down_md = useMediaQuery(theme.breakpoints.down("md"));

  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const [valueTabs, setValueTabs] = useState(0);
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };

  const scrollTop = () => {
    const curr = (ps as React.MutableRefObject<any>).current;
    if (curr) {
      console.log("oohh");
      curr.scrollTop = 0;
    }
  };

  const navigate = useNavigate();

  const goTo = (routes: string) => {
    navigate(routes, { replace: true });
  };

  return (
    <div className="ct-formulaire">
      <div className="ct-formulaire__nav-part">
        <Button
          variant="contained"
          color="info"
          className="ct-formulaire__nav-part__btn-nav"
          onClick={() => {
            goTo(routePaths.profileChildren.myAccountChildren.ficheAbsolute);
          }}
          startIcon={<InlineIcon icon="ant-design:file-search-outlined" />}
        >
          Voir la fiche
        </Button>

        <Button
          variant="outlined"
          color="primary"
          className="ct-formulaire__nav-part__btn-nav"
          onClick={handleOpenAlert}
          startIcon={<InlineIcon icon="clarity:warning-line" />}
        >
          Bon à savoir
        </Button>
      </div>

      <div
        className="ct-formulaire__body-part"
        ref={(el) => ((ps as React.MutableRefObject<any>).current = el)}
      >
        <Collapse in={openAlert}>
          <Alert
            severity="warning"
            className="ct-formulaire__body-part__alert"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseAlert}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Informations utiles</AlertTitle>
            <strong>
              ** Infos réservées à zawaj sunnah et non-visibles par les membres
            </strong>
            <br />
            Toute modification du profil entraînera une modération de celui-ci.
            <br />
            Durant la modération, ton profil est inaccessible aux autres membres
            <br />
          </Alert>
        </Collapse>
        <div className="ct-formulaire__body-part__card-part">
          <div className="pastille-container">
            <div className="option">
              {(userConnected as TUserConnected).account.is_approved ? (
                <>
                  <div className="option-ok">
                    <InlineIcon icon="humbleicons:verified" />
                  </div>
                  <small className="ok">Profil approuvé</small>
                </>
              ) : (
                <>
                  <div className="option-ko">
                    <InlineIcon icon="material-symbols:cancel-outline-rounded" />
                  </div>
                  <small className="ko">
                    {" "}
                    Profil en attente de vérfification
                  </small>
                </>
              )}
            </div>
            <div className="option">
              <div className="option-ok">
                <InlineIcon icon="humbleicons:verified" />
              </div>
              <small className="ok">Engagement</small>
            </div>
          </div>

          <Box sx={{ width: "100%" }}>
            <p className="profil-progress-text">
              {" "}
              Profil rempli à <b>{progress}%</b>{" "}
            </p>
            <LinearProgress
              variant="determinate"
              color="success"
              value={progress}
            />
          </Box>

          {!(userConnected as TUserConnected).account.is_approved && (
            <Alert
              severity={
                (userConnected as TUserConnected).account
                  .changes_requests_rejected
                  ? "error"
                  : "info"
              }
              className="ct-formulaire__body-part__alert"
            >
              <AlertTitle>Modération du profil</AlertTitle>
              {(userConnected as TUserConnected).account
                .changes_requests_rejected ? (
                <>
                  Les modifications apportées à votre profil ont été{" "}
                  <b> rejetées</b> par notre équipe de modération.
                  <br />
                  <strong>
                    {" "}
                    Veuillez apporter les corrections adéquates à vos
                    modifications pour qu'elles puissent être prises en compte.
                  </strong>
                  <br />
                  Avant l'approbation de vos nouvelles modifications , votre
                  profil sera inaccessible aux autres membres
                  <br />
                </>
              ) : (
                <>
                  Vous avez effectué des modifications dans votre profil.
                  <br />
                  <strong>
                    {" "}
                    vos modifications sont donc en cours de modération
                  </strong>
                  <br />
                  Durant la modération, votre profil est inaccessible aux autres
                  membres
                  <br />
                </>
              )}
            </Alert>
          )}

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={valueTabs}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
                variant={matches_down_md ? "scrollable" : "fullWidth"}
                indicatorColor="primary"
              >
                <Tab
                  icon={
                    <InlineIcon
                      className="icon-tabs"
                      icon={`bi:1-circle${valueTabs === 0 ? "-fill" : ""}`}
                    />
                  }
                  iconPosition="start"
                  label="Votre profil"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
                <Tab
                  icon={
                    <InlineIcon
                      className="icon-tabs"
                      icon={`bi:2-circle${valueTabs === 1 ? "-fill" : ""}`}
                    />
                  }
                  iconPosition="start"
                  label="Aspect Réligieux"
                  id="simple-tab-1"
                  aria-controls="simple-tabpanel-1"
                />
                <Tab
                  icon={
                    <InlineIcon
                      className="icon-tabs"
                      icon={`bi:3-circle${valueTabs === 2 ? "-fill" : ""}`}
                    />
                  }
                  iconPosition="start"
                  label="Pratiques réligieuses"
                  id="simple-tab-2"
                  aria-controls="simple-tabpanel-2"
                />
                <Tab
                  icon={
                    <InlineIcon
                      className="icon-tabs"
                      icon={`bi:4-circle${valueTabs === 3 ? "-fill" : ""}`}
                    />
                  }
                  iconPosition="start"
                  label="Santé et handicap"
                  id="simple-tab-3"
                  aria-controls="simple-tabpanel-3"
                />
              </Tabs>
            </Box>
            <TabPanel value={valueTabs} index={0}>
              <ProfilStep chooseStep={setValueTabs} scrollTop={scrollTop} />
            </TabPanel>
            <TabPanel value={valueTabs} index={1}>
              <AspectStep chooseStep={setValueTabs} scrollTop={scrollTop} />
            </TabPanel>
            <TabPanel value={valueTabs} index={2}>
              <PratiquesStep chooseStep={setValueTabs} scrollTop={scrollTop} />
            </TabPanel>
            <TabPanel value={valueTabs} index={3}>
              <SanteStep chooseStep={setValueTabs} scrollTop={scrollTop} />
            </TabPanel>
          </Box>
        </div>
      </div>

      <CModalRemindPay
        fromAccount={true}
        open={
          !(userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_approved
        }
        type={
          (userConnected as TUserConnected).account.is_subscription_expired
            ? "reabonnement"
            : "abonnement"
        }
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  progress: selectProgress,
  new_modification_submit: selectNewModificationSubmit,
});

const connector = connect(mapStateToProps);
const Formulaire = connector(FormulaireFct);

export default Formulaire;
