import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";






export const  apiDesactivateAccount = ( data:any , access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/subscriptions/unsubscribe',data);
}

export const  apiActivateAccount = ( access_token: string, data:any ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/subscription-plans/'+data+'/checkout-session',config);
}

export const  apiActivateAccountPaypal = ( access_token: string, data:any ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/subscription-plans/'+data+'/checkout-session-paypal',config);
}

export const  apiDeleteAccount = ( data:any, access_token: string ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.patch('/members/delete/by-member',data,config);
}

export const  apiGetSubscriptionPlan = ( access_token?: string ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    false && console.log(config);
    return axios.get('/subscription-plans');

}

