import { HighlightOffRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./CommentSeVoir.style.scss";

type TCommentSeVoirProps = {
  closeModal: () => void;
};
const CommentSeVoir: React.FC<TCommentSeVoirProps> = ({ closeModal }) => {
  return (
    <PerfectScrollbar className="voir-scroll-container">
      <div className="voir-card">
        <IconButton
          className="close-icon"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          <HighlightOffRounded />
        </IconButton>

        <img src={require("@/assets/images/png/logo-mini.png")} alt="logo" />

        <p className="m-title">
          Votre discussion se déroule bien, vous avez posé les questions les
          plus pertinentes et vous aimeriez vous voir pour savoir si vous vous
          convenez physiquement ?
        </p>
        <p className="m-body">
          La démarche est simple : L'homme doit demander le numéro du tuteur de
          la soeur. Il n'y a que les sœurs qui sont autorisées à donner les
          numéros de leurs tuteurs.
          <br />
          <br />
          Concernant les visio : notre système de visio payante est actuellement
          en cours de développement. En attendant, vous devez faire intervenir
          vos familles. Par exemple, une personne des familles respectives vont
          organiser une visio sur zoom ou soit via zoom, Google meet. Et vous
          transmettez le lien sur la plate-forme en précisant qui est la tierce
          personne pour faire la mouqabala visio. Toute personne qui triche et
          ment et se retrouve en isolement dans la mouqabala vision sera bannie
          définitivement.
          <br />
          <br />
          Les femmes converties peuvent faire également intervenir leur famille
          non musulmane pour les visio ou les mouqabala. En revanche, pour le
          mariage, il faut impérativement un tuteur musulman car pas de mariage
          sans tuteur !
          <br />
          <br />
          Une fois le numéro du tuteur échangé, prenez garde à ne pas tomber
          dans l'isolement.
          <br />
          <br />
          ‼️Si vous échangez le numéro de vos intermédiaires, mais que vous
          entrez en contact sans eux en prenant le numéro du/de la prétendant(e)
          en cachette et que vous discutez, alors vous serez banni de nos
          services! Si un membre entre en contact avec vous sans une tierce
          personne merci de nous le signaler immédiatement en nous envoyant les
          preuves!
          <br />
          <br />
          Nous vous mettons 2 fatwa pour vous aider dans votre démarche :
        </p>

        <b className="fatwa">Première fatwa </b>
        <p className="m-title">Question ?</p>
        <p className="m-body">
          Que peut montrer la femme au prétendant [1] ? Et avec qui peut-elle
          entreprendre cette rencontre [2] ? ⚠️
          <br />
          💽 L'érudit et défunt cheikh ibn Baaz - rahimahullah - fut interrogé
          comme suit:
          <br />
          «Quel est le jugement du fait que le prétendant regarde sa prétendante
          alors qu'elle est dans tout son état d'embellissement et sans voile
          sur sa tête ?»
        </p>

        <p className="m-title">Réponse :</p>
        <p className="m-body">
          «Il n'y a pas de mal à cela. Le Prophète صلى الله عليه وسلم a ordonné
          de regarder la prétendante.
          <br />
          Donc la sounnah, pour le prétendant, est qu'il la voit, [elle], ainsi
          que son visage, ses cheveux, ses mains, ses pieds: il n'y a pas de mal
          à [voir] tout cela.
          <br />
          Il doit cependant la voir sans isolement; donc plutôt en la présence
          de son père, de sa mère, ou d'autres qu'eux deux. Ou [la voir] alors
          de loin, à travers une ouverture, un mur ou autre [chose] semblable.
          Mais qu'il s'isole avec elle en un endroit alors, non, cela n'est pas
          permis.
          <br />
          Et qu'il se réunisse plutôt avec elle en la présence de sa mère, de sa
          sœur, de sa tante paternelle ou d'autres qu'elles, il n'y a alors pas
          de mal, et il est même recommandé de la regarder car cela est une des
          causes de réussite et de durabilité de leur relation»
        </p>

        <p className="m-title">Question ?</p>
        <p className="m-body">
          «Ô noble cheikh, quelles sont les limites de ce qui est autorisé à
          voir [ndt: chez la femme] ?»
        </p>

        <p className="m-title">Réponse :</p>
        <p className="m-body">
          «Ce que l'on laisse habituellement paraître comme les mains, les
          pieds, le visage et la tête».
          <br />
          <br />
          <b>🗃 Source:</b>
          <br />
          <b>
            [1] : le prétendant est celui qui vient demander la main d'une
            femme. [2] : plus communément connu sous le nom de «mouqabala» et,
            en terminologie religieuse, connu sous le nom de: «regard légiféré»
            [النظرة الشرعية].
            <br />
            ا حكم نظر المخطوب إلى مخطوبته وهي بكامل زينتها، ودون حجاب على رأسها
            ؟
            <br />
            لا بأس بذلك، النبي-صلى الله عليه وسلم -أمر بالنظر إلى المخطوبة
            فالسنة للخاطب أن ينظر إليها, وإلى وجهها, وشعرها, ويديها, وقدميها كل
            هذا لا بأس به، لكن من غير خلوة، ينظر إليها من دون خلوة بها، بحضور
            أبيها, أو أمها, أو غيرهما, أو من بعيد من فرجة, أو من جدار, أو من شبه
            ذلك، لا بأس أما الخلوة بها في محل واحد، لا، لا يجوز، لكن إذا اجتمع
            بها مع أمها, أو مع أختها, أو مع عمتها، أو مع غيرهن، فلا حرج في ذلك
            بل هو مستحب، يستحب له أن ينظر؛ لأن هذا من أسباب التوفيق, ومن أسباب
            أن يؤدم بينهما.
            <br />
            ما حدود المسموح له بالنظر سماحة الشيخ ؟
            <br />
            ما جرت العادة بظهوره كاليد, والقدم, والوجه, والرأس.
          </b>
          <br />✅ Traduction faite par l'équipe al Miirath.
        </p>

        <b className="fatwa"> Seconde fatwa </b>
        <p className="m-title">Question ?</p>
        <p className="m-body">
          Ahsana Llâh ilaykum Shaykh, une soeur de France dit :
          <br />
          J’entreprends des démarches pour le mariage, mais je rencontre des
          difficultés avec mon père car il n’est pas dans la religion, et ne
          veut pas comprendre le concept des muqabala.
          <br />
          J’en ai fait plusieurs, mais pour lui c’est trop et il ne veut pas
          être dérangé pour ça. Mon oncle paternel, c’est pareil. Je n’ai qu’eux
          comme maharim.
          <br />
          Est-ce possible de prendre un autre tuteur car je me retrouve bloqué
          et ne peux donc pas me marier ?
          <br />
          Bâraka Llâhu fîkum
        </p>

        <p className="m-title">Réponse :</p>
        <p className="m-body">
          Comment ça les muqabala les dérange ?
          <br />
          Est-ce qu'il veut te marier comme ça, sans faire de muqabala ?
          <br />
          Tu peux regarder le prétendant, il peut te regarder, vous pouvez vous
          parler et vous mettre d'accord avec la présence de n'importe quelle
          3ème personne, même si cette personne n'est pas de tes maharim.
          <br />
          Ensuite, tu informes ton père, qui est ton tuteur légal, et il ne faut
          pas prendre à la légère le fait de se marier sans tuteur.
          <br />
          Le Prophète ﷺ a dit : ' N'importe quelle femme qui se marie sans
          l'accord de son père (tuteur), alors son mariage est nul !
          <br />
          Shaykh Tal'ât Zahrân حفظه الله Traduit par Ummu Radia Corrigé par
          Bintu Zahir حفظهما الله
        </p>
      </div>
    </PerfectScrollbar>
  );
};

export default CommentSeVoir;
