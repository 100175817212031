import { Box, Grid } from '@mui/material';
import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import "./Staff.styles.scss";

const CtStaff:React.FC = () => {



  return (
    <PerfectScrollbar className='ct-staff'>
        
        <Box >
            <Grid container rowSpacing={4} columnSpacing={2} >
                  <Grid item xs={12} sm={12} lg={4}>
                        <h2 className="title-part"> LES FONDATEURS </h2>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                          <div className="img-card">
                              <img
                                src={require("@/assets/images/png/avatar-male.png")}
                                alt="logo-mini"
                              />
                              <p> ABOU RAYHANNA </p>
                          </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                          <div className="img-card">
                              <img
                                src={require("@/assets/images/png/avatar-female.png")}
                                alt="logo-mini"
                              />
                              <p> OUM RAYHANNA </p>
                          </div>
                  </Grid>
            </Grid>

            <Grid container mt={1} rowSpacing={4} columnSpacing={2} >
                  <Grid item xs={12} sm={12} lg={4}>
                        <h2 className="title-part"> NOS MODÉRATEURS </h2>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={8}>
                          <div className="text-card">
                              <img
                                    src={require("@/assets/images/gif/moderateurs.gif")}
                                    alt="logo-mini"
                                  />
                              <p> 
                                  Notre équipe de modérateurs est là pour vous accompagner durant vos <br/>
                                  échanges. Ils lisent tous vos messages avant validation afin de vous garantir <br/>
                                  votre sécurité et le respect des règles religieuses.
                              </p>
                          </div>
                  </Grid>
            </Grid>

            <Grid container mt={3} rowSpacing={4} columnSpacing={2} >
                  <Grid item xs={12} sm={12} lg={12}>
                        <h2 className="title-part about"> À PROPOS DE ZAWAJ SUNNAH</h2>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>

                        <p className="about-text">
                            Lorsque nous nous sommes mariés, mon épouse et moi, nous nous sommes promis de faciliter les frères et les sœurs 
                            pour le mariage. 
                            <br/>
                            <br/>
                            De fil en aiguille, le bouche à oreille aidant, nous nous sommes retrouvés avec plus de 130 frères et le double 
                            de soeurs d’inscrits ! Résultats : en moins d’un an, une vingtaine de mariages ont été célébrés. Et toutes les louanges reviennent de droit à Allãh.
                            <br/>
                            <br/>
                            À notre connaissance, seuls 2 mariages n’ont pas duré et ont fini par des divorces. Aujourd’hui nous avons décidé de nous investir d'avantage et c’est 
                            ainsi que nous avons décidé de créer ce site afin de faciliter à un plus grand nombre de nos frères et sœurs cette grande adoration.
                            <br/>
                            <br/>
                            Qu’Allāh nous accorde la sincérité et vous facilite le mariage. Mes frères et sœurs, soyez les bienvenus.
                            <br/>
                            <br/>
                            Notre équipe de modérateurs est là pour vous accompagner durant vos échanges. Ils lisent tous vos messages avant validation afin de vous garantir 
                            votre sécurité et le respect des règles religieuses.
                        </p>

                        <img  src={require("@/assets/images/png/logo-alt.png")}
                              alt="logo-alt"
                              className='logo-end'/>

                  </Grid>
            </Grid>

        </Box>

    </PerfectScrollbar>
  )
}

export default CtStaff