import { InlineIcon } from '@iconify/react';
import { HighlightOffRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './QuestionAPoser.style.scss';

type TQuestionAPoserProps ={
    closeModal : ()=> void,
}
const QuestionAPoser: React.FC<TQuestionAPoserProps> = ({
    closeModal
}) => {
    return (
        <PerfectScrollbar className='question-scroll-container'>
            <div className="question-card">
                <IconButton className='close-icon'
                    size="large"
                    color='secondary'
                    onClick={closeModal}>
                    <HighlightOffRounded />
                </IconButton>

                <img src={require('@/assets/images/png/logo-mini.png')} alt="logo"/>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                            Réligion
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/religion-musulmane.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small> Choisis celle/celui qui détient la piété ! </small>
                <p className="m-body">
                            • &nbsp;Parle-moi de ton cheminement religieux ? Depuis combien de temps pratiques-tu ?
                    <br/>   • &nbsp;Accomplis-tu tes obligations religieuses ? Prière, jeûne, zakat, etc.
                    <br/>   • &nbsp;Quel est ta relation/implication  avec la communauté ? Masdjid /cours/association ?
                    <br/>   • &nbsp;Quels savants et prédicateurs écoutes-tu ?
                    <br/>   • &nbsp;Suis-tu des cours ? Si oui, avec qui, et quels livres étudiez-vous ? Sinon, est-ce dans tes projets ?
                    <br/>   • &nbsp;Qu’est-ce qui te paraît le plus important dans ta religion ?
                    <br/>   • &nbsp;Suis-tu un courant religieux ?
                    <br/>   • &nbsp;Connais-tu les sectes, si oui peux-tu m’en citer quelques unes ?
                    <br/>   • &nbsp;De quelle façon te comporte-tu quand une personne n’accepte pas ton rappel ? Acceptes-tu facilement le rappel ?
                    <br/>   • &nbsp;Peux-tu me citer les droits qu’Allah t’a donné dans le mariage ?
                    <br/>   • &nbsp;Peux-tu me citer les devoirs qu’Allah t’a ordonné dans le mariage ?
                    <br/>   • &nbsp;Que penses-tu des gouverneurs des pays musulmans ?
                    <br/>   • &nbsp;Que penses-tu du fait de sortir manifester ?
                    <br/>   • &nbsp;Comment conçois-tu un mariage religieusement parlant (fête, walima, dot) ?
                    <br/>   • &nbsp;Que penses-tu pouvoir offrir spirituellement/religieusement parlant à ton partenaire de vie ?
                    <br/>   • &nbsp;Quel est ton rapport à la mixité homme/femme dans ta vie ?
                    <br/>   • &nbsp;Que penses-tu de l’égalité homme/femme ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Comportement
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/bon-comportement-islam.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small> «Le vrai musulman est celui dont les musulmans n’ont à redouter ni la langue ni la main.» </small>
                <p className="m-body">
                            • &nbsp;Cite 3 valeurs/qualités que tu recherches chez ton partenaire ? Cite 3 défauts que tu ne supporterais pas ?
                    <br/>   • &nbsp;Peux-tu décrire ta personnalité ? Ton caractère ?
                    <br/>   • &nbsp;Quels sont tes défauts et tes qualités ?
                    <br/>   • &nbsp;Comment gères-tu les conflits ?
                    <br/>   • &nbsp;Comment s’exprime ta colère ? As-tu tendance à hausser le ton, à crier ?
                    <br/>   • &nbsp;T’est-il déjà arrivé de te montrer violent ?
                    <br/>   • &nbsp;De quelle manière patientes-tu face aux gens ? Face aux épreuves ?
                    <br/>   • &nbsp;Comment réagis-tu si l’on se montre injuste envers toi ?
                    <br/>   • &nbsp;Quel est le dernier service que tu as rendu ?
                    <br/>   • &nbsp;Que ne pourrais-tu pas pardonner ? Es-tu rancunier ?
                    <br/>   • &nbsp;Comment reconnais-tu tes torts ? Demandes-tu pardon ?
                    <br/>   • &nbsp;Quel est ton rapport face a l’autorité ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Vie maritale
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/couple-musulman-1.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small> <b>Il a créé de vous, pour vous, des épouses pour que vous viviez en tranquillité avec elles et Il a mis entre vous de l’affection et de la bonté.</b>  (sourate Ar-Rûm verset 21)</small>
                <p className="m-body">
                            • &nbsp;Comment conçois-tu la vie de couple ?
                    <br/>   • &nbsp;Qu’attends-tu du mariage ?
                    <br/>   • &nbsp;Qu’est-ce qui te paraît important dans un couple ?
                    <br/>   • &nbsp;Quelles sont les choses qu’il te tient à cœur de voir chez ton époux(se) ? Y’a-t-il des caractéristiques qui te sont indispensables ?
                    <br/>   • &nbsp;Quel est ton rapport au ménage, à la cuisine ?
                    <br/>   • &nbsp;Qu’attends-tu que je fasse au sein du foyer ?
                    <br/>   • &nbsp;Comment vois-tu les sorties dans un couple ?
                    <br/>   • &nbsp;À l’inverse, quels sont les critères rédhibitoires pour toi ?
                    <br/>   • &nbsp;Comment exprimes-tu ton affection ?
                    <br/>   • &nbsp;Es-tu attentionné ? As-tu besoin qu’on soit attentionné envers toi ?
                    <br/>   • &nbsp;Quelles sont les raisons qui pourraient te pousser à divorcer ?
                    <br/>   • &nbsp;Quel est ta vision de la polygamie ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Divorce
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/divorce-islam-1.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small> Quand vous répudiez les femmes, répudiez-les conformément à leur période d’attente prescrite; et comptez la période; et craignez Allah votre Seigneur. <b>AT-TALAQ (S65.v1)</b>  </small>
                <p className="m-body">
                            • &nbsp;As-tu déjà été marié ? Si oui, combien de fois ?
                    <br/>   • &nbsp;Depuis combien de temps as-tu divorcé ? Quelles sont les raisons de ton/tes divorce(s) ?
                    <br/>   • &nbsp;Qu’est ce que tu aurais pu faire de plus pour éviter ce divorce ?
                    <br/>   • &nbsp;Quelles sont tes relations/contact avec l’autre parent ?
                    <br/>   • &nbsp;As-tu des enfants ? Si oui, combien, quel est leur genre et leur âge ? Peux-tu décrire leur personnalité ?
                    <br/>   • &nbsp;Comment s’organise la garde ? En cas de hijra ?
                    <br/>   • &nbsp;Quelles relations souhaites-tu que nous entretenions (avec tes enfants) ?
                    <br/>   • &nbsp;Ton ex pourrait-il/elle nous faire des problèmes en cas de mariage ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Enfants
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/education-enfant-islam.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small>  <b>L’homme est un berger dans sa famille et il est responsable de son troupeau. La femme est une bergère dans la maison de son époux et elle est responsable de son troupeau.</b>  </small>
                <p className="m-body">
                            • &nbsp;Combien d’enfants souhaites-tu avoir ?
                    <br/>   • &nbsp;Souhaites-tu des grossesses rapprochées ou espacés ?
                    <br/>   • &nbsp;Comment vois-tu l’apprentissage religieux des enfants ?
                    <br/>   • &nbsp;Quels métiers voudrais-tu pour tes enfants ?
                    <br/>   • &nbsp;Comment conçois-tu l’éducation des enfants ?
                    <br/>   • &nbsp;Comment comptes-tu corriger leur comportement ?
                    <br/>   • &nbsp;Comment as-tu été élevé par tes parents ?
                    <br/>   • &nbsp;Quel est ton avis sur l’école publique ? L’école à la maison ?
                    <br/>   • &nbsp;Quelle est ton opinion concernant la télévision à la maison, les jeux vidéo ?
                    <br/>   • &nbsp;Quel est ton avis sur l’allaitement ? Sur le fait de faire appel à une nourrice ou de faire garder les enfants ?
                    <br/>   • &nbsp;Quel avenir vois-tu pour tes enfants ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Famille
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/famille-musulmane.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small>  <b>Nous avons commandé à l’homme (la bienfaisance envers) ses pères et mères </b> sourate Luqmân verset 14  </small>
                <p className="m-body">
                            • &nbsp;Parle moi de la relation avec ta famille ? As-tu besoin de leur rendre visite ? Quelle fréquence ?
                    <br/>   • &nbsp;Comment est ta famille vis-à-vis de la religion ?
                    <br/>   • &nbsp;Comment se comportent-ils avec les étrangers (gendres, belles-filles) ?
                    <br/>   • &nbsp;En cas de conflit entre ta famille et moi, quelle sera ton attitude ?
                    <br/>   • &nbsp;Qu’attends-tu de moi par rapport à tes parents ?
                    <br/>   • &nbsp;Que penses-tu du fait de cohabiter avec ta famille, la mienne ?
                    <br/>   • &nbsp;Qu’est-ce-que ta famille ne supporterait pas comme comportement chez ton conjoint ? Quelles sont leurs attentes envers moi ?
                    <br/>   • &nbsp;Ta famille pourrait-elle se mêler de tes affaires de couple ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Sante
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/sante-islam.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small>  <b> Le croyant mange de façon à ne remplir qu’un seul (ma’y) et le non-croyant mange de façon à en remplir sept. »</b> (Rapporté par Mâlik , Boukhari, 5081 et Mouslim, 2060).  </small>
                <p className="m-body">
                    <br/>   • &nbsp;As-tu des problèmes de santé physiques ou psychologiques ?
                    <br/>   • &nbsp;Souffres-tu ou as-tu déjà souffert de maux occultes ?
                    <br/>   • &nbsp;Y’a-t-il des pathologies dans ta famille dont je devrais être mis au courant ?
                    <br/>   • &nbsp;Accepterais-tu d’effectuer des examens biologiques (test, dépistage) avant notre mariage ?
                    <br/>   • &nbsp;Souhaites-tu me faire part de quelque chose concernant ta santé ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Hijra
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/hijra-1.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small>  Le Prophète (que la prière d’Allah et Son salut soient sur lui) a dit: « Je me désavoue de tout musulman qui réside parmi les associateurs ». <b>(Rapporté par Tirmidhi dans ses Sounan n°1604 )</b>  </small>
                <p className="m-body">
                            • &nbsp;As-tu l’intention d’accomplir la hijra ? Si oui, où ?
                    <br/>   • &nbsp;Te vois-tu vivre en ville ou en campagne ?
                    <br/>   • &nbsp;Quand envisages-tu d’émigrer ? Est-ce un projet à court, moyen ou long terme ?
                    <br/>   • &nbsp;As-tu commencé à préparer la hijra ? De quelle manière ?
                    <br/>   • &nbsp;Réalises-tu des économies, fais-tu des causes concrètes pour la hijra ?
                    <br/>   • &nbsp;Comment vois-tu la gestion des dépenses en hijra ?
                    <br/>   • &nbsp;Envisages-tu d’effectuer des allers-retours ?
                    <br/>   • &nbsp;Quelle est ta vision concernant la visite de nos familles ?
                    <br/>   • &nbsp;Tes proches peuvent-ils être un frein dans ce projet ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Travail et études
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/travail-halal-1.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small>  Le Prophète (que la prière d’Allah et Son salut soient sur lui) a dit: « Aucun homme n’a gagné un meilleur salaire que ce qu’il a gagné par le travail de ses mains. ». <b> (Rapporté par Ibn Maja et authentifié par Cheikh Albani dans Sahih Al Jami n°5660) </b>  </small>
                <p className="m-body">
                            • &nbsp;Parle-moi de ton parcours scolaire, social et professionnel ?
                    <br/>   • &nbsp;Quel est ton niveau d’étude ?
                    <br/>   • &nbsp;Quelle est ton activité ? Quel est ton rapport au travail ?
                    <br/>   • &nbsp;Que penses-tu du travail de la femme ?
                    <br/>   • &nbsp;Comment prendrais-tu l’échec d’un de tes projets ? Que ferais-tu ?
                </p>
            </>
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Argent
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/argent-halal.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small>  Le Prophète a dit: « نِعْمَ الْمالُ الصّالِحُ لِلرَّجُلِ الصّالِحِ » Ce qui signifie: <b>  « Quel bienfait que l’argent licite pour l’homme vertueux !»</b>  </small>
                <p className="m-body">
                            • &nbsp;Comment décrirais-tu ton rapport à l’argent ?
                    <br/>   • &nbsp;Quelle est ta conception des dépenses dans un couple ? De la gestion de l’argent ?
                    <br/>   • &nbsp;Quel est ton avis sur la gestion des biens de la femme ?
                    <br/>   • &nbsp;As-tu des projets qui nécessitent d’économiser ?
                    <br/>   • &nbsp;Comment réagis-tu en cas de difficultés financières ?
                    <br/>   • &nbsp;Peux-tu décrire ton train de vie ?
                    <br/>   • &nbsp;Quelles sont tes limites en termes de patience par rapport aux biens ? Qu’est-ce qui te serait difficilement supportable ?
                    <br/>   • &nbsp;As-tu des dettes ?
                    <br/>   • &nbsp;Quelles sont tes responsabilités financières actuellement ? Charges, pensions, soutien familial, etc.
                    <br/>   • &nbsp;Quel devoir as-tu envers moi vis à vis des dépenses ?
                    <br/>   • &nbsp;As-tu déjà songé à la dot ?
                </p>
            </> 
            <>
                <p className="m-title"> 
                    <InlineIcon icon="tabler:tilde" flip="horizontal"/>
                        &nbsp; 
                        Loisirs
                        &nbsp; 
                    <InlineIcon icon="tabler:tilde"/> 
                </p>
                <img src={require('@/assets/images/png/sport-halal-2.png')}  
                     alt="img" 
                     className="m-img-title" />
                <small> Le Prophète aleyhi salat wa salam a dit : <b> Tout ce par quoi le musulman se divertit est vain, sauf le fait qu’il tire à l’arc, qu’il entraîne son cheval, ou qu’il joue avec son épouse : ces (actions) relèvent du haqq” </b> (at-Tirmidhî, partie du hadîth 1637)  </small>
                <p className="m-body">
                            • &nbsp;Quels sont tes hobbies ?
                    <br/>   • &nbsp;Quelle est ton rapport aux sorties ? Es-tu souvent dehors ?
                    <br/>   • &nbsp;Comment occupes-tu ton temps libre ?
                    <br/>   • &nbsp;Pratiques-tu une activité sportive ?
                    <br/>   • &nbsp;Quels sont tes centres d’intérêt ?
                    <br/>   • &nbsp;Regardes-tu la télévision, les séries, les films, etc ?
                    <br/>   • &nbsp;Es-tu sur les réseaux sociaux ? Si oui, lesquels et comment les utilises-tu ?
                </p>
            </>    
            </div>
        </PerfectScrollbar>
    )
}

export default QuestionAPoser
