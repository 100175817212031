import { TAdditionnalInfosHealthAndDisability, TAdditionnalInfosProfil, TAdditionnalInfosReligiousAspect, TAdditionnalInfosReligiousPractices, TNotification, TUserConnected } from "@/features/profile/types";
import memberTypes from "./member.types";
interface IGetUserConnected{
  type: memberTypes.GET_USER_CONNECTED;
  payload: TUserConnected;
}

interface IGetToken{
  type: memberTypes.GET_TOKEN;
  payload: string;
}

interface IUpdateProgress{
  type: memberTypes.UPDATE_PROGRESS;
  payload: number;
}

interface IUpdateLocation{
  type: memberTypes.UPDATE_LOCATION;
  payload: { latitude : number, longitude: number };
}

interface IUpdateProfile{
  type: memberTypes.UPDATE_PROFILE;
  payload: TAdditionnalInfosProfil;
}

interface IUpdateReligiousAspect{
  type: memberTypes.UPDATE_RELIGIOUS_ASPECT;
  payload: TAdditionnalInfosReligiousAspect;
}

interface IUpdateReligiousPractice{
  type: memberTypes.UPDATE_RELIGIOUS_PRACTICES;
  payload: TAdditionnalInfosReligiousPractices;
}

interface IUpdateHealthAndDisability{
  type: memberTypes.UPDATE_HEALTH_AND_DISABILITY;
  payload: TAdditionnalInfosHealthAndDisability;
}
interface IUpdateApproved{
  type: memberTypes.UPDATE_IS_APPROVED;
  payload: boolean;
}

interface IUpdateIsActive{
  type: memberTypes.UPDATE_IS_ACTIVE;
  payload: boolean;
}

interface IUpdateIsOnline{
  type: memberTypes.UPDATE_IS_ONLINE;
  payload: boolean;
}

interface IUpdateIsFreshnew{
  type: memberTypes.UPDATE_IS_FRESHNEW;
  payload: boolean;
}

interface IUpdateIsFicheFilled{
  type: memberTypes.UPDATE_IS_FICHE_FILLED;
  payload: boolean;
}

interface IUpdateIsSubscriptionActive{
  type: memberTypes.UPDATE_IS_SUBSCRIPTION_ACTIVE;
  payload: boolean;
}

interface IUpdateNotification{
  type: memberTypes.UPDATE_NOTIFICATION;
  payload: TNotification[];
}

interface IUpdateMessageNotif{
  type: memberTypes.UPDATE_MESSAGE_NOTIF;
  payload: number;
}

interface IUpdateFavoriteNotif{
  type: memberTypes.UPDATE_FAVORITE_NOTIF;
  payload: number;
}

interface IUpdateExchangeNotif{
  type: memberTypes.UPDATE_EXCHANGE_NOTIF;
  payload: number;
}

interface IUpdatePendingCancelConversation{
  type: memberTypes.UPDATE_PENDING_CANCEL_CONVERSATION;
  payload: boolean;
}

interface IUpdateAcceptExchange{
  type: memberTypes.UPDATE_ACCEPT_EXCHANGE;
  payload: boolean;
}

interface IUpdateNewModificationSubmit{
  type: memberTypes.UPDATE_NEW_MODIFICATION_SUBMIT;
  payload: boolean;
}

export type MemberAction =  IGetUserConnected | 
                            IGetToken | 
                            IUpdateProgress | 
                            IUpdateLocation | 
                            IUpdateProfile | 
                            IUpdateReligiousAspect | 
                            IUpdateReligiousPractice | 
                            IUpdateHealthAndDisability | 
                            IUpdateApproved |
                            IUpdateIsActive |
                            IUpdateIsFreshnew |
                            IUpdateIsFicheFilled |
                            IUpdateIsSubscriptionActive |
                            IUpdateIsOnline |
                            IUpdateNotification |
                            IUpdateMessageNotif |
                            IUpdateFavoriteNotif |
                            IUpdateExchangeNotif |
                            IUpdatePendingCancelConversation |
                            IUpdateNewModificationSubmit |
                            IUpdateAcceptExchange;


export const getUserConnected = (user:TUserConnected) => ({
  type: memberTypes.GET_USER_CONNECTED,
  payload: user
});

export const getToken = (token:string) => ({
  type: memberTypes.GET_TOKEN,
  payload: token
});


export const updateProgress = (progress:number) => ({
  type: memberTypes.UPDATE_PROGRESS,
  payload: progress
});

export const updateLocation = ( location: { latitude : number, longitude: number }) => ({
  type: memberTypes.UPDATE_LOCATION,
  payload: location
});

export const updateProfile = ( profile: TAdditionnalInfosProfil) => ({
  type: memberTypes.UPDATE_PROFILE,
  payload: profile
});

export const updateReligiousAspects = ( religiousAspect: TAdditionnalInfosReligiousAspect) => ({
  type: memberTypes.UPDATE_RELIGIOUS_ASPECT,
  payload: religiousAspect
});

export const updateReligiousPractices = ( religiousPractice: TAdditionnalInfosReligiousPractices) => ({
  type: memberTypes.UPDATE_RELIGIOUS_PRACTICES,
  payload: religiousPractice
});

export const updateHealthAndDisability = ( healthAndDisability: TAdditionnalInfosHealthAndDisability) => ({
  type: memberTypes.UPDATE_HEALTH_AND_DISABILITY,
  payload: healthAndDisability
});

export const updateIsApproved = (is_approved:boolean) => ({
  type: memberTypes.UPDATE_IS_APPROVED,
  payload: is_approved
});

export const updateIsActive = (is_available:boolean) => ({
  type: memberTypes.UPDATE_IS_ACTIVE,
  payload: is_available
});

export const updateIsOnline = (is_online:boolean) => ({
  // normalement c'est is_available au lieu de is_online (je suis un peu fatigué)
  type: memberTypes.UPDATE_IS_ONLINE,
  payload: is_online
});

export const updateIsFreshnew = (is_fresh_new:boolean) => ({
  type: memberTypes.UPDATE_IS_FRESHNEW,
  payload: is_fresh_new
});

export const updateIsFicheFilled = (are_main_infos_provided:boolean) => ({
  type: memberTypes.UPDATE_IS_FICHE_FILLED,
  payload: are_main_infos_provided
});

export const updateIsSubscriptionActive = (is_subscription_active:boolean) => ({
  type: memberTypes.UPDATE_IS_SUBSCRIPTION_ACTIVE,
  payload: is_subscription_active
});

export const updateNotification = (notification:TNotification[]) => ({
  type: memberTypes.UPDATE_NOTIFICATION,
  payload: notification
});

export const updateMessageNotif = (nbre_message_notif:number) => ({
  type: memberTypes.UPDATE_MESSAGE_NOTIF,
  payload: nbre_message_notif
});

export const updateFavoriteNotif = (nbre_favorite_notif:number) => ({
  type: memberTypes.UPDATE_FAVORITE_NOTIF,
  payload: nbre_favorite_notif
});

export const updateExchangeNotif = (nbre_exchange_notif:number) => ({
  type: memberTypes.UPDATE_FAVORITE_NOTIF,
  payload: nbre_exchange_notif
});

export const updatePendingCancelConversation = (pending_cancel_conversation:boolean) => ({
  type: memberTypes.UPDATE_PENDING_CANCEL_CONVERSATION,
  payload: pending_cancel_conversation
});

export const updateAcceptExchange = (has_conversation:boolean) => ({
  type: memberTypes.UPDATE_ACCEPT_EXCHANGE,
  payload: has_conversation
});

export const updateNewModificationSubmit = (new_modification_submit:boolean) => ({
  type: memberTypes.UPDATE_NEW_MODIFICATION_SUBMIT,
  payload: new_modification_submit
});

