import { InlineIcon } from "@iconify/react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ListManager.scss";
import classnames from "classnames";
import "moment/locale/fr";
import { TProfileList } from "@/features/profile/pages/Home-content/Home.content";
import ProfileCard from "@/features/profile/Elements/ProfileCard/ProfileCard";
import NoData from "../No-data/NoData";

type TListManager = {
  title?: string;
  subtitle?: string;
  profileList?: TProfileList;
  isForExchange?: boolean;
  isInProgress?: boolean;
  isForCloture?: boolean;
  loading?: boolean;
  alt?: boolean;
  no_corner_option?: boolean;
};

type TFilteredValue = "sent" | "received" | "";

const ListManager: React.FC<TListManager> = ({
  title = "Ceux que j'intéresse",
  subtitle = "Mes favoris",
  profileList = [],
  isForExchange = false,
  isInProgress = false,
  isForCloture = false,
  alt = false,
  loading = false,
  no_corner_option = false,
}) => {
  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [itemCardWidth, setItemCardWidth] = useState(330);
  const [scrollInt, setScrollInt] = useState(0);

  const [activeButton, setActiveButton] = useState<TFilteredValue>("");

  const [searchedText, setSearchedText] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
  };

  const scrollRight = () => {
    let nbrOfLoadedItem = profileList
      .filter((value) => {
        const fullname =
          value?.username! +
          value?.age! +
          value?.country +
          value?.town +
          value?.origine +
          value?.nationalite +
          value?.profil_id;
        return fullname;
      })
      .filter((value) => {
        return value?.sendStatut!.indexOf(activeButton) !== -1;
      }).length;

    let maxScrollRight = (nbrOfLoadedItem - 1) * itemCardWidth;

    if (scrollInt < 0) {
      setScrollInt(0);
      document.getElementsByClassName("list-part")[0]?.scroll({
        top: 0,
        left: itemCardWidth,
        behavior: "auto",
      });
    } else if (scrollInt >= maxScrollRight) {
      setScrollInt(maxScrollRight);
      document.getElementsByClassName("list-part")[0]?.scroll({
        top: 0,
        left: maxScrollRight,
        behavior: "auto",
      });
    } else {
      setScrollInt(scrollInt + itemCardWidth);
      document.getElementsByClassName("list-part")[0]?.scroll({
        top: 0,
        left: scrollInt + itemCardWidth,
        behavior: "auto",
      });
    }

    console.log(scrollInt);
  };

  const scrollLeft = () => {
    if (scrollInt <= 0) {
      setScrollInt(0);
      document.getElementsByClassName("list-part")[0]?.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    } else {
      setScrollInt(scrollInt - itemCardWidth);
      document.getElementsByClassName("list-part")[0]?.scroll({
        top: 0,
        left: scrollInt - itemCardWidth,
        behavior: "auto",
      });
    }
  };

  useEffect(() => {
    if (document.querySelector(".card-profile")) {
      const fakeItemCard = document.querySelector(".card-profile");
      const size = (fakeItemCard as HTMLElement).clientWidth + 20;
      setItemCardWidth(size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.querySelector(".card-profile")]);

  return (
    <div
      className={classnames("list-manager-container", {
        isForExchange: isForExchange,
      })}
    >
      {!isForExchange && (
        <>
          <div className="title-part">
            {subtitle.length > 0 ? (
              <>
                <span className="title"> {title} /</span>&nbsp;
                <span className="subtitle"> {subtitle} </span>
              </>
            ) : (
              <span className="title"> {title} </span>
            )}
          </div>

          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                lg={8}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  placeholder="Rechercher un membre"
                  variant="outlined"
                  name="recherche"
                  className="searchInput-alt"
                  type="text"
                  value={searchedText}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InlineIcon icon="bi:search" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="chip-kpi">
                  <b> {profileList.length} </b>
                  <span> membre(s) </span>
                </div>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {isForExchange && (
        <>
          <div className="title-part isForExchange">
            {subtitle.length > 0 ? (
              <>
                <span className="title"> {title} /</span>&nbsp;
                <span className="subtitle"> {subtitle} </span>
              </>
            ) : (
              <span className="title"> {title} </span>
            )}
            <div className="circle">
              <b> {profileList.length} </b>
            </div>
            <TextField
              placeholder="Rechercher un membre"
              variant="outlined"
              name="recherche"
              className="searchInput"
              type="text"
              value={searchedText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            {isInProgress && (
              <>
                <div className="chip-kpi-gray">
                  <b>
                    {
                      profileList
                        .filter((value) => {
                          const fullname =
                            value?.username! +
                            value?.age! +
                            value?.country +
                            value?.town +
                            value?.origine +
                            value?.nationalite +
                            value?.profil_id;
                          return fullname;
                        })
                        .filter((value) => {
                          return (
                            value?.sendStatut!.indexOf(activeButton) !== -1
                          );
                        }).length
                    }
                  </b>
                  <span> membre(s) </span>
                </div>
                <div className="filter-part">
                  <div
                    className={classnames("msg-filtered-box ", {
                      send: activeButton === "sent",
                    })}
                    onClick={() => {
                      if (activeButton === "sent") {
                        setActiveButton("");
                      } else {
                        setActiveButton("sent");
                      }
                    }}
                  >
                    <InlineIcon icon="carbon:mail-reply" className="sent" />
                    <small>Envoyées</small>
                  </div>
                  <div
                    className={classnames("msg-filtered-box ", {
                      receive: activeButton === "received",
                    })}
                    onClick={() => {
                      if (activeButton === "received") {
                        setActiveButton("");
                      } else {
                        setActiveButton("received");
                      }
                    }}
                  >
                    <InlineIcon
                      icon="carbon:mail-reply"
                      className="received"
                      flip="horizontal"
                    />
                    <small>Reçues</small>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {loading && (
        <img
          src={require("@/assets/images/gif/loadmore.gif")}
          height="70"
          width="70"
          className="load-more"
          alt="load-more"
        />
      )}

      {false &&
        !(
          (profileList.length === 0 ||
            profileList
              .filter((value) => {
                const fullname =
                  value?.username! +
                  value?.age! +
                  value?.country +
                  value?.town +
                  value?.origine +
                  value?.nationalite +
                  value?.profil_id;
                return (
                  fullname
                    .toLocaleLowerCase()
                    .indexOf(searchedText.toLocaleLowerCase()) !== -1
                );
              })
              .filter((value) => {
                return value?.sendStatut!.indexOf(activeButton) !== -1;
              }).length === 0) &&
          !loading
        ) &&
        matches_sm && (
          <div className="horizontal-top-management">
            <Button
              className="left-icon nav-btn-scroll"
              size="large"
              color="primary"
              onClick={scrollLeft}
              startIcon={<InlineIcon icon="ep:arrow-left" />}
            >
              Précédent
            </Button>

            <Button
              className="right-icon nav-btn-scroll"
              size="large"
              color="primary"
              onClick={scrollRight}
              endIcon={<InlineIcon icon="ep:arrow-right" />}
            >
              Suivant
            </Button>
          </div>
        )}

      {profileList.length > 0 &&
        profileList.filter((value) => {
          const fullname =
            value?.username! +
            value?.age! +
            value?.country +
            value?.town +
            value?.origine +
            value?.nationalite +
            value?.profil_id;
          return (
            fullname
              .toLocaleLowerCase()
              .indexOf(searchedText.toLocaleLowerCase()) !== -1
          );
        }).length > 0 &&
        !loading && (
          <div
            className={classnames("list-part", {
              isForExchange: isForExchange,
            })}
          >
            <>
              {profileList
                .filter((value) => {
                  const fullname =
                    value?.username! +
                    value?.age! +
                    value?.country +
                    value?.town +
                    value?.origine +
                    value?.nationalite +
                    value?.profil_id;
                  return (
                    fullname
                      .toLocaleLowerCase()
                      .indexOf(searchedText.toLocaleLowerCase()) !== -1
                  );
                })
                .filter((value) => {
                  return value?.sendStatut!.indexOf(activeButton) !== -1;
                })
                .map((value, index) => {
                  return (
                    <ProfileCard
                      key={index}
                      alt={alt}
                      no_corner_option={no_corner_option}
                      isCloture={isForCloture}
                      isExchange={isForExchange}
                      isOnline={value?.isOnline}
                      isVerified={value?.isVerified}
                      hasMore={value?.hasMore}
                      username={value?.username}
                      description={value?.description}
                      profile_wanted={value?.profile_wanted}
                      type={value?.type}
                      age={value?.age}
                      town={value?.town}
                      country={value?.country}
                      origine={value?.origine}
                      profil_id={value?.profil_id}
                      nationalite={value?.nationalite}
                      progressValue={value?.progressValue}
                      sendStatut={value?.sendStatut}
                      isMutualFavorite={value?.isMutualFavorite}
                      isSimpleFavorite={value?.isSimpleFavorite}
                      conversation_id={value?.conversation_id}
                      cloture={value?.cloture}
                      isListeNoire={value?.isListeNoire}
                      has_conversation={value?.has_conversation}
                      is_deleted={value?.is_deleted}
                      date_of_creation={value?.date_of_creation}
                    />
                  );
                })}
            </>
          </div>
        )}

      {(profileList.length === 0 ||
        profileList
          .filter((value) => {
            const fullname =
              value?.username! +
              value?.age! +
              value?.country +
              value?.town +
              value?.origine +
              value?.nationalite +
              value?.profil_id;
            return (
              fullname
                .toLocaleLowerCase()
                .indexOf(searchedText.toLocaleLowerCase()) !== -1
            );
          })
          .filter((value) => {
            return value?.sendStatut!.indexOf(activeButton) !== -1;
          }).length === 0) &&
        !loading && <NoData />}
    </div>
  );
};

export default ListManager;
