import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const  apiGetBlackList = ( access_token: string  ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/members/blocked/members',config);
}


export const  apiGetHideList = ( access_token: string  ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/test',config);
}


export const  apiAddToBlacklist = ( member_id:string, access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/members/block/'+member_id);
}

export const  apiRemoveFromBlacklist = ( member_id:string, access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/members/unblock/'+member_id);
}
