import React, { useEffect, useRef, useState } from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import arrowDown from "@iconify/icons-ep/arrow-down";
import { TMenu, TUserConnected } from "../../types";
import useToggle from "@/hooks/useToggleWithTransition.hooks";
import {
  SideMenuContainer,
  SideMenuHeader,
  SideMenuLinkContainer,
} from "./SideMenu.styles";
import { Badge, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useAppDispatch } from "@/store";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { selectUserConnected, selectToken, selectMessageNotif, selectFavoriteNotif, selectExchangeNotif } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { styled } from '@mui/material/styles';
import { selectNbreLiveSocket, selectNbreNotificationSocket } from "@/store/reducers/general_param/general_param.selector";



const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


type TSideMenuProps = {
  menu: TMenu;
  indexMenuOpen: number | null;
  myIndex: number;
  setIndexMenuOpen: React.Dispatch<React.SetStateAction<number | null>>;
};

type TSideMenuLink = {
  menu: TMenu;
  userConnected: TUserConnected | null;
  nbre_notification_socket: number,
  nbre_live_socket: number,
  nbre_message_notif:number,
  nbre_favorite_notif: number,
  nbre_exchange_notif: number,
  isActive: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const SideMenuLink: React.FC<TSideMenuLink> = ({
  menu,
  isActive,
  onClick,
  userConnected,
  nbre_notification_socket,
  nbre_message_notif,
  nbre_favorite_notif,
  nbre_exchange_notif,
  nbre_live_socket, }) => {

  const [active, setActive] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    menu && (
      <SideMenuLinkContainer
        down_lg={matches.toString()}
        active={active.toString()}
        to={menu.route}
        onClick={onClick}
        className={
          (
            ((typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string') ? true : (!(userConnected as TUserConnected).account.is_free && (!(userConnected as TUserConnected).account.is_active || !(userConnected as TUserConnected).account.is_subscription_active)))
            &&
            (menu.label === 'Accueil' || menu.label === 'Favoris' || menu.label === 'Échanges' || menu.label === 'Rechercher' || menu.label === 'Ma liste noire' || menu.label === 'Gestion des lives' || menu.label === 'Imam & Intervenants' || menu.label === 'As tu une question ?')
          )
            ? "not_available" : ""
        }
      >
        <>
          <InlineIcon
            icon={menu.icon}
          />

          {menu.label}

          {
            (menu.label === 'Favoris') &&
            <>
              <StyledBadge
                overlap="circular"
                variant="dot"
                sx={{ marginLeft: '20px' }}
                color={active ? "primary" : "warning"}
                badgeContent={
                  nbre_favorite_notif
                } />
            </>
          }
          {
            (menu.label === 'Notifications') &&
            <>
              <StyledBadge
                overlap="circular"
                variant="dot"
                sx={{ marginLeft: '20px' }}
                color={active ? "primary" : "warning"}
                badgeContent={
                  nbre_notification_socket
                } />
            </>
          }
          {
            (menu.label === 'Échanges') &&
            <>
              <StyledBadge
                overlap="circular"
                variant="dot"
                sx={{ marginLeft: '20px' }}
                color={active ? "primary" : "warning"}
                badgeContent={
                  nbre_message_notif + nbre_exchange_notif // <---
                } />
            </>
          }
          {
            (menu.label === 'Gestion des lives') &&
            <>
              <StyledBadge
                overlap="circular"
                variant="dot"
                sx={{ marginLeft: '20px' }}
                color={active ? "primary" : "warning"}
                badgeContent={
                  nbre_live_socket
                } />
            </>
          }
        </>
      </SideMenuLinkContainer>
    )
  );
};


type PropsFromRedux = ConnectedProps<typeof connector>;

const SideMenuFct: React.FC<TSideMenuProps & PropsFromRedux> = ({
  menu,
  indexMenuOpen,
  myIndex,
  setIndexMenuOpen,
  userConnected,
  nbre_notification_socket,
  nbre_live_socket,
  nbre_message_notif,
  nbre_favorite_notif,
  nbre_exchange_notif,
}) => {

  let route = "none";
  const dispatch = useAppDispatch();

  if (typeof menu.route === "string") {
    route = menu.route;
  }

  const sideMenuContainerRef = useRef<HTMLDivElement>(null);
  const resolved = useResolvedPath(route);
  const match = useMatch({ path: resolved.pathname, end: !!menu.exact });

  const [isMatch, setIsMatch] = useState<boolean>(false);

  useEffect(() => {
    let flag = window.location.pathname.indexOf(menu.route as string);
    setIsMatch(flag !== -1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);


  const { setToggle, toggle, state, STATES, timeout, setState } = useToggle();

  useEffect(() => {
    if (!!match) {
      setIndexMenuOpen(myIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myIndex === indexMenuOpen && !toggle) {
      setToggle(true);
    } else if (myIndex !== indexMenuOpen && toggle) {
      setToggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myIndex, indexMenuOpen]);

  useEffect(() => {
    if (state === STATES.CLOSING && sideMenuContainerRef.current) {
      sideMenuContainerRef.current.style.height = "";
      const timer = setTimeout(() => {
        setState(STATES.CLOSE);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    } else if (state === STATES.OPENING && sideMenuContainerRef.current) {
      // At same time
      sideMenuContainerRef.current.style.height = "unset"; //1 => remove height,
      const height =
        sideMenuContainerRef.current.getBoundingClientRect().height; //2 => get the height before DOM recalculate (repaint),
      sideMenuContainerRef.current.style.height = ""; // 3 => remove height property for easily create animation after DOM recalculate

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      document.body.offsetHeight; // Force DOM recalculate

      sideMenuContainerRef.current.style.height = height + "px";

      const timer = setTimeout(() => {
        setState(STATES.OPEN);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, timeout]);

  if (menu.exact === true) {
    return (
      <SideMenuLink
        userConnected={userConnected}
        menu={menu}
        nbre_notification_socket={nbre_notification_socket}
        nbre_live_socket={nbre_live_socket}
        nbre_message_notif={nbre_message_notif}
        nbre_favorite_notif = {nbre_favorite_notif}
        nbre_exchange_notif = {nbre_exchange_notif}
        //isActive={!!match}
        isActive={isMatch}
        onClick={() => {
          setIndexMenuOpen(null);
          dispatch(toggleSidebar());
        }}
      />
    );
  }

  return (
    <SideMenuContainer
      duration={timeout}
      open={state !== STATES.CLOSE || !!match}
      ref={sideMenuContainerRef}
      className={
        (
          !(userConnected as TUserConnected).account.is_approved 
          &&
          !(userConnected as TUserConnected).account.is_active 
          && 
          (menu.label === 'Mon compte')
        )
          ? "not_display" : ""
      }
    >
      <SideMenuHeader
        onClick={() =>
          setIndexMenuOpen(myIndex !== indexMenuOpen ? myIndex : null)
        }
      >
        {menu.label}{""}
        <InlineIcon
          icon={arrowDown}
          rotate={!toggle && state === STATES.CLOSE ? 3 : 0}
        />
      </SideMenuHeader>
      {menu.children && (
        <ul>
          {menu.children.map((submenu, idx) => (
            <li key={`submenu-${idx}`} onClick={() => { dispatch(toggleSidebar()); }} >
              <NavLink to={`${menu.route}${submenu.route}`}>
                {submenu.label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </SideMenuContainer>
  );
};


const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  nbre_notification_socket: selectNbreNotificationSocket,
  nbre_live_socket: selectNbreLiveSocket,
  nbre_message_notif:selectMessageNotif,
  nbre_favorite_notif: selectFavoriteNotif,
  nbre_exchange_notif: selectExchangeNotif,
});


const connector = connect(mapStateToProps);
const SideMenu = connector(SideMenuFct);

export default SideMenu;
