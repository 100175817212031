import React, { useEffect, useRef, useState } from "react";
import { InlineIcon } from "@iconify/react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import "./Fiche.styles.scss";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import * as yup from "yup";
import { useFormik } from "formik";
import { TCountry, TFiche, TUserConnected } from "@/features/profile/types";
import { apiSaveFiche } from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  selectToken,
  selectUserConnected,
} from "@/store/reducers/member/member.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useAppDispatch } from "@/store";
import { getUserConnected } from "@/store/reducers/member/member.actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";

type PropsFromRedux = ConnectedProps<typeof connector>;

const FicheFct: React.FC<PropsFromRedux> = ({ userConnected, token }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [country, setCountry] = useState<TCountry>({
    country: (userConnected as TUserConnected).contacts?.country || "",
    abbreviation:
      (userConnected as TUserConnected).contacts?.country_code || "",
  });
  const [countries, setCountries] = React.useState<
    Array<{ country: string; abbreviation: string }>
  >([]);

  const dispatch = useAppDispatch();

  const handleCountryChange = (event: any, value: TCountry | null) => {
    console.log(value);
    if (value == null) {
      setCountry({ country: "", abbreviation: "" });
      formik.setFieldValue("country", "");
    } else {
      setCountry(value as TCountry);
    }
  };

  useEffect(() => {
    const countries_json_data = require("@/assets/json/countries.json");
    setCountries(countries_json_data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryInputref = useRef(null);

  const validationSchema = yup.object({
    username: yup
      .string()
      .test({
        name: "not-email",
        message: "le pseudo n'est pas un email",
        test: function (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return !emailRegex.test(value as string);
        },
      })
      .required(" le pseudo est obligatoire "),
    age: yup
      .number()
      .required(" l'âge est obligatoire ")
      .min(18, " l'âge minimum est de 18 ans"),
    city: yup.string().required(" la ville est obligatoire "),
    country: yup.string().when("origine", (val, schema) => {
      let country_val =
        (countryInputref as React.MutableRefObject<any>).current.value || "";
      if (country_val === "") {
        return yup.string().required(" le pays est obligatoire ");
      } else if (getAbbreviation(country_val) === "") {
        setCountry({
          country: country_val,
          abbreviation: getAbbreviation(country_val),
        });
        return yup.string().test({
          name: "incorrect_country",
          message: " Ce pays n'est pas valide ",
          test: function (value) {
            let result = true;
            if (getAbbreviation(country_val) === "") {
              result = false;
            }
            return result;
          },
        });
      } else {
        setCountry({
          country: country_val,
          abbreviation: getAbbreviation(country_val),
        });
        return yup.string().notRequired();
      }
    }),
  });

  const formik = useFormik({
    initialValues: {
      username: (userConnected as TUserConnected).infos.username || "",
      age: (userConnected as TUserConnected).infos.age || "",
      city: (userConnected as TUserConnected).contacts?.city || "",
      country: (userConnected as TUserConnected).contacts?.country || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dataToSend = {
        ...values,
        country: country.country,
        country_code: country.abbreviation,
      };
      saveFiche(dataToSend as TFiche);
    },
  });

  const saveFiche = (data: TFiche) => {
    setLoading(true);
    apiSaveFiche(data, token)
      .then((response: any) => {
        setLoading(false);
        console.log(response);

        let userConnected = response.data; // comment
        dispatch(getUserConnected(userConnected as TUserConnected));

        navigate(routePaths.profileChildren.myAccountChildren.formAbsolute, {
          replace: false,
        });
        Toastify(
          `Votre fiche a été enregistrée avec succès .`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const goTo = (routes: string) => {
    navigate(routes, { replace: true });
  };

  const getAbbreviation = (selected_country: string): string => {
    let result = "";

    result =
      countries.find((x) => x.country === selected_country)?.abbreviation || "";

    return result;
  };

  return (
    <div className="ct-fiche">
      <div className="ct-fiche__nav-part">
        <Button
          color="primary"
          className="ct-fiche__nav-part__btn-nav"
          onClick={() => {
            goTo(routePaths.profileChildren.myAccountChildren.formAbsolute);
          }}
          startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
        >
          Accédez à votre profil
        </Button>
      </div>
      <PerfectScrollbar className="ct-fiche__body-part">
        <div className="ct-fiche__body-part__card-part">
          <h4>
            {" "}
            <b>MA FICHE D'INFORMATION</b>{" "}
          </h4>
          <img
            src={require("@/assets/images/png/avatar-" +
              (userConnected as TUserConnected).infos.gender +
              ".png")}
            alt="sidebar-logo"
            className="ct-fiche__card-part__avatar"
          />
          <form className="form-part" onSubmit={formik.handleSubmit}>
            <Box className="upper-box">
              <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} sm={12} lg={6} justifyContent="center">
                  <FormControl
                    fullWidth
                    color="primary"
                    className="gender-radio-row"
                  >
                    <FormLabel id="id-civilité">Civilité **</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="id-civilité"
                      name="row-radio-buttons-group"
                      className="row-radio-buttons-group"
                      value={
                        (userConnected as TUserConnected).infos.gender || "male"
                      }
                    >
                      <FormControlLabel
                        value="male"
                        disabled
                        control={<Radio />}
                        label="Mr"
                      />
                      <FormControlLabel
                        value="female"
                        disabled
                        control={<Radio />}
                        label="Mme"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Pseudo"
                    variant="outlined"
                    label="Pseudo"
                    name="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                    type="text"
                    // disabled={
                    //   (userConnected as TUserConnected).infos.username !== ""
                    // }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Prénom"
                    variant="outlined"
                    label="Prénom **"
                    value={
                      (userConnected as TUserConnected).infos.firstname || ""
                    }
                    disabled
                    type="text"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Nom"
                    variant="outlined"
                    label="Nom **"
                    value={
                      (userConnected as TUserConnected).infos.lastname || ""
                    }
                    disabled
                    type="text"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <TextField
                    placeholder="Votre adresse email"
                    variant="outlined"
                    label="Email **"
                    value={
                      (userConnected as TUserConnected).credentials?.email || ""
                    }
                    disabled
                    type="text"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Votre âge"
                    variant="outlined"
                    label="Âge"
                    name="age"
                    onChange={formik.handleChange}
                    value={formik.values.age}
                    error={formik.touched.age && Boolean(formik.errors.age)}
                    helperText={formik.touched.age && formik.errors.age}
                    type="number"
                    // disabled={
                    //   (userConnected as TUserConnected).infos.age.toString() !==
                    //   ""
                    // }
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Votre numéro de téléphone"
                    variant="outlined"
                    label="Numéro de téléphone **"
                    value={
                      (userConnected as TUserConnected).contacts
                        ?.phone_number || ""
                    }
                    disabled
                    type="text"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    placeholder="Votre ville"
                    variant="outlined"
                    label="Ville"
                    name="city"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    type="text"
                    // disabled={
                    //   (userConnected as TUserConnected).contacts?.city !== null
                    // }
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={countries}
                    value={country}
                    onChange={handleCountryChange}
                    autoHighlight
                    getOptionLabel={(option) => option.country}
                    // disabled={
                    //   (userConnected as TUserConnected).contacts?.country !==
                    //     null &&
                    //   (userConnected as TUserConnected).contacts
                    //     ?.country_code !== null
                    // }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <InlineIcon
                          style={{ borderRadius: "50%" }}
                          icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                        />{" "}
                        &nbsp;
                        {option.country}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <InlineIcon
                          className="flag"
                          icon={`flag:${country.abbreviation.toLowerCase()}-1x1`}
                        />
                        <TextField
                          {...params}
                          placeholder="Votre Pays"
                          variant="outlined"
                          label="Pays"
                          name="country"
                          inputRef={countryInputref}
                          onChange={formik.handleChange}
                          value={formik.values.country}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country
                          }
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center" }}>
                  <Typography variant="caption" color="primary">
                    ** INFORMATION <b>UNIQUEMENT</b> VISIBLE PAR
                    L’ADMINISTRATION DE ZAWAJ SUNNAH
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* {((userConnected as TUserConnected).contacts?.country === null ||
              (userConnected as TUserConnected).contacts?.country_code ===
                null ||
              (userConnected as TUserConnected).infos.username === "" ||
              (userConnected as TUserConnected).contacts?.city === null ||
              (userConnected as TUserConnected).infos.age.toString() ===
                "")  && ( */}
            <LoadingButton
              loading={loading}
              loadingPosition="center"
              variant="contained"
              color="tertiary"
              sx={{ marginTop: "30px" }}
              type="submit"
            >
              ENREGISTRER
            </LoadingButton>
            {/* )} */}
          </form>
        </div>
      </PerfectScrollbar>

      {(userConnected as TUserConnected).account.changes_requests_rejected && (
        <CModalRejectedModif
          open={
            (userConnected as TUserConnected).account.changes_requests_rejected
          }
        />
      )}

      <CModalRemindPay
        fromAccount={true}
        open={
          !(userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_approved
        }
        type={
          (userConnected as TUserConnected).account.is_subscription_expired
            ? "reabonnement"
            : "abonnement"
        }
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const Fiche = connector(FicheFct);

export default Fiche;
