import React from 'react';
import { routePaths } from "@/config";
import { selectToken } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";

type PropsFromRedux = ConnectedProps<typeof connector>;
const ProtectedRouteFct: React.FC<PropsFromRedux & { children: React.ReactNode }> = ({ children, token }) => {

    return <>
        {
            (token.length === 0) ?
                children
                :
                <Navigate to={routePaths.profile} />
        }
    </>;
};


const mapStateToProps = createStructuredSelector({
    token: selectToken
});
const connector = connect(mapStateToProps);
const ProtectedRoute = connector(ProtectedRouteFct);

export default ProtectedRoute;