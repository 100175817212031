import React, { useState } from "react";
import { menus1, menus2 } from "@/config/sidebarMenus";
import SideMenu from "../../Elements/SideMenu/SideMenu.element";
import PerfectScrollbar from "react-perfect-scrollbar";
import { InlineIcon } from "@iconify/react";
import {
  RemoveCircleOutline,
  YouTube,
  Send,
  FacebookRounded,
  Twitter,
  Instagram,
  PowerSettingsNewRounded,
  HelpOutlineRounded,
  ArrowBackRounded
} from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, useMediaQuery } from "@mui/material";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { selectOpened } from "@/store/reducers/sidebar/sidebar.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import classNames from "classnames";
import { persistor, store, useAppDispatch } from "@/store";
import { useTheme } from '@mui/material/styles';
import "./SideBar.styles.scss";
import { routePaths } from "@/config";
import { useNavigate } from "react-router-dom";
import CModal from "@/components/Modal/Modal";
import { selectToken, selectUserConnected } from "@/store/reducers/member/member.selector";
import { TUserConnected } from "../../types";
import { getToken, updateIsOnline } from "@/store/reducers/member/member.actions";
import { apiToggleDisponibilite } from "../../api/general.api";
import { Toastify } from "@/utils/toast";
import { selectGeneralLinks } from "@/store/reducers/general_param/general_param.selector";



type PropsFromRedux = ConnectedProps<typeof connector>;

const CSideBarFunction: React.FC<PropsFromRedux> = ({
  sidebarOpened,
  userConnected,
  token,
  general_links,
}) => {


  const [indexMenuOpen, setIndexMenuOpen] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [openHelp, setOpenHelp] = useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const [openIndisponibilite, setOpenIndisponibilite] = useState(false);
  const handleClickOpenIndisponibilite = () => setOpenIndisponibilite(true);
  const handleCloseIndisponibilite = () => setOpenIndisponibilite(false);


  const navigate = useNavigate();

  const logout = () => {
    store.dispatch({ type: 'CLEAR_STORE' });
    localStorage.clear();
    navigate(routePaths.auth, { replace: true });
    dispatch(getToken(''));
    persistor.purge();
  }


  const clickToSetIndisponible = () => {
    handleCloseIndisponibilite();
    store.dispatch(updateIsOnline(false));

    apiToggleDisponibilite(token).then((response: any) => {

      console.log(response);
      Toastify(`Profil indisponible`, 'success', 2000);

    }).catch((error: any) => {
      console.log(error);
      //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
    });

  }


  const clickToSetDisponible = () => {
    if ((userConnected as TUserConnected).account.is_available === false) {
      
      store.dispatch(updateIsOnline(true));

      apiToggleDisponibilite(token).then((response: any) => {

        console.log(response);
        Toastify(`Profil disponible`, 'success', 2000);

      }).catch((error: any) => {
        console.log(error);
        //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
      });

    } else {
      handleClickOpenIndisponibilite();
    }
  }



  return (
    <>
      <Slide direction="right" in={matches || sidebarOpened} mountOnEnter unmountOnExit>
        <div className={classNames("c-sidebar", { "opened": sidebarOpened })}>
          <div className="sidebar-top">
            <div className="sidebar-top__header-btn-container">
              <div className="rounded-square"
                onClick={() => {
                  dispatch(toggleSidebar())
                }}>
                <ArrowBackRounded color="primary" />
              </div>
              <div className="rounded-square"
                onClick={handleOpenHelp}>
                <HelpOutlineRounded color="primary" />
              </div>
              <div className="rounded-square"
                onClick={handleClickOpenLogout}>
                <PowerSettingsNewRounded color="primary" />
              </div>
            </div>
            <img
              src={require("@/assets/images/png/logo.png")}
              alt="sidebar-logo"
              className="sidebar-top__logo"
            />
            <div className="sidebar-top__avatar">
              <img
                src={require("@/assets/images/png/avatar-" + (userConnected as TUserConnected).infos.gender + ".png")}
                alt="sidebar-logo"
              />
              <div className={classNames("sidebar-top__avatar__badge", { 'error': !(userConnected as TUserConnected).account.is_available })}></div>
            </div>

            <p className="sidebar-top__username"> {(userConnected as TUserConnected).infos.firstname}&nbsp;{(userConnected as TUserConnected).infos.lastname} </p>
            <p className="sidebar-top__identity_number">ID: {(userConnected as TUserConnected)?.member_id} </p>
            <div className="sidebar-top__other-infos">
              <div className="sidebar-top__other-infos__infos-bloc">
                <InlineIcon icon="bx:calendar" />&nbsp;
                <span> {(userConnected as TUserConnected).infos?.age} ans </span>
              </div>
              <div className="sidebar-top__other-infos__infos-bloc">
                <InlineIcon icon="akar-icons:location" />&nbsp;
                <span> {(userConnected as TUserConnected).contacts?.city || '--'} </span>
              </div>
            </div>
            <div className="sidebar-top__btn-container">
              <Button
                variant="contained"
                color={(userConnected as TUserConnected).account.is_available ? "success" : "error"}
                className="sidebar-top__btn-container__available-btn"
                startIcon={<RemoveCircleOutline />}
                onClick={clickToSetDisponible}
              >
                PROFIL {(userConnected as TUserConnected).account.is_available ? 'DISPONIBLE' : 'INDISPONIBLE'}
              </Button>
            </div>
          </div>
          <PerfectScrollbar className="sidebar-menu">
            {menus1.map((menu, idx) => (
              <SideMenu
                menu={menu}
                key={idx}
                myIndex={idx}
                indexMenuOpen={indexMenuOpen}
                setIndexMenuOpen={setIndexMenuOpen}
              />
            ))}
            <hr />
            {menus2.map((menu, idx) => (
              <SideMenu
                menu={menu}
                key={idx}
                myIndex={idx}
                indexMenuOpen={indexMenuOpen}
                setIndexMenuOpen={setIndexMenuOpen}
              />
            ))}
            <div className="sidebar-menu__social-part">
              <h1 className="title">Suivez-nous sur les réseaux</h1>
              <div className="bloc-container">
                <a
                  className="rounded-square"
                  href={general_links.youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTube color="primary" />
                </a>
                <a
                  className="rounded-square"
                  href={general_links.telegram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Send color="primary" />
                </a>
                <a
                  className="rounded-square"
                  href={general_links.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookRounded color="primary" />
                </a>
                <a
                  className="rounded-square"
                  href={general_links.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter color="primary" />
                </a>
                <a
                  className="rounded-square"
                  href={general_links.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram color="primary" />
                </a>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </Slide>
      <CModal open={openHelp} type="aide" onClose={handleCloseHelp} closeClick={handleCloseHelp} />
      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Déconnexion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogout}>Annuler</Button>
          <Button onClick={logout} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openIndisponibilite}
        onClose={handleCloseIndisponibilite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Indisponibilite"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Une fois le profil indisponible, personne ne peut vous envoyer la demande d'échange.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseIndisponibilite}>Annuler</Button>
          <Button onClick={clickToSetIndisponible} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
  userConnected: selectUserConnected,
  token: selectToken,
  general_links: selectGeneralLinks,
});


const connector = connect(mapStateToProps);
const CSideBar = connector(CSideBarFunction);

export default CSideBar;
