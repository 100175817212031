import { useEffect, useState } from "react";

enum STATES {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  OPENING = "OPENING",
  CLOSING = "CLOSING",
}

const useToggleWithTransition = (
  props = { timeout: 350, onClose: () => {} }
) => {
  const { timeout = 350, onClose = () => {} } = props;
  const [toggle, setToggle] = useState<boolean>(false);
  const [state, setState] = useState<STATES>(STATES.CLOSE);

  useEffect(() => {
    if (!toggle && state === STATES.OPEN) {
      setState(STATES.CLOSING);
    } else if (toggle && state === STATES.CLOSE) {
      setState(STATES.OPENING);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  useEffect(() => {
    if (state === STATES.CLOSING) {
      const timer = setTimeout(() => {
        setState(STATES.CLOSE);
        onClose();
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    } else if (state === STATES.OPENING) {
      const timer = setTimeout(() => {
        setState(STATES.OPEN);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    toggle,
    setToggle,
    state,
    STATES,
    timeout,
    setState,
  };
};

export default useToggleWithTransition;
