import { TMenu } from "@/features/profile/types";
import { routePaths } from "./routePaths";


export const menus1: TMenu[] = [
  {
    icon: "bx:home",
    label: "Accueil",
    route: routePaths.profileChildren.homeAbsolute,
    exact: true,
  },
  {
    icon: "bx:star",
    label: "Favoris",
    route: routePaths.profileChildren.favoriteAbsolute,
    exact: true, 
  },
  {
    icon: "bx:message-rounded-detail",
    label: "Échanges",
    route: routePaths.profileChildren.exchangeAbsolute,
    exact: true,
  },
  {
    icon: "bx:search-alt",
    label: "Rechercher",
    route: routePaths.profileChildren.searchAbsolute,
    exact: true,
  },
  {
    icon: "bx:user",
    label: "Votre profil",
    route: routePaths.profileChildren.myAccountAbsolute,
    exact: true,
  },
  {
    icon: "bx:bell",
    label: "Notifications",
    route: routePaths.profileChildren.notificationAbsolute,
    exact: true,
  },
];

export const menus2: TMenu[] = [
  {
    label: "Fonctionnement du site",
    route: routePaths.profileChildren.fonctionnementAbsolute,
    exact: true,
  },
  {
    label: "Mon engagement",
    route: routePaths.profileChildren.engagementAbsolute,
    exact: true,
  },
  {
    label: "As tu une question ?",
    route: routePaths.profileChildren.questionsAbsolute,
    exact: true,
  },
  {
    label: "Le staff",
    route: routePaths.profileChildren.staffAbsolute,
    exact: true,
  },
  {
    label: "Imam & Intervenants",
    route: routePaths.profileChildren.imamSpeakersAbsolute,
    exact: true,
  },
  {
    label: "Ma liste noire",
    route: routePaths.profileChildren.blacklistAbsolute,
    exact: true,
  },
  {
    label: "Gestion des lives",
    route: routePaths.profileChildren.liveAbsolute,
    exact: true,
  },
  {
    label: "Mon compte",
    route: routePaths.profileChildren.subscriptionAbsolute,
    children: [
      {
        label: "Supprimer mon compte",
        route: routePaths.profileChildren.subscriptionChildren.deleteAccount,
      },
      {
        label: "Mon abonnement",
        route: routePaths.profileChildren.subscriptionChildren.desactivateSubscription,
      },
    ],
  },
  
];
