import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";



export const  apiGetInterfaceSettings = ( access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/interface-settings');
}


export const  apiToggleDisponibilite= ( access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/members/account/availability-status');
}


