import { InlineIcon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Button, Collapse, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useState } from 'react';
import "./Questions.styles.scss";
import { selectToken, selectUserConnected } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { apiGetQuestion } from '../../api/questions.api';
import { Toastify } from '@/utils/toast';
import CModalRemindPay from '@/components/Modal-remind-pay/ModalRemindPay';
import { TUserConnected } from '../../types';
import { selectGeneralLinks } from '@/store/reducers/general_param/general_param.selector';


type PropsFromRedux = ConnectedProps<typeof connector>;

const CtQuestionsFct: React.FC<PropsFromRedux> = ({
  token,
  userConnected,
  general_links
}) => {

  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const [faq, setFaq] = useState<{ question: string, reponse: string }[]>([]);


  useEffect(() => {

                    (
          (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin ==='string') ? false :
          (
            (userConnected as TUserConnected).account.is_free ||
            (
              (userConnected as TUserConnected).account.is_active &&
              (userConnected as TUserConnected).account.is_subscription_active
            )
          )
        ) &&
      setLoading(true);

                    (
          (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin ==='string') ? false :
          (
            (userConnected as TUserConnected).account.is_free ||
            (
              (userConnected as TUserConnected).account.is_active &&
              (userConnected as TUserConnected).account.is_subscription_active
            )
          )
        ) &&
      apiGetQuestion(token).then((response: any) => {
        setLoading(false);
        console.log(response);

        let tempList = response.data.map((data: any) => {
          return {
            question: data.title,
            reponse: data.description
          }
        })

        console.log([...tempList]);
        setFaq([...tempList]);

      }).catch((error) => {
        setLoading(false);
        Toastify(`${error?.response?.data?.message}`, 'error', 5000);
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <>
      {
                        (
          (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin ==='string') ? false :
          (
            (userConnected as TUserConnected).account.is_free ||
            (
              (userConnected as TUserConnected).account.is_active &&
              (userConnected as TUserConnected).account.is_subscription_active
            )
          )
        ) &&
        <div className='ct-questions'>
          <Collapse in={!openAlert}>
            <div className="ct-questions__nav-part">
              <Button variant="contained"
                color="primary"
                className="ct-questions__nav-part__btn-nav"
                onClick={handleOpenAlert}
                startIcon={<InlineIcon icon='tabler:brand-telegram' />}>
                Canal privé
              </Button>
            </div>
          </Collapse>
          <Collapse in={openAlert}>
            <Alert severity="info"
              className="ct-questions__alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleCloseAlert}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }>
              <AlertTitle> <b>Canal privé </b> </AlertTitle>
              <div className="join-part">
                <p>
                  Rejoignez le canal Telegram privé de
                  &nbsp;<strong>Zawaj Sounnah</strong> afin d'avoir toutes les
                  informations liées à la plateforme en
                  <strong> temps réel ! </strong>
                </p>

                <Button variant="contained"
                  color="primary"
                  sx={{width: 'fit-content'}}
                  href={general_links.telegram}
                  target="_blank"
                  startIcon={<InlineIcon icon='tabler:brand-telegram' />}>
                  Rejoindre
                </Button>
              </div>
            </Alert>
          </Collapse>

          <PerfectScrollbar className="ct-questions__body-part">
            {
              loading ?
                <img src={require("@/assets/images/gif/loadmore.gif")}
                  height="70"
                  width="70"
                  className="load-more"
                  alt="load-more" />
                :
                faq.map((value, index) => {

                  return <Accordion color='primary' key={index} >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography> {value.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {value.reponse}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                })
            }

          </PerfectScrollbar>
        </div>
      }
      <CModalRemindPay
        open={ (typeof (userConnected as TUserConnected).account.reason_of_desactivation_by_admin === 'string') ? true : (!(userConnected as TUserConnected).account.is_free && (!(userConnected as TUserConnected).account.is_active || !(userConnected as TUserConnected).account.is_subscription_active)) }
        type={ (userConnected as TUserConnected).account.is_subscription_expired ? "reabonnement" : "abonnement" } />
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  token: selectToken,
  userConnected: selectUserConnected,
  general_links: selectGeneralLinks,
});


const connector = connect(mapStateToProps);
const CtQuestions = connector(CtQuestionsFct);

export default CtQuestions