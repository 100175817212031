import React from "react";
import texteArabe from "@/assets/images/png/texte-arabe.png";
import "./Engagement.styles.scss";
import * as moment from "moment";
import "moment/locale/fr";
import { Alert, Checkbox } from "@mui/material";
import TextCGU from "@/components/Text-CGU/TextCGU";
import { selectUserConnected } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "../../types";

type PropsFromRedux = ConnectedProps<typeof connector>;
const CtEngagementFct: React.FC<PropsFromRedux> = ({ userConnected }) => {
  return (
    <div className="ct-engagement">
      <div className="terms-and-conditions-container">
        <div className="header-part">
          <h1 className="title">
            TU DOIS REGARDER LA VIDÉO <br />
            LIRE ET SIGNER TON CONTRAT D'ENGAGEMENT AINSI QUE LES CONDITIONS
            D'UTILISATIONS.
          </h1>
        </div>
        <form className="form-part">
          <div className="media-container">
            <iframe
              src="https://player.vimeo.com/video/712936730?h=8e57687621"
              title="video-of-terms-and-condition"
              width="100%"
              height="500"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />

            <img src={texteArabe} alt="texte arabe" />
          </div>
          <TextCGU />
          <h1 className="title"> CONTRAT D'ENGAGEMENT </h1>
          <div className="checkbox-item">
            <Checkbox checked />
            <p className="important">
              {" "}
              Je prends Allah 'Azawajel à témoin et...{" "}
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              J'atteste que je suis de la religion musulmane et ne suis{" "}
              <b> partisant(e) d’aucune secte. </b>{" "}
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              J'atteste que <b>je suis une personne majeure.</b>
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              Je m'engage à <b>ne pas mentir</b> concernant les informations
              relatives à mon profil.
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              Je m'engage à ne jamais rentrer en contact avec un membre de
              manière isolée en dehors du site.
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              Je m'engage à dénoncer tout frère ou sœur ayant un comportement
              suspect à Zawaj Sounnah.
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              {" "}
              Je m'engage à{" "}
              <b>
                ne pas demander où ne partager aucune photo en dehors du site
              </b>{" "}
              je ne ferais que des MOUQABALA.<b>(Physique ou VISIO)</b>.{" "}
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              <b>Je certifie avoir pris connaissance des C.G.U.</b>
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>
              Je m’engage à respecter les règles du site <b>ZAWAJ SOUNNAH</b>
            </p>
          </div>
          <div className="checkbox-item">
            <Checkbox checked />
            <p>Je certifie avoir regardé la vidéo ci-dessus</p>
          </div>
        </form>
        <Alert severity="success" className="alert-part">
          <div className="certified-part">
            <img
              src={require("@/assets/images/png/engager.png")}
              alt="engager"
            />
            <div className="sign-part">
              <span>OUI</span>
              <p>
                {moment
                  .default(
                    (userConnected as TUserConnected).infos.date_of_creation
                  )
                  .format("LLLL")}
              </p>
            </div>
          </div>
        </Alert>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const CtEngagement = connector(CtEngagementFct);

export default CtEngagement;
