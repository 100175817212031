import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";
import { TForgotPassword, TResetPassword, TSignIn, TSignUp } from "../types";


export const  apiLoginMember = ( data:TSignIn ):Promise<AxiosResponse<any, any>> => {
    return axios.post('/members/auth/login',data);
}

export const  apiForgotPasswordMember = ( data:TForgotPassword ):Promise<AxiosResponse<any, any>> => {
    return axios.patch('/members/auth/reset-password-token',data);
}

export const  apiResetPasswordMember = ( data:TResetPassword ):Promise<AxiosResponse<any, any>> => {
    return axios.patch('/members/auth/reset-password',data);
}

export const  apiSignupMember = ( data:TSignUp ):Promise<AxiosResponse<any, any>> => {
    return axios.post('/members',data);
}

export const apiGetUserConnected = ( access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/members/account/details');
}

export const apiGetModerationSchedule = ( access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/time-slots/moderation-status');
}

export const apiVerifyEmail = ( email: string ):Promise<AxiosResponse<any, any>> => {
    return axios.get('/members/email/verify?email='+email);
}

export const apiVerifyUsername = ( username: string ):Promise<AxiosResponse<any, any>> => {
    return axios.get('/members/username/verify?username='+username);
}

export const apiVerifyPhone = ( phone_number: string ):Promise<AxiosResponse<any, any>> => {
    return axios.get('/members/phone/verify?phone_number='+phone_number);
}

