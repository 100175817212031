export enum memberTypes {
    GET_USER_CONNECTED = "GET_USER_CONNECTED",
    GET_TOKEN = "GET_TOKEN",
    UPDATE_PROGRESS = "UPDATE_PROGRESS",
    UPDATE_LOCATION = "UPDATE_LOCATION",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    UPDATE_RELIGIOUS_ASPECT = "UPDATE_RELIGIOUS_ASPECT",
    UPDATE_RELIGIOUS_PRACTICES = "UPDATE_RELIGIOUS_PRACTICES",
    UPDATE_HEALTH_AND_DISABILITY = "UPDATE_HEALTH_AND_DISABILITY",
    UPDATE_IS_ONLINE = "UPDATE_IS_ONLINE",
    UPDATE_IS_APPROVED = "UPDATE_IS_APPROVED",
    UPDATE_IS_ACTIVE = "UPDATE_IS_ACTIVE",
    UPDATE_IS_FRESHNEW = "UPDATE_IS_FRESHNEW",
    UPDATE_IS_FICHE_FILLED = "UPDATE_IS_FICHE_FILLED",
    UPDATE_IS_SUBSCRIPTION_ACTIVE = "UPDATE_IS_SUBSCRIPTION_ACTIVE",
    UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION",
    UPDATE_MESSAGE_NOTIF = "UPDATE_MESSAGE_NOTIF",
    UPDATE_FAVORITE_NOTIF = "UPDATE_FAVORITE_NOTIF",
    UPDATE_EXCHANGE_NOTIF = "UPDATE_EXCHANGE_NOTIF",
    UPDATE_PENDING_CANCEL_CONVERSATION = "UPDATE_PENDING_CANCEL_CONVERSATION",
    UPDATE_ACCEPT_EXCHANGE = "UPDATE_ACCEPT_EXCHANGE",
    UPDATE_NEW_MODIFICATION_SUBMIT = "UPDATE_NEW_MODIFICATION_SUBMIT",
}
  
export default memberTypes;
  