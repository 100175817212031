import React, { useContext, useEffect, useRef, useState } from "react";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./EchangeMessage.styles.scss";
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { InlineIcon } from "@iconify/react";
import ChatMessage, {
  TChatMessageProps,
} from "@/components/ChatMessage/ChatMessage";
import * as moment from "moment";
import "moment/locale/fr";
import { useLayoutEffect } from "react";
import {
  TCurrentExchange,
  TModerationSocket,
  TUserConnected,
  initCurrentExchange,
} from "@/features/profile/types";
import {
  selectCurrentExchange,
  selectIsModerationSuspend,
  selectMaxCaracter,
  selectMaxMessage,
  selectModerationSocket,
  selectNbreMessageReceive,
  selectNbreMessageSent,
} from "@/store/reducers/general_param/general_param.selector";
import {
  apiGetConversation,
  markMessageAsRead,
  apiSendMessage,
} from "@/features/profile/api/exchange.api";
import { Toastify } from "@/utils/toast";
import { store } from "@/store";
import {
  updateNbreMessageReceive,
  updateNbreMessageSent,
} from "@/store/reducers/general_param/general_param.actions";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import { updateMessageNotif } from "@/store/reducers/member/member.actions";
import classNames from "classnames";

type TEchangeMessage = {
  exchangeId: string;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const EchangeMessageFct: React.FC<TEchangeMessage & PropsFromRedux> = ({
  userConnected,
  token,
  currentExchange,
  max_message,
  max_caractere,
  nbre_message_sent,
  nbre_message_receive,
  exchangeId,
  moderation_socket,
  is_moderation_suspend,
}) => {
  const [value, setValue] = useState("");
  const [discussion, setDiscussion] = useState<TChatMessageProps[] | []>([]);

  const [loading, setLoading] = useState(false);

  const scrollRef = useRef<any>();

  const { socketIO } = useContext(AppSocketContext);

  useLayoutEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const newMessage = () => {
    if (value !== "" && nbre_message_sent < max_message) {
      let tempValue = value;
      setValue("");
      store.dispatch(updateNbreMessageSent(nbre_message_sent + 1));

      apiSendMessage(value, token)
        .then((response: any) => {
          setDiscussion([
            ...discussion,
            {
              message: tempValue,
              reason:
                "Vous devez être courtois, ce ne sont pas des paroles à avoir",
              fromStaff: false,
              pending: true,
              isValid: false,
              type: "sent",
              gender: (userConnected as TUserConnected).infos.gender,
              time:
                moment.default(new Date()).format("LL") +
                " " +
                moment.default(new Date()).format("LT"),
            },
          ]);
        })
        .catch((error) => {
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
          window.location.reload();
        })
        .finally(() => {
          setValue("");
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        });
    }

    if (nbre_message_sent >= max_message) {
      Toastify(
        `Vous avez dépassé le quota de message journalier`,
        "warning",
        5000
      );
      setValue("");
    }
  };

  const testSender = (): boolean => {
    return (
      (userConnected as TUserConnected).infos.username ===
      (currentExchange as TCurrentExchange).sender.username
    );
  };

  const loadConversation = () => {
    setLoading(true);

    apiGetConversation(exchangeId, token)
      .then((response: any) => {
        setLoading(false);
        setDiscussion(
          [...response.messages].map((elt) => {
            return {
              ...elt,
              time:
                moment.default(new Date(elt.time)).format("LL") +
                " " +
                moment.default(new Date(elt.time)).format("LT"),
            };
          })
        );

        markMessageAsRead(exchangeId, token)

        const message_length_today_receiver =
          response.receiver.message_length_today;
        const message_length_today_sender =
          response.sender.message_length_today;

        if (
          (userConnected as TUserConnected).member_id ===
          response.receiver.profile_id
        ) {
          store.dispatch(updateNbreMessageReceive(message_length_today_sender));
          store.dispatch(updateNbreMessageSent(message_length_today_receiver));
        }

        if (
          (userConnected as TUserConnected).member_id ===
          response.sender.profile_id
        ) {
          store.dispatch(
            updateNbreMessageReceive(message_length_today_receiver)
          );
          store.dispatch(updateNbreMessageSent(message_length_today_sender));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("ERROR MESSAGE", error);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  useEffect(() => {
    store.dispatch(updateMessageNotif(0)); // <---

    let messageArray = (currentExchange as TCurrentExchange).messages.map(
      (value: any) => {
        return {
          ...value,
          type: value.type === "received" ? "sent" : "received",
        };
      }
    );
    setDiscussion([...messageArray]);

    loadConversation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //console.log('||| ⭐️',socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.member_conversation_messages, (data) => {
        console.log(data);
        let temp_discussion = discussion as TChatMessageProps[];
        temp_discussion.push({
          message: data.message,
          reason: data.reason,
          fromStaff: data.fromStaff,
          pending: data.pending,
          isValid: data.isValid,
          type: data.type,
          gender: data.gender,
          note: data.note,
          time:
            moment.default(new Date(data.time)).format("LL") +
            " " +
            moment.default(new Date(data.time)).format("LT"),
        });
        setDiscussion([...temp_discussion]);
        loadConversation();

        if (data.type === "received" && data.pending === false) {
          store.dispatch(updateNbreMessageReceive(nbre_message_receive + 1));
        }
      });
    } else {
      console.log("||| ⭐️⭐️", socketIO);
    }

    return () => {
      if (socketIO) {
        socketIO.off(socketChannels.member_conversation_messages);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, discussion]);

  const scrollToBottom = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const cleanCurrentExchange = (): TCurrentExchange => {
    let result: TCurrentExchange = initCurrentExchange;

    if ((currentExchange as TCurrentExchange).receiver === undefined) {
      result = {
        ...(currentExchange as TCurrentExchange),
        receiver: initCurrentExchange.receiver,
      };
    } else if ((currentExchange as TCurrentExchange).sender === undefined) {
      result = {
        ...(currentExchange as TCurrentExchange),
        sender: initCurrentExchange.sender,
      };
    } else {
      result = currentExchange as TCurrentExchange;
    }

    return result;
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      <div className="c-echange-message">
        <div className="top-part">
          <p>
            Tous les messages envoyés sont intégralement lus, contrôlés et
            validés par le service de modération avant d'être transmis à &nbsp;
            <b>
              {!testSender()
                ? cleanCurrentExchange().receiver.username
                : cleanCurrentExchange().sender.username}
            </b>
          </p>
          <CustomTooltip title="Actualiser la conversation">
            <IconButton
              className="icon-header"
              color="primary"
              onClick={loadConversation}
            >
              <InlineIcon icon="material-symbols:sync-rounded" />
            </IconButton>
          </CustomTooltip>
        </div>
        <div
          className={classNames("content-part", {
            unavailable: !(
              (moderation_socket as TModerationSocket).status &&
              !is_moderation_suspend
            ),
          })}
          ref={scrollRef}
        >
          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            <>
              {discussion.length === 0 && (
                <div className="no-text">
                  <p> Commencez à discuter avec votre partenaire </p>
                </div>
              )}
              {discussion.length > 0 && (
                <>
                  {discussion.map((msg, index) => {
                    return (
                      <ChatMessage
                        key={index}
                        message={msg.message}
                        reason={msg.reason}
                        fromStaff={msg.fromStaff}
                        pending={msg.pending}
                        isValid={msg.isValid}
                        type={msg.type}
                        gender={msg.gender}
                        time={msg.time}
                        note={msg.note}
                        isRead={msg.isRead}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
        <div
          className={classNames("bottom-part", {
            unavailable: !(
              (moderation_socket as TModerationSocket).status &&
              !is_moderation_suspend
            ),
          })}
        >
          {(moderation_socket as TModerationSocket).status &&
          !is_moderation_suspend ? (
            <>
              <IconButton
                className="sroll-to-bottom"
                size="small"
                onClick={scrollToBottom}
              >
                <InlineIcon icon="ep:arrow-down" />
              </IconButton>
              <div className="sender-input">
                <textarea
                  className="send-message-input"
                  maxLength={max_caractere}
                  rows={2}
                  name="savants"
                  value={value}
                  onChange={handleChange}
                ></textarea>
                <IconButton className="send-btn" onClick={newMessage}>
                  <InlineIcon icon="fluent:send-28-regular" />
                </IconButton>
              </div>
              <small>
                {" "}
                {value.length} / {max_caractere} caractères
              </small>
            </>
          ) : (
            <>
              <span className="title-unavailable"> SERVICE INDISPONIBLE </span>
              <p className="text-unavailable">
                {" "}
                {(moderation_socket as TModerationSocket).message}{" "}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  currentExchange: selectCurrentExchange,
  max_message: selectMaxMessage,
  nbre_message_sent: selectNbreMessageSent,
  nbre_message_receive: selectNbreMessageReceive,
  max_caractere: selectMaxCaracter,
  moderation_socket: selectModerationSocket,
  is_moderation_suspend: selectIsModerationSuspend,
});

const connector = connect(mapStateToProps);
const EchangeMessage = connector(EchangeMessageFct);

export default EchangeMessage;
