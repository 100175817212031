import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const  apiGetImamSpeakers_old = ( access_token: string, page:number=1, limit: number=6, ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('live/test?page='+page+'&limit='+limit,config);
}


export const  apiGetImamSpeakers = ( access_token: string ):Promise<AxiosResponse<any, any>> => {
    const config = {   
        headers: {
            "Authorization": `${access_token}`
        }
    }
    return axios.get('/imams-speakers',config);
}