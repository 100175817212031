import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const  apiGetNotification = ( access_token: string  ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/notifications?operation=get-notifications-by-owner');
}


export const  apiRemoveNotification = ( notification_id:string, access_token: string ):Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/notifications/'+notification_id);
}

