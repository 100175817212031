import React, { createContext, useContext, useEffect, useState } from "react";
import { InlineIcon } from "@iconify/react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./Favorite.styles.scss";
import ListManager from "@/components/ListManager/ListManager";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "../../types";
import { apiGetFavorite, apiRemoveFromFavorite } from "../../api/favorite.api";
import { Toastify } from "@/utils/toast";
import { TProfileCard } from "../../Elements/ProfileCard/ProfileCard";
import * as _ from "lodash";
import { updateFavoriteNotif } from "@/store/reducers/member/member.actions";
import { store } from "@/store";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

export const FavoriteContext = createContext<{
  removeFromFavoriteFct: (profil_id: string, username: string) => void;
  loadingRemove: boolean;
}>({
  removeFromFavoriteFct: () => {},
  loadingRemove: false,
});

type PropsFromRedux = ConnectedProps<typeof connector>;
const CtFavoriteFct: React.FC<PropsFromRedux> = ({ userConnected, token }) => {
  const theme = useTheme();
  const matches_xl = useMediaQuery(theme.breakpoints.down("xl"));

  const [nbr_mutual_interest, setNbr_mutual_interest] = useState(0);

  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const [listeFavorite, setListeFavorite] = useState<TProfileCard[]>([]);
  const [listeInterestedInMe, setListeInterestedInMe] = useState<
    TProfileCard[]
  >([]);

  const [navState, setNavState] = React.useState<"for_me" | "from_them">(
    "for_me"
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newNavState: "for_me" | "from_them"
  ) => {
    setNavState(newNavState);
  };

  const { socketIO } = useContext(AppSocketContext);

  useEffect(() => {
    store.dispatch(updateFavoriteNotif(0));

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      setLoading(true);
    setLoading2(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetFavorite(token)
        .then((response: any) => {
          setLoading(false);
          setLoading2(false);
          console.log(response);

          let tempList1: TProfileCard[] = response.favorites.items.map(
            (data: any) => {
              return {
                isVerified: data.is_verified,
                username: data.username,
                type:
                  (data.has_visited && "Mes spectateurs") ||
                  (data.is_visited ? "consulted" : "non consultés"),
                progressValue: data.rate_of_completion,
                profil_id: data.profile_id,
                isOnline: data.is_online,
                age: data.age,
                town: data.city,
                country: data.country,
                origine: data.home_country,
                nationalite: data.citizenship,
                hasMore: data.has_more,
                sendStatut: "",
                description: data.description,
                profile_wanted: data.profile_wanted,
                isMutualFavorite: data.is_favorite && data.is_fan,
                isSimpleFavorite: data.is_favorite,
                date_of_creation: data.date_of_creation,
                cloture: null,
                isListeNoire: false,
              };
            }
          );

          let tempList2: TProfileCard[] = response.fans.items.map(
            (data: any) => {
              return {
                isVerified: data.is_verified,
                username: data.username,
                type:
                  (data.has_visited && "Mes spectateurs") ||
                  (data.is_visited ? "consulted" : "non consultés"),
                progressValue: data.rate_of_completion,
                profil_id: data.profile_id,
                isOnline: data.is_online,
                age: data.age,
                town: data.city,
                country: data.country,
                origine: data.home_country,
                nationalite: data.citizenship,
                hasMore: data.has_more,
                sendStatut: "",
                description: data.description,
                profile_wanted: data.profile_wanted,
                isMutualFavorite: data.is_favorite && data.is_fan,
                isSimpleFavorite: data.is_fan,
                date_of_creation: data.date_of_creation,
                cloture: null,
                isListeNoire: false,
              };
            }
          );

          setNbr_mutual_interest(
            _.sumBy(tempList1, (x) => (x.isMutualFavorite === true ? 1 : 0))
          );
          setListeFavorite([...tempList1]);
          setListeInterestedInMe([...tempList2]);

          // store.dispatch(updateListFavoris([...tempList1]));
          // store.dispatch(updateListFans([...tempList2]));
        })
        .catch((error) => {
          setLoading(false);
          setLoading2(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //console.log('||| ⭐️',socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.new_favorite_notif, (data) => {
        console.log("socket favorite interested in me => ", data);
        let temp_listeInterestedInMe = listeInterestedInMe as TProfileCard[];
        true &&
          temp_listeInterestedInMe.push({
            isVerified: data.is_verified,
            username: data.username,
            type:
              (data.has_visited && "Mes spectateurs") ||
              (data.is_visited ? "consulted" : "non consultés"),
            progressValue: data.rate_of_completion,
            profil_id: data.profile_id,
            isOnline: data.is_online,
            age: data.age,
            town: data.city,
            country: data.country,
            origine: data.home_country,
            nationalite: data.citizenship,
            hasMore: data.has_more,
            sendStatut: "",
            description: data.description,
            profile_wanted: data.profile_wanted,
            isMutualFavorite: data.is_favorite && data.is_fan,
            isSimpleFavorite: data.is_fan,
            date_of_creation: data.date_of_creation,
            cloture: null,
            isListeNoire: false,
          });
        true && setListeInterestedInMe([...temp_listeInterestedInMe]);
      });
    }

    return () => {
      if (socketIO) {
        socketIO.off(socketChannels.new_favorite_notif);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, listeInterestedInMe]);

  const removeFromFavoriteFct = (profil_id: string, username: string) => {
    setLoadingRemove(true);
    apiRemoveFromFavorite(profil_id, token)
      .then((response: any) => {
        setLoadingRemove(false);
        console.log(response);

        let tempArr = listeFavorite;
        let removedItem = _.remove(tempArr, (x) => {
          return x.profil_id === profil_id;
        });
        console.log(removedItem);
        setListeFavorite([...tempArr]);

        Toastify(
          `Vous avez retiré ${username} de vos favoris avec succès`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingRemove(false);
      });
  };

  return (
    <FavoriteContext.Provider
      value={{
        removeFromFavoriteFct: removeFromFavoriteFct,
        loadingRemove: loadingRemove,
      }}
    >
      <>
        {(typeof (userConnected as TUserConnected).account
          .reason_of_desactivation_by_admin === "string"
          ? false
          : (userConnected as TUserConnected).account.is_free ||
            ((userConnected as TUserConnected).account.is_active &&
              (userConnected as TUserConnected).account
                .is_subscription_active)) && (
          <div className="ct-favorite">
            <div className="ct-favorite__nav-part">
              <Button
                variant="contained"
                color="tertiary"
                className="ct-favorite__nav-part__btn-nav"
                onClick={handleOpenAlert}
                startIcon={<InlineIcon icon="clarity:warning-line" />}
              >
                Bon à savoir
              </Button>
              <div className="chip-kpi">
                <InlineIcon
                  icon="clarity:favorite-solid"
                  style={{ color: "#f3f31e" }}
                />
                <b> {nbr_mutual_interest} </b>
                <span> intérêt(s) mutuel(s) </span>
              </div>
            </div>
            <div className="ct-favorite__body-part">
              <Collapse in={openAlert}>
                <Alert
                  severity="warning"
                  className="ct-favorite__body-part__alert"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseAlert}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <AlertTitle>Informations utiles</AlertTitle>
                  Mettre en favoris{" "}
                  <strong> n'implique pas une demande de discussion</strong>
                  <br />
                  Celle-ci doit être faite dans la fiche du membre.
                  <br />
                </Alert>
              </Collapse>

              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                  {!matches_xl && (
                    <>
                      <Grid item xs={12} sm={12} lg={12} xl={6}>
                        <ListManager
                          title="Ceux qui m'intéressent"
                          subtitle="Mes favoris"
                          loading={loading}
                          profileList={listeFavorite}
                          alt={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={6}>
                        <ListManager
                          title="Ceux que j'intéresse"
                          subtitle="Leur favoris"
                          loading={loading2}
                          profileList={listeInterestedInMe}
                          alt={true}
                          no_corner_option={true}
                        />
                      </Grid>
                    </>
                  )}
                  {matches_xl && (
                    <>
                      <Grid item xs={12} sm={12} lg={12} xl={8}>
                        <div className="nav-toggle-part">
                          <ToggleButtonGroup
                            color="primary"
                            className="yel-toggle-group"
                            value={navState}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                          >
                            <ToggleButton value="for_me">
                              {" "}
                              Ceux qui m'intéressent ({
                                listeFavorite.length
                              }){" "}
                            </ToggleButton>
                            <ToggleButton value="from_them">
                              Ceux que j'intéresse ({listeInterestedInMe.length}
                              ){" "}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                        {navState === "for_me" && (
                          <ListManager
                            title="Ceux qui m'intéressent"
                            subtitle="Mes favoris"
                            loading={loading}
                            profileList={listeFavorite}
                            alt={true}
                          />
                        )}
                        {navState === "from_them" && (
                          <ListManager
                            title="Ceux que j'intéresse"
                            subtitle="Leur favoris"
                            loading={loading2}
                            profileList={listeInterestedInMe}
                            alt={true}
                            no_corner_option={true}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
        )}
        {(userConnected as TUserConnected).account
          .changes_requests_rejected && (
          <CModalRejectedModif
            open={
              (userConnected as TUserConnected).account
                .changes_requests_rejected
            }
          />
        )}
        <CModalRemindPay
          open={
            typeof (userConnected as TUserConnected).account
              .reason_of_desactivation_by_admin === "string"
              ? true
              : !(userConnected as TUserConnected).account.is_free &&
                (!(userConnected as TUserConnected).account.is_active ||
                  !(userConnected as TUserConnected).account
                    .is_subscription_active)
          }
          type={
            (userConnected as TUserConnected).account.is_subscription_expired
              ? "reabonnement"
              : "abonnement"
          }
        />
      </>
    </FavoriteContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const CtFavorite = connector(CtFavoriteFct);

export default CtFavorite;
