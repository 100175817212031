import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import { InlineIcon } from "@iconify/react";
import { Button, IconButton } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";
import "./Modal.styles.scss";
import { selectUserConnected } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TModerationSocket, TUserConnected } from "@/features/profile/types";
import {
  selectMailFemale,
  selectMailMale,
} from "@/store/reducers/general_param/general_param.selector";

type TModalProps = {
  open: boolean;
  type: "aide" | "moderation";
  moderation_socket?: TModerationSocket;
  is_moderation_suspend?: boolean;
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  closeClick: () => void;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const CModalFct: React.FC<TModalProps & PropsFromRedux> = ({
  open,
  type,
  onClose,
  closeClick,
  moderation_socket,
  is_moderation_suspend,
  userConnected,
  mail_male,
  mail_female,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Grow in={open}>
        <div className="c-modal ">
          <IconButton
            className="close-icon"
            size="large"
            color="inherit"
            onClick={closeClick}
          >
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require("@/assets/images/png/avatar-" +
              (userConnected as TUserConnected).infos.gender +
              ".png")}
            alt="sidebar-logo"
            className="modal-avatar"
          />

          {type === "aide" && (
            <>
              <p>
                As Salam Aleykoum et bienvenue à toi
                <br />
                Si tu as la moindre question, tu peux me
                <br />
                Contacter par <b className="wha">&nbsp;Email&nbsp; </b> en
                m'indiquant ton<b>&nbsp;nom&nbsp; </b>
                <br />
                <b>&nbsp; prénom</b> et <b>identifiant d'utilisateur &nbsp; </b>{" "}
                afin que je
                <br />
                puisse t'orienter de la meilleur façon.
                <br />À bientôt in shâ Llah.
              </p>

              <Button
                className="btn-modal"
                variant="contained"
                color="primary"
                href={`mailto:${
                  (userConnected as TUserConnected).infos.gender === "male"
                    ? mail_male
                    : mail_female
                }`}
                endIcon={<InlineIcon icon="cil:send" />}
              >
                Envoyer un e-mail
              </Button>
            </>
          )}

          {type === "moderation" && (
            <>
              <p style={{ textAlign: "justify" }}>
                {(moderation_socket as TModerationSocket).status &&
                !is_moderation_suspend ? (
                  <>
                    Le service d’intermédiaire est actuellement ouvert n'hésitez
                    cependant pas à faire intervenir les membres de votre
                    familles pour accélérer vos échanges.
                  </>
                ) : (
                  <>
                    Le service d’intermédiaire est actuellement indisponible,
                    l’équipe de modération se repose et ne peut vérifier vos
                    messages, n’hésitez pas à faire intervenir vos mahrams pour
                    ne pas être gêné dans vos discussions.
                  </>
                )}
                <br />
                <br />
                <span>-- Nos horaires --</span>
                <br />
                <br />
                Le service d’intermédiaire est ouvert tous les jours de{" "}
                <b className="wha">
                  &nbsp;{(moderation_socket as TModerationSocket).start_time} à
                  &nbsp;{(moderation_socket as TModerationSocket).end_time}{" "}
                  (Heure de France)
                </b>
                .
                <br />
                <br />
                <b>
                  *Le vendredi (et les jours de fête réligieuse) nous sommes
                  fermés
                </b>
              </p>

              <Button
                className="btn-modal"
                variant="contained"
                color="primary"
                onClick={closeClick}
              >
                OK
              </Button>
            </>
          )}
        </div>
      </Grow>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  mail_male: selectMailMale,
  mail_female: selectMailFemale,
});

const connector = connect(mapStateToProps);
const CModal = connector(CModalFct);

export default CModal;
