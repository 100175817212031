import { InlineIcon } from "@iconify/react";
import {
  Button,
  Chip,
  InputAdornment,
  Link,
  SpeedDial,
  SpeedDialAction,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
//import PerfectScrollbar from "react-perfect-scrollbar";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Home.styles.scss";
import NoData from "@/components/No-data/NoData";
import ProfileCard, {
  TProfileCard,
} from "../../Elements/ProfileCard/ProfileCard";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "../../types";
import { Toastify } from "@/utils/toast";
import { apiGetMember } from "../../api/profile.api";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import {
  selectCurrentExchange,
  selectSearchQuery,
} from "@/store/reducers/general_param/general_param.selector";
import { routePaths } from "@/config";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";
import CModalInfosZawaj from "@/components/Modal-Infos-Zawaj/ModalInfosZawaj";

type TFilteredValue = "consulted" | "non consultés" | "Mes spectateurs" | "";

export type TProfileList = Array<TProfileCard>;

type PropsFromRedux = ConnectedProps<typeof connector>;

const CtHomeFct: React.FC<PropsFromRedux> = ({
  userConnected,
  token,
  currentExchange,
  search_query,
}) => {
  const [page, setPage] = useState(1);

  const [allMemberCount, setAllMemberCount] = useState(0);
  const [scrollInt, setScrollInt] = useState(0);
  const [activeButton, setActiveButton] = useState<TFilteredValue>("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasmore, setHasmore] = useState(true);
  const [itemCardWidth, setItemCardWidth] = useState(330);
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchQueryHome, setSearchQueryHome] = useState("");

  const [searchedText, setSearchedText] = useState("");

  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
    setTimeout(() => {
      switch (activeButton) {
        case "":
          setSearchQueryHome("&username=" + e?.target?.value);
          break;
        case "consulted":
          setSearchQueryHome(
            "&username=" + e?.target?.value + "&is_visited=true"
          );
          break;
        case "non consultés":
          setSearchQueryHome(
            "&username=" + e?.target?.value + "&is_visited=false"
          );
          break;
        case "Mes spectateurs":
          setSearchQueryHome(
            "&username=" + e?.target?.value + "&has_visited=true"
          );
          break;

        default:
          setSearchQueryHome("&username=" + e?.target?.value);
          break;
      }
    }, 1000);
  };

  const handleActiveButton = (val: TFilteredValue) => {
    switch (val) {
      case "":
        if (searchedText === "") {
          setSearchQueryHome("");
        } else {
          setSearchQueryHome("&username=" + searchedText);
        }
        break;
      case "consulted":
        if (searchedText === "") {
          setSearchQueryHome("&is_visited=true");
        } else {
          setSearchQueryHome("&username=" + searchedText + "&is_visited=true");
        }
        break;
      case "non consultés":
        if (searchedText === "") {
          setSearchQueryHome("&is_visited=false");
        } else {
          setSearchQueryHome("&username=" + searchedText + "&is_visited=false");
        }
        break;
      case "Mes spectateurs":
        if (searchedText === "") {
          setSearchQueryHome("&has_visited=true");
        } else {
          setSearchQueryHome("&username=" + searchedText + "&has_visited=true");
        }
        break;

      default:
        if (searchedText === "") {
          setSearchQueryHome("");
        } else {
          setSearchQueryHome("&username=" + searchedText);
        }
        break;
    }
  };

  const actions = [
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Tout" },
    {
      icon: (
        <InlineIcon
          icon="akar-icons:circle-fill"
          className={
            (userConnected as TUserConnected).infos.gender === "male"
              ? "consulted male"
              : "consulted female"
          }
        />
      ),
      name: "Consultés",
    },
    {
      icon: (
        <InlineIcon icon="akar-icons:circle-fill" className="un_consulted" />
      ),
      name: "Non consulté",
    },
    {
      icon: <InlineIcon icon="akar-icons:circle-fill" className="followers" />,
      name: "Mes Spectateurs",
    },
  ];

  const [fakeItems, setFakeItems] = useState<TProfileList>([]);

  const scrollRight = () => {
    let nbrOfLoadedItem = fakeItems
      .filter((value) => {
        const fullname = value.username!;
        return (
          fullname!
            .toLocaleLowerCase()
            .indexOf(searchedText.toLocaleLowerCase()) !== -1
        );
      })
      .filter((value) => {
        return value.type!.indexOf(activeButton) !== -1;
      }).length;
    let maxScrollRight = (nbrOfLoadedItem - 1) * itemCardWidth;

    if (scrollInt < 0) {
      setScrollInt(0);
      document.getElementsByClassName("ct-home__body-part")[0]?.scroll({
        top: 0,
        left: itemCardWidth,
        behavior: "smooth",
      });
    } else if (scrollInt >= maxScrollRight) {
      setScrollInt(maxScrollRight);
      document.getElementsByClassName("ct-home__body-part")[0]?.scroll({
        top: 0,
        left: maxScrollRight,
        behavior: "smooth",
      });
    } else {
      setScrollInt(scrollInt + itemCardWidth);
      document.getElementsByClassName("ct-home__body-part")[0]?.scroll({
        top: 0,
        left: scrollInt + itemCardWidth,
        behavior: "smooth",
      });
    }

    hasmore && fetchMoreData();
  };

  const scrollLeft = () => {
    if (scrollInt <= 0) {
      setScrollInt(0);
      document.getElementsByClassName("ct-home__body-part")[0]?.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setScrollInt(scrollInt - itemCardWidth);
      document.getElementsByClassName("ct-home__body-part")[0]?.scroll({
        top: 0,
        left: scrollInt - itemCardWidth,
        behavior: "smooth",
      });
    }
  };

  const fetchMoreData = () => {
    setPage(page + 1);
    console.log(page);
    setLoadingMore(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetMember(token, page + 1, 30, search_query + searchQueryHome)
        .then((response: any) => {
          setLoadingMore(false);
          console.log(response);

          setAllMemberCount(response.total);

          if (page === response.number_of_pages || page === response.pages) {
            setHasmore(false);
          } else {
            let tempList: TProfileList;
            tempList = response.items.map((data: any) => {
              return {
                isVerified: data.is_verified,
                username: data.username,
                type:
                  (data.has_visited && "Mes spectateurs") ||
                  (data.is_visited ? "consulted" : "non consultés"),
                progressValue: data.rate_of_completion,
                profil_id: data.profile_id,
                isOnline: data.is_online,
                age: data.age,
                town: data.city,
                country: data.country,
                origine: data.home_country,
                nationalite: data.citizenship,
                hasMore: data.has_more,
                sendStatut: "",
                description: data.description,
                profile_wanted: data.profile_wanted,
                date_of_creation: data.date_of_creation,
                last_connection: data.last_connection,
              };
            });
            console.log([...tempList]);
            setFakeItems(fakeItems.concat([...tempList]));
          }
        })
        .catch((error) => {
          setLoadingMore(false);
          //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
        });
  };

  useEffect(() => {
    console.log("searchQueryHome", searchQueryHome);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      setLoading(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetMember(token, 1, 30, search_query + searchQueryHome)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          setAllMemberCount(response.total);

          let tempList: TProfileList;
          tempList = response.items.map((data: any) => {
            return {
              isVerified: data.is_verified,
              username: data.username,
              type:
                (data.has_visited && "Mes spectateurs") ||
                (data.is_visited ? "consulted" : "non consultés"),
              progressValue: data.rate_of_completion,
              profil_id: data.profile_id,
              isOnline: data.is_online,
              age: data.age,
              town: data.city,
              country: data.country,
              origine: data.home_country,
              nationalite: data.citizenship,
              hasMore: data.has_more,
              sendStatut: "",
              description: data.description,
              profile_wanted: data.profile_wanted,
              date_of_creation: data.date_of_creation,
              last_connection: data.last_connection,
            };
          });

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          console.log("ERROR", error);
          setLoading(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryHome]);

  useEffect(() => {
    if (document.querySelector(".card-profile")) {
      const fakeItemCard = document.querySelector(".card-profile");
      const size = (fakeItemCard as HTMLElement).clientWidth + 20;
      setItemCardWidth(size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.querySelector(".card-profile")]);

  return (
    <>
      {(typeof (userConnected as TUserConnected).account
        .reason_of_desactivation_by_admin === "string"
        ? false
        : (userConnected as TUserConnected).account.is_free ||
          ((userConnected as TUserConnected).account.is_active &&
            (userConnected as TUserConnected).account
              .is_subscription_active)) && (
        <div className="ct-home">
          <div className="ct-home__btn-top-container">
            <Button
              variant="contained"
              color="info"
              className="ct-favorite__nav-part__btn-nav"
              onClick={handleOpenAlert}
            >
              <InlineIcon
                icon="uil:info-circle"
                style={{ fontSize: "x-large" }}
              />
              À Lire absolument
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ct-favorite__nav-part__btn-nav"
              onClick={() => {
                navigate(routePaths.profileChildren.searchAbsolute, {
                  replace: true,
                });
              }}
              style={{ fontSize: "small" }}
              startIcon={
                <InlineIcon icon="material-symbols:manage-search-rounded" />
              }
            >
              Définir mes critères
            </Button>
          </div>

          <div className="ct-home__filter-part">
            <TextField
              placeholder="pseudo/id"
              variant="outlined"
              label="Rechercher"
              name="recherche"
              className="searchInput"
              type="text"
              value={searchedText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            {matches_sm && (
              <>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  className="ct-home__filter-part__speed-dial"
                  direction="down"
                  icon={
                    <InlineIcon icon="ep:filter" style={{ fontSize: "20px" }} />
                  }
                >
                  {actions.map((action, index) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      open={true}
                      tooltipOpen
                      onClick={() => {
                        if (index === 0) {
                          // Tout
                          setActiveButton("");
                          handleActiveButton("");
                        }
                        if (index === 2) {
                          // Imam
                          setActiveButton("non consultés");
                          handleActiveButton("non consultés");
                        }
                        if (index === 3) {
                          // Prédicateur
                          setActiveButton("Mes spectateurs");
                          handleActiveButton("Mes spectateurs");
                        }
                        if (index === 1) {
                          // Prédicateur
                          setActiveButton("consulted");
                          handleActiveButton("consulted");
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
                {activeButton !== "" && (
                  <Chip
                    label={activeButton.toUpperCase()}
                    onDelete={() => {
                      setActiveButton("");
                      handleActiveButton("");
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
              </>
            )}

            {!matches_sm && (
              <>
                <Button
                  variant={
                    activeButton === "non consultés" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("non consultés");
                    handleActiveButton("non consultés");
                  }}
                  color="primary"
                  className="ct-home__filter-part__btn-nav"
                  startIcon={
                    <InlineIcon
                      icon="akar-icons:circle-fill"
                      className="un_consulted"
                    />
                  }
                >
                  Non consultés
                </Button>
                <Button
                  variant={
                    activeButton === "Mes spectateurs"
                      ? "outlined"
                      : "contained"
                  }
                  onClick={() => {
                    setActiveButton("Mes spectateurs");
                    handleActiveButton("Mes spectateurs");
                  }}
                  color="primary"
                  className="ct-home__filter-part__btn-nav"
                  startIcon={
                    <InlineIcon
                      icon="akar-icons:circle-fill"
                      className="followers"
                    />
                  }
                >
                  Mes spectateurs
                </Button>
                <Button
                  variant={
                    activeButton === "consulted" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("consulted");
                    handleActiveButton("consulted");
                  }}
                  color="primary"
                  className="ct-home__filter-part__btn-nav"
                  startIcon={
                    <InlineIcon
                      icon="akar-icons:circle-fill"
                      className={
                        (userConnected as TUserConnected).infos.gender ===
                        "male"
                          ? "consulted male"
                          : "consulted female"
                      }
                    />
                  }
                >
                  Consultés
                </Button>
                <Button
                  variant={activeButton === "" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("");
                    handleActiveButton("");
                  }}
                  color="primary"
                  className="ct-home__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Tout
                </Button>
              </>
            )}
          </div>
          <div className="result">
            <p>
              <b>
                {
                  fakeItems
                    .filter((value) => {
                      const fullname = value.username!;
                      return (
                        fullname!
                          .toLocaleLowerCase()
                          .indexOf(searchedText.toLocaleLowerCase()) !== -1
                      );
                    })
                    .filter((value) => {
                      return value.type!.indexOf(activeButton) !== -1;
                    }).length
                }{" "}
                /{allMemberCount}
              </b>
              &nbsp; profil(s) trouvé(s)
              {search_query !== "" && (
                <>
                  <b style={{ color: "var(--ui-primary)" }}>
                    {" "}
                    &nbsp;•&nbsp; Cette liste est filtrée &nbsp;•&nbsp;{" "}
                  </b>
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(routePaths.profileChildren.searchAbsolute, {
                        replace: true,
                      });
                    }}
                  >
                    {" "}
                    Modifier le filtre{" "}
                  </Link>
                </>
              )}
            </p>
          </div>

          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            <>
              <div id="scrollableDiv-home">
                <>
                  {false && matches_sm && (
                    <div className="horizontal-top-management">
                      <Button
                        className="left-icon nav-btn-scroll"
                        size="large"
                        color="primary"
                        onClick={scrollLeft}
                        startIcon={<InlineIcon icon="ep:arrow-left" />}
                      >
                        Précédent
                      </Button>

                      <LoadingButton
                        loading={loadingMore}
                        loadingPosition="center"
                        className="right-icon nav-btn-scroll"
                        size="large"
                        color="primary"
                        onClick={scrollRight}
                        endIcon={<InlineIcon icon="ep:arrow-right" />}
                      >
                        Suivant
                      </LoadingButton>
                    </div>
                  )}
                  <InfiniteScroll
                    className="ct-home__body-part"
                    scrollableTarget="scrollableDiv-home"
                    dataLength={fakeItems.length}
                    next={fetchMoreData}
                    hasMore={hasmore}
                    loader={
                      fakeItems.length > 0 ? (
                        <img
                          src={require("@/assets/images/gif/loadmore.gif")}
                          className="load-more"
                          alt="load-more"
                        />
                      ) : (
                        <>
                          <NoData />
                          {(userConnected as TUserConnected).account
                            .has_conversation &&
                            !(userConnected as TUserConnected).account
                              .pending_cancel_conversation && (
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {" "}
                                Vous êtes actuellement en <b>échange</b>. <br />
                                Vous ne pouvez pas avoir accès aux autres
                                profils{" "}
                              </p>
                            )}
                          {(userConnected as TUserConnected).account
                            .pending_cancel_conversation && (
                            <p
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "1.2rem",
                              }}
                            >
                              {" "}
                              Votre clôture d'échange est actuellement en{" "}
                              <b>examination</b> auprès de nos modérateurs.{" "}
                              <br />
                              Vous ne pouvez pas avoir accès aux autres profils{" "}
                            </p>
                          )}
                        </>
                      )
                    }
                  >
                    {fakeItems.map((value, index) => {
                      return (
                        <ProfileCard
                          key={index}
                          isOnline={value.isOnline}
                          isVerified={value.isVerified}
                          hasMore={value.hasMore}
                          username={value.username}
                          description={value.description}
                          profile_wanted={value.profile_wanted}
                          type={value.type}
                          age={value.age}
                          town={value.town}
                          country={value.country}
                          origine={value.origine}
                          profil_id={value.profil_id}
                          nationalite={value.nationalite}
                          progressValue={value.progressValue}
                          sendStatut={value.sendStatut}
                          date_of_creation={value.date_of_creation}
                          last_connection={value.last_connection}
                        />
                      );
                    })}
                  </InfiniteScroll>
                  {false && matches_sm && (
                    <div className="horizontal-bottom-management">
                      <img
                        src={require("@/assets/images/gif/scroll-down.gif")}
                        className="horizontal-scroll-gif"
                        alt="horizontal-scroll"
                      />
                      {false && (
                        <Button
                          className="btn-load-more"
                          color="primary"
                          onClick={fetchMoreData}
                        >
                          Charger plus
                        </Button>
                      )}
                    </div>
                  )}
                </>
              </div>
            </>
          )}
        </div>
      )}
      {openAlert && (
        <CModalInfosZawaj open={openAlert} closeModal={handleCloseAlert} />
      )}
      {(userConnected as TUserConnected).account.changes_requests_rejected && (
        <CModalRejectedModif
          open={
            (userConnected as TUserConnected).account.changes_requests_rejected
          }
        />
      )}
      <CModalRemindPay
        open={
          typeof (userConnected as TUserConnected).account
            .reason_of_desactivation_by_admin === "string"
            ? true
            : !(userConnected as TUserConnected).account.is_free &&
              (!(userConnected as TUserConnected).account.is_active ||
                !(userConnected as TUserConnected).account
                  .is_subscription_active)
        }
        type={
          (userConnected as TUserConnected).account.is_subscription_expired
            ? "reabonnement"
            : "abonnement"
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  search_query: selectSearchQuery,
  currentExchange: selectCurrentExchange,
});

const connector = connect(mapStateToProps);
const CtHome = connector(CtHomeFct);

export default CtHome;
