import React from 'react';
import { Badge, Slide, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { InlineIcon } from "@iconify/react";
import './Bottombar.styles.scss';
import { routePaths } from '@/config';
import { useNavigate } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { styled } from '@mui/material/styles';
import { selectNbreNotificationSocket } from '@/store/reducers/general_param/general_param.selector';
import { selectExchangeNotif, selectFavoriteNotif, selectMessageNotif } from '@/store/reducers/member/member.selector';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));


type PropsFromRedux = ConnectedProps<typeof connector>;
const BottombarFct: React.FC<PropsFromRedux> = ({
    nbre_notification_socket,
    nbre_message_notif,
    nbre_favorite_notif,
    nbre_exchange_notif,
}) => {

    const theme = useTheme();
    const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));
    const matches_down_md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [value, setValue] = React.useState(routePaths.profileChildren.homeAbsolute);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const goTo = (route: string) => {
        navigate(route, { replace: true });
    }

    React.useEffect(() => {
        setValue(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])


    return (
        <Slide direction="up" in={matches_down_lg} mountOnEnter unmountOnExit>
            <div className="c-bottombar">
                <BottomNavigation
                    className="mui-bottom-navigation"
                    value={value} onChange={handleChange}>
                    <BottomNavigationAction
                        label={matches_down_md ? null : "Accueil"}
                        value={routePaths.profileChildren.homeAbsolute}
                        onClick={() => {
                            goTo(routePaths.profileChildren.homeAbsolute)
                        }}
                        icon={
                            <InlineIcon icon="bx:home" />
                        }
                    />
                    <BottomNavigationAction
                        label={matches_down_md ? null : "Favoris"}
                        value={routePaths.profileChildren.favoriteAbsolute}
                        onClick={() => {
                            goTo(routePaths.profileChildren.favoriteAbsolute)
                        }}
                        icon={
                            <StyledBadge
                            overlap="circular"
                            variant="dot"
                            color={(value === routePaths.profileChildren.favoriteAbsolute) ? "primary" : "warning"}
                            badgeContent={
                                nbre_favorite_notif
                            } >
                            <InlineIcon icon="bx:star" />
                        </StyledBadge>
                        }
                    />
                    <BottomNavigationAction
                        label={matches_down_md ? null : "Échanges"}
                        value={routePaths.profileChildren.exchangeAbsolute}
                        onClick={() => {
                            goTo(routePaths.profileChildren.exchangeAbsolute)
                        }}
                        icon={
                            <StyledBadge
                                overlap="circular"
                                variant="dot"
                                color={(value === routePaths.profileChildren.notificationAbsolute) ? "primary" : "warning"}
                                badgeContent={
                                    nbre_message_notif + nbre_exchange_notif // <---
                                } >
                                <InlineIcon icon="bx:message-rounded-detail" />
                            </StyledBadge>
                        }
                    />
                    <BottomNavigationAction
                        label={matches_down_md ? null : "Rechercher"}
                        value={routePaths.profileChildren.searchAbsolute}
                        onClick={() => {
                            goTo(routePaths.profileChildren.searchAbsolute)
                        }}
                        icon={
                            <InlineIcon icon="bx:search-alt" />
                        }
                    />
                    <BottomNavigationAction
                        label={matches_down_md ? null : "Profil"}
                        value={routePaths.profileChildren.myAccountAbsolute}
                        onClick={() => {
                            goTo(routePaths.profileChildren.myAccountAbsolute)
                        }}
                        icon={
                            <InlineIcon icon="bx:user" />
                        }
                    />

                    <BottomNavigationAction
                        label={matches_down_md ? null : "Notifications"}
                        value={routePaths.profileChildren.notificationAbsolute}
                        onClick={() => {
                            goTo(routePaths.profileChildren.notificationAbsolute)
                        }}
                        icon={
                            <StyledBadge
                                overlap="circular"
                                variant="dot"
                                color={(value === routePaths.profileChildren.notificationAbsolute) ? "primary" : "warning"}
                                badgeContent={
                                    nbre_notification_socket
                                } >
                                <InlineIcon icon="bx:bell" />
                            </StyledBadge>
                        }
                    />
                </BottomNavigation>
            </div>
        </Slide>
    )
}


const mapStateToProps = createStructuredSelector({
    nbre_notification_socket: selectNbreNotificationSocket,
    nbre_message_notif: selectMessageNotif,
    nbre_favorite_notif: selectFavoriteNotif,
    nbre_exchange_notif: selectExchangeNotif,
});


const connector = connect(mapStateToProps);
const Bottombar = connector(BottombarFct);


export default Bottombar;