import { SuspenseFallback } from "@/components/SuspenseFallback/SuspenseFallback";
import { routePaths } from "@/config";
import { socketChannels } from "@/config/socketChannels";
import { AppSocketContext } from "@/routes";
import { store, useAppDispatch } from "@/store";
import {
  updateCurrentExchange,
  updateGeneralLinks,
  updateMailFemale,
  updateMailMale,
  updateMaxCaracter,
  updateMaxMessage,
  updateModeration,
  updateNbreLiveSocket,
  updateNbreNotificationSocket,
} from "@/store/reducers/general_param/general_param.actions";
import {
  getUserConnected,
  updateExchangeNotif,
  updateFavoriteNotif,
  updateMessageNotif,
  updateProgress,
} from "@/store/reducers/member/member.actions";
import {
  selectToken,
  selectUserConnected,
} from "@/store/reducers/member/member.selector";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { selectOpened } from "@/store/reducers/sidebar/sidebar.selector";
import classNames from "classnames";
import classnames from "classnames";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { apiGetNotification } from "../api/notification.api";
import Bottombar from "../containers/Bottombar/Bottombar.container";
import SideBar from "../containers/Sidebar/SideBar.container";
import { initializeParam } from "../initialize_param/initialize_param";
import {
  TCurrentExchange,
  TInterfaceSettings,
  TModerationSocket,
  TUserConnected,
} from "../types";
import "./Profile.styles.scss";

type TProps = {};
type PropsFromRedux = ConnectedProps<typeof connector>;

const LProfileFunction: React.FC<TProps & PropsFromRedux> = ({
  sidebarOpened,
  userConnected,
  token,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { socketIO } = useContext(AppSocketContext);

  const [permissionGranted, setPermissionGranted] = useState(false);

  useEffect(() => {
    // Check if notifications are supported
    if ("Notification" in window) {
      // Request permission to show notifications
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setPermissionGranted(true);
        }
      });
    }
  }, []);

  const showNotification = () => {
    if (permissionGranted) {
      // Show a notification
      new Notification("Notification", {
        body: "Nouvelle Notification",
        icon: "../../../assets/images/png/logo-mini.png",
      });
    }
  };

  useEffect(() => {
    initializeParam();

    (userConnected as TUserConnected).account.is_fresh_new &&
      navigate(routePaths.profileChildren.myAccountChildren.ficheAbsolute, {
        replace: true,
      });

    token.length === 0 && navigate(routePaths.auth, { replace: true });

    const { rate_of_completion } = userConnected as TUserConnected;
    if (rate_of_completion) {
      store.dispatch(updateProgress(rate_of_completion));
    }

    if (socketIO) {
      socketIO.on(socketChannels.user_is_moderation_open, (data) => {
        store.dispatch(updateModeration(data as TModerationSocket));
      });

      socketIO.on(socketChannels.member_profile_has_changed, (data) => {
        console.log("||| 🔥 PROFILE - has changed", data);
        store.dispatch(getUserConnected(data as TUserConnected));
      });

      socketIO.on(socketChannels.interface_setting_channel, (data) => {
        console.log("||| 🌕 interface", data);
        let interfaceSetting: TInterfaceSettings = data as TInterfaceSettings;
        store.dispatch(
          updateMaxMessage(
            interfaceSetting.moderation.number_of_messages_per_discussion
          )
        );
        store.dispatch(
          updateMaxCaracter(
            interfaceSetting.moderation.number_of_caracters_per_message
          )
        );
        store.dispatch(updateMailMale(interfaceSetting.men_group_head.email));
        store.dispatch(
          updateMailFemale(interfaceSetting.women_group_head.email)
        );
        store.dispatch(
          updateGeneralLinks({
            youtube: interfaceSetting.web_media.youtube,
            telegram: interfaceSetting.social_media.telegram,
            facebook: interfaceSetting.social_media.facebook,
            twitter: interfaceSetting.social_media.twitter,
            instagram: interfaceSetting.social_media.instagram,
          })
        );
      });

      apiGetNotification(token).then((response: any) => {
        store.dispatch(updateNbreNotificationSocket(response.count));
        // store.dispatch(updateFavoriteNotif(2));
        showNotification();
      });

      socketIO.on(socketChannels.notification_channel, (data) => {
        store.dispatch(updateNbreNotificationSocket(2));
        showNotification();
      });

      socketIO.on(socketChannels.member_conversation_messages, (data) => {
        store.dispatch(updateMessageNotif(2));
        showNotification();
      });

      socketIO.on(socketChannels.live_channel, (data) => {
        store.dispatch(updateNbreLiveSocket(2));
        showNotification();
      });

      socketIO.on(socketChannels.current_exchange_channel, (data) => {
        console.log(data);
        store.dispatch(updateMessageNotif(2));
        false &&
          store.dispatch(updateCurrentExchange(data as TCurrentExchange));
      });

      socketIO.on(socketChannels.new_favorite_notif, (data) => {
        store.dispatch(updateFavoriteNotif(2));
        showNotification();
        console.log(data);
      });

      socketIO.on(socketChannels.new_demand_exchange_notif, (data) => {
        store.dispatch(updateExchangeNotif(2));
        showNotification();
      });

      socketIO.on(socketChannels.accept_demand_channel, (data) => {
        store.dispatch(updateExchangeNotif(2));
        showNotification();
      });

      socketIO.on(socketChannels.cloture_demand_channel, (data) => {
        store.dispatch(updateExchangeNotif(2));
        showNotification();
      });
    } else {
      console.log("||| 🔥🔥", socketIO);
    }

    return () => {
      if (socketIO) {
        socketIO.off(socketChannels.user_is_moderation_open);
        socketIO.off(socketChannels.member_profile_has_changed);
        socketIO.off(socketChannels.notification_channel);
        socketIO.off(socketChannels.member_conversation_messages);
        socketIO.off(socketChannels.live_channel);
        socketIO.off(socketChannels.current_exchange_channel);
        socketIO.off(socketChannels.new_favorite_notif);
        socketIO.off(socketChannels.new_demand_exchange_notif);
        socketIO.off(socketChannels.accept_demand_channel);
        socketIO.off(socketChannels.cloture_demand_channel);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO]);

  return (
    <div className="l-profile">
      <SideBar />
      <div
        className={classNames("backdrop", { showed: sidebarOpened })}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      ></div>
      <div className={classnames("profile-content")}>
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
        <Bottombar />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const LProfile = connector(LProfileFunction);

export default LProfile;
