import React, { useEffect, useState } from "react";
import * as moment from "moment";
import "moment/locale/fr";
import "./NotificationCard.styles.scss";
import { InlineIcon } from "@iconify/react";
import { CloseRounded } from "@mui/icons-material";
import { Chip, IconButton, Modal, TextField } from "@mui/material";
import FullProfil from "@/components/FullProfil/FullProfil";

type TNotificationCard = {
  title: string;
  message: string;
  date: string;
  onClose: () => void;
};

const NotificationCard: React.FC<TNotificationCard> = ({
  title,
  message,
  date,
  onClose,
}) => {
  const [linkList, setLinkList] = useState<RegExpMatchArray | null>(null);
  const [idList, setIdList] = useState<RegExpMatchArray | null>(null);
  const [selectedId, setSelectedid] = useState<string>("");

  const [openFullProfil, setOpenFullProfil] = React.useState(false);
  const handleOpenFullProfil = (id: string) => {
    setSelectedid(id);
    setOpenFullProfil(true);
  };
  const handleCloseFullProfil = () => setOpenFullProfil(false);

  const detecterLiens = (texte: string): RegExpMatchArray | null => {
    // Expression régulière pour reconnaître un motif de lien HTTP/HTTPS
    var regex = /(http:\/\/|https:\/\/)[^\s]+/gi;

    // Recherche de tous les liens correspondants dans la chaîne de texte
    var resultats = texte.match(regex);

    // Retourne un tableau contenant tous les liens détectés
    return resultats;
  };

  const detecterId = (texte: string): RegExpMatchArray | null => {
    // Expression régulière pour reconnaître un motif d'Id
    var regex = /([0-9]{6})/gi;

    // Recherche de tous les motifs d'id correspondants dans la chaîne de texte
    var resultats = texte.match(regex);

    // Retourne un tableau contenant tous les ids détectés
    return resultats;
  };

  useEffect(() => {
    const tempLinkList = detecterLiens(message);
    setLinkList(tempLinkList);

    const tempIdList = detecterId(message);
    setIdList(tempIdList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card-notification">
        <div className="head-part">
          <div className="img-container">
            <img
              src={require("@/assets/images/png/bell.png")}
              alt="logo-mini"
            />
          </div>

          <div className="middle-container">
            <h3> {title} </h3>
            <TextField
              multiline
              InputProps={{
                readOnly: true,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: 0,
                  borderColor: "transparent !important",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent !important",
                },
              }}
              className="chat-message__text"
              defaultValue={message}
            />
            {linkList && (
              <div className="link-part">
                {linkList.map((item) => (
                  <>
                    <Chip
                      icon={<InlineIcon icon="gg:link" height={"1.3rem"} />}
                      label={item}
                      color="primary"
                      component="a"
                      target="_blank"
                      href={item}
                      sx={{
                        height: "auto",
                        padding: "10px",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                      }}
                      clickable
                    />
                  </>
                ))}
              </div>
            )}
            {idList && (
              <div className="link-part">
                {idList.map((item) => (
                  <>
                    <Chip
                      icon={
                        <InlineIcon icon="basil:user-solid" height={"1.3rem"} />
                      }
                      label={item}
                      color="warning"
                      sx={{
                        height: "auto",
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "30px",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        },
                      }}
                      onClick={() => {
                        handleOpenFullProfil(item);
                      }}
                      clickable={true}
                    />
                  </>
                ))}
              </div>
            )}

            <div className="time-part">
              <div className="date">
                <span>
                  <InlineIcon icon="bx:calendar" />
                  &nbsp; {moment.default(date).format("L")}{" "}
                  {moment.default(new Date(date)).format("LT")}
                </span>
                <span className="corner" style={{ display: "none" }}>
                  {Math.abs(
                    moment
                      .default(date)
                      .diff(moment.default(new Date()), "days")
                  ) === 0 ? (
                    <>
                      <b> Aujourd'hui</b>
                    </>
                  ) : (
                    <>
                      il y a{" "}
                      <b>
                        {Math.abs(
                          moment
                            .default(date)
                            .diff(moment.default(new Date()), "days")
                        ) + " jrs"}
                      </b>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
          {true && (
            <IconButton
              aria-label="fingerprint"
              color="default"
              onClick={onClose}
              className="close"
            >
              <CloseRounded />
            </IconButton>
          )}
        </div>
      </div>
      {/* FULL PROFIL */}
      <Modal
        open={openFullProfil}
        onClose={handleCloseFullProfil}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <FullProfil
            closeModal={handleCloseFullProfil}
            profil_id={selectedId}
          />
        </>
      </Modal>
      {/* End of FULL PROFIL */}
    </>
  );
};

export default NotificationCard;
