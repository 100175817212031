import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";
import { TFiche } from "../types";

export const apiSaveFiche = (
  data: TFiche,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.patch("/members/profile/main-infos", data, config);
};

export const apiSaveProfile = (
  data: any,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.patch("/members/profile/profile-infos", data, config);
};

export const apiSaveAspectFemale = (
  data: any,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.patch(
    "/members/profile/female/religious-aspects-infos",
    data,
    config
  );
};

export const apiSaveAspectMale = (
  data: any,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.patch(
    "/members/profile/male/religious-aspects-infos",
    data,
    config
  );
};

export const apiSavePratique = (
  data: any,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.patch(
    "/members/profile/religious-practices-infos",
    data,
    config
  );
};

export const apiSaveSante = (
  data: any,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.patch("/members/profile/health-infos", data, config);
};

export const apiSearchProfile = (
  access_token: string,
  page: number = 1,
  limit: number = 6,
  data: string
): Promise<AxiosResponse<any, any>> => {
  axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
  return axios.get(
    "/members/filtered/members?" + data + "&page=" + page + "&limit=" + limit
  );
};

export const apiGetMember = (
  access_token: string,
  page: number = 1,
  limit: number = 6,
  data: string
): Promise<AxiosResponse<any, any>> => {
  axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
  if (data !== "") {
    return axios.get(
      "/members/filtered/members?" + data + "&page=" + page + "&limit=" + limit
    );
  } else {
    return axios.get(
      "members/not-detailed/members?page=" + page + "&limit=" + limit
    );
  }
};

export const apiGetFullProfil = (
  member_id: string,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
  return axios.patch("/members/member-profile/" + member_id);
};

export const apiAskForExchange = (
  member_id: string,
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
  return axios.patch("/conversations/request/" + member_id);
};

export const apiSignalMember = (
  data: { member_id: string; note: string },
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  const config = {
    headers: {
      Authorization: `${access_token}`,
    },
  };
  return axios.post("/reports", data, config);
};

// Faux commentaire
