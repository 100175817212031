import { routePaths } from '@/config';
import { selectUserConnected } from '@/store/reducers/member/member.selector';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import "./MyAccount.styles.scss";



type PropsFromRedux = ConnectedProps<typeof connector>;
const CtMyAccountFunction: React.FC<  PropsFromRedux> = ({
  userConnected
}) => {

  const navigate = useNavigate();

  useEffect(() => {
    
    // !(userConnected as TUserConnected).account.are_main_infos_provided  
    //       ?
    //         navigate(routePaths.profileChildren.myAccountChildren.ficheAbsolute, {replace: true})
    //       :
    //         navigate(routePaths.profileChildren.myAccountChildren.formAbsolute, {replace: true});

    navigate(routePaths.profileChildren.myAccountChildren.ficheAbsolute, {replace: true}); // à la demande de momo
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className='ct-my-account'>
        <Outlet/>
    </div>
  )
}


const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected
});


const connector = connect(mapStateToProps);
const CtMyAccount = connector(CtMyAccountFunction);

export default CtMyAccount;