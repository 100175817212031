import { TThemeColors } from "../utils/convertThemeColorsToRootColors";

export const colors: TThemeColors = {
    ui: {
        primary: "#73103E",
        secondary: "#FEF8EC",
        tertiary: "#DEB86B",
        'tertiary-shade': "#bf9c57",
        dark: "#252525",
        gray: "#8790a1",
        disabled: "#cecece",
        medium: "#363853",
        light: "#F9F9F9",
        black: "#000000",
        white: "#FFFFFF",
        success: "#75D873",
        green:"#4B9249",
        error: "#FF3000",
        warning: "#F5B544",
        'pink-for-male':"#fbf1f9",
        'blue-for-female':"#e0f3fe",
        'pink-for-male-shade':"#f691e1",
        'blue-for-female-shade':"#6ecaff",
        blue: "#3479cd",
        red: "#ae2847",
    }

};
