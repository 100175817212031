import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import { InlineIcon } from "@iconify/react";
import { Button, IconButton } from '@mui/material';
import { HighlightOffRounded } from '@mui/icons-material';
import { selectUserConnected } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TUserConnected } from '@/features/profile/types';
import './ModalRejectedModif.styles.scss';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/config';



type TModalProps = {
  open: boolean,
  fromAccount?: boolean,
}
type PropsFromRedux = ConnectedProps<typeof connector>;


const CModalRejectedModifFct: React.FC<TModalProps & PropsFromRedux> = ({
  open,
  userConnected,
  fromAccount = false,
}) => {

  const [openM, setOpenM] = useState(open);
  const navigate = useNavigate();

  const goToAccount = () => {
      setOpenM(false);
      !fromAccount && navigate(routePaths.profileChildren.myAccountAbsolute, { replace: true });

  }


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openM}
      onClose={()=>{setOpenM(false)}}
    >
      <Grow in={openM}>
        <div className='c-modal-rejected-modif '>
          <IconButton className='close-icon'
            size="large"
            color='inherit'
            onClick={()=>{setOpenM(false)}}>
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require("@/assets/images/png/avatar-" + (userConnected as TUserConnected).infos.gender + ".png")}
            alt="sidebar-logo"
            className="modal-avatar"
          />



          {
            
            <>
              <h3><b> MODIFICATIONS REJETÉES </b></h3>
              <br />

              <p>
                    Les modifications apportées à votre profil ont été <b> rejetées</b> par notre équipe de modération,<br />
                    {/* pour la raison suivante:&nbsp;<b>{(userConnected as TUserConnected).account.reason_of_desactivation_by_admin}</b><br /> */}
                    <strong> Veuillez apporter les corrections adéquates à vos modifications pour qu'elles puissent être prises en compte.</strong><br />
                    Avant l'approbation de vos nouvelles modifications , votre profil sera inaccessible aux autres membres<br />
              </p>
              <Button
              className='btn-modal'
              variant="contained"
              color="primary"
              onClick={goToAccount}
              endIcon={
                <InlineIcon icon="material-symbols:edit-note" />
              }
            >
              Corriger mon profil
            </Button>
            </>
          }



        </div>
      </Grow>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected
});


const connector = connect(mapStateToProps);
const CModalRejectedModif = connector(CModalRejectedModifFct);

export default CModalRejectedModif;