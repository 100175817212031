import React from "react";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./EchangeHeader.styles.scss";
import {
  TCurrentExchange,
  TUserConnected,
  initCurrentExchange,
} from "@/features/profile/types";
import { InlineIcon } from "@iconify/react";
import classnames from "classnames";
import {
  selectMaxMessage,
  selectNbreMessageReceive,
  selectNbreMessageSent,
} from "@/store/reducers/general_param/general_param.selector";

type TEchangeHeader = {
  currentExchange?: TCurrentExchange;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const EchangeHeaderFct: React.FC<TEchangeHeader & PropsFromRedux> = ({
  userConnected,
  currentExchange,
  max_message,
  nbre_message_sent,
  nbre_message_receive,
  token,
}) => {
  const testSender = (): boolean => {
    return (
      (userConnected as TUserConnected).member_id ===
      (currentExchange as TCurrentExchange).sender.profile_id
    );
  };

  const cleanCurrentExchange = (): TCurrentExchange => {
    let result: TCurrentExchange = initCurrentExchange;

    if ((currentExchange as TCurrentExchange).receiver === undefined) {
      result = {
        ...(currentExchange as TCurrentExchange),
        receiver: initCurrentExchange.receiver,
      };
    } else if ((currentExchange as TCurrentExchange).sender === undefined) {
      result = {
        ...(currentExchange as TCurrentExchange),
        sender: initCurrentExchange.sender,
      };
    } else {
      result = currentExchange as TCurrentExchange;
    }

    return result;
  };

  return (
    <>
      <div className={classnames("c-echange-header")}>
        <div className="user-part">
          {testSender() ? (
            (userConnected as TUserConnected).infos.gender === "male" ? (
              <img
                src={require("@/assets/images/png/avatar-male.png")}
                alt="logo"
              />
            ) : (
              <img
                src={require("@/assets/images/png/avatar-female.png")}
                alt="logo"
              />
            )
          ) : (userConnected as TUserConnected).infos.gender === "male" ? (
            <img
              src={require("@/assets/images/png/avatar-male.png")}
              alt="logo"
            />
          ) : (
            <img
              src={require("@/assets/images/png/avatar-female.png")}
              alt="logo"
            />
          )}
          <p>
            {testSender()
              ? cleanCurrentExchange().sender.username
              : cleanCurrentExchange().receiver.username}{" "}
            <br />
            {testSender()
              ? cleanCurrentExchange().sender.age
              : cleanCurrentExchange().receiver.age}{" "}
            ans
          </p>
          <div className="tag">
            <InlineIcon icon="akar-icons:location" />
            <p>
              <b>
                {testSender()
                  ? cleanCurrentExchange().sender.city
                  : cleanCurrentExchange().receiver.city}
              </b>{" "}
              /
              {testSender()
                ? cleanCurrentExchange().sender.country
                : cleanCurrentExchange().receiver.country}
            </p>
          </div>
          <small>
            {" "}
            Message restants{" "}
            <b>
              {max_message - nbre_message_sent < 0
                ? 0
                : max_message - nbre_message_sent}
              /{max_message}
            </b>{" "}
          </small>
        </div>

        <img
          className="double-arrow"
          src={require("@/assets/images/png/double-arrow.png")}
          alt="logo"
        />

        <div className="staff-part">
          <img src={require("@/assets/images/png/logo-mini.png")} alt="logo" />
          <p> Le staff Zawaj Sunnah </p>
          {currentExchange?.duration && (
            <p style={{ fontWeight: "bold", fontSize: "small" }}>
              En échange depuis <br />
              <b style={{ color: "var(--ui-primary)" }}>
                {currentExchange.duration.value} {currentExchange.duration.unit}
              </b>
            </p>
          )}
        </div>

        <img
          className="double-arrow"
          src={require("@/assets/images/png/double-arrow.png")}
          alt="logo"
        />

        <div className="user-part">
          {testSender() ? (
            (userConnected as TUserConnected).infos.gender === "male" ? (
              <img
                src={require("@/assets/images/png/avatar-female.png")}
                alt="logo"
              />
            ) : (
              <img
                src={require("@/assets/images/png/avatar-male.png")}
                alt="logo"
              />
            )
          ) : (userConnected as TUserConnected).infos.gender === "male" ? (
            <img
              src={require("@/assets/images/png/avatar-female.png")}
              alt="logo"
            />
          ) : (
            <img
              src={require("@/assets/images/png/avatar-male.png")}
              alt="logo"
            />
          )}
          <p>
            {!testSender()
              ? cleanCurrentExchange().sender.username
              : cleanCurrentExchange().receiver.username}{" "}
            <br />
            {!testSender()
              ? cleanCurrentExchange().sender.age
              : cleanCurrentExchange().receiver.age}{" "}
            ans
          </p>
          <div className="tag">
            <InlineIcon icon="akar-icons:location" />
            <p>
              <b>
                {!testSender()
                  ? cleanCurrentExchange().sender.city
                  : cleanCurrentExchange().receiver.city}
              </b>{" "}
              /
              {!testSender()
                ? cleanCurrentExchange().sender.country
                : cleanCurrentExchange().receiver.country}
            </p>
          </div>
          <small>
            {" "}
            Message restants{" "}
            <b>
              {max_message - nbre_message_receive < 0
                ? 0
                : max_message - nbre_message_receive}
              /{max_message}
            </b>{" "}
          </small>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  max_message: selectMaxMessage,
  nbre_message_sent: selectNbreMessageSent,
  nbre_message_receive: selectNbreMessageReceive,
});

const connector = connect(mapStateToProps);
const EchangeHeader = connector(EchangeHeaderFct);

export default EchangeHeader;
