import React from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import { IconButton } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";
import { selectUserConnected } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "@/features/profile/types";
import "./ModalInfosZawaj.styles.scss";

type TModalProps = {
  open: boolean;
  closeModal: () => void;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const CModalInfosZawajFct: React.FC<TModalProps & PropsFromRedux> = ({
  open,
  closeModal,
  userConnected,
}) => {
  const textForALireAbsolument = () => {
    if ((userConnected as TUserConnected).infos.gender === "female")
      return (
        <>
          <h3>
            <b> Salam aleykoum chères sœurs </b>
          </h3>
          <br />

          <p>
            Zawaj Sounnah est uniquement un service d'intermédiaire, nous
            faisons de notre mieux pour filtrer les bons profils et bannir les
            personnes pas sérieuses, mais il n'empêche qu'il vous est
            obligatoire de faire une enquête sur le prétendant via la famille !
            <br />
            <br />
            Ne vous précipitez pas dans le mariage, certaines sœurs se marient
            sans même que la famille du prétendant ne les connaît, ne faites
            jamais celà.
            <br />
            <br />
            Vous devez investiguer, poser des questions aux mères, aux sœurs...
            et si un homme ne veut pas impliquer sa famille, fuyez-le comme la
            peste et signalez-le nous. Nous ferons le nécessaire comme toujours
            in sha Allah.
            <br />
            <br />
            Impliquez votre tuteur, un homme aura moins l'audace de faire du mal
            si vous êtes entourées.
            <br />
            <br />
            Nous avons rédigé des articles où nous vous donnons des conseils et
            des techniques pour vous aider à vous protéger contre les mauvaises
            personnes.
            <br />
            <br />
            Le mariage est important, vous êtes responsables de vos choix, alors
            choisissez bien. Et heureusement al hamdu lillah ils restent
            beaucoup de frères très bien.
            <br />
            <br />
            <br />
            <b>
              Comment identifier un prédateur:{" "}
              <a
                href="https://zawaj-sounnah.com/les-freres-loups-cest-predateurs-qui-sattaquent-aux-soeurs/  "
                target="__blank"
              >
                https://zawaj-sounnah.com/les-freres-loups-cest-predateurs-qui-sattaquent-aux-soeurs/{" "}
              </a>
            </b>
            <br />
            <br />
            <b>
              Comment trouver un tuteur:{" "}
              <a
                href="https://zawaj-sounnah.com/comment-trouver-un-tuteur-pour-se-marier/"
                target="__blank"
              >
                https://zawaj-sounnah.com/comment-trouver-un-tuteur-pour-se-marier/
              </a>
            </b>
            <br />
            <br />
            <b>
              Ne pas faire de groupe WhatsApp avec votre tuteur:{" "}
              <a
                href="https://zawaj-sounnah.com/groupe-whatsapp-mariage/"
                target="__blank"
              >
                https://zawaj-sounnah.com/groupe-whatsapp-mariage/
              </a>
            </b>
            <br />
            <br />
          </p>
        </>
      );
    if ((userConnected as TUserConnected).infos.gender === "male")
      return (
        <>
          <h3>
            <b> Salam aleykoum chers frères </b>
          </h3>
          <br />

          <p>
            Le site Zawaj Sounnah est un service d'intermédiaire, vous avez
            signez un engagement,
            <br />
            Et comme Allah le Très-Haut a dit (traduction rapprochée) :<br />
            <b style={{ fontStyle: "italic" }}>
              « Et remplissez l’engagement, car on sera interrogé au sujet des
              engagements. » S17. V34
            </b>
            <br />
            Il a dit aussi (traduction rapprochée) :
            <br />
            <b style={{ fontStyle: "italic" }}>
              « Ô croyants ! Remplissez fidèlement vos engagements » S5. V1
            </b>
            <br />
            Il a dit aussi (traduction rapprochée) :
            <br />
            <b style={{ fontStyle: "italic" }}>
              « Ô vous qui avez cru ! Pourquoi dites-vous ce que vous ne faites
              pas ? C’est une grande abomination pour Allah que de dire ce que
              vous ne faites pas » S61. V2-3
            </b>
            <br />
            <br />
            Nous vous demandons donc de respecter nos règles. Tout homme qui
            triche, contacte une sœur en privé via l'intermédiaire de sa famille
            sera exclu définitivement du site, n'hésitez pas à demander au soeur
            de vous donner les numéros de leurs tuteurs. Et craignez Allah une
            fois en dehors du site.
            <br />
            <br />
            Qu'Allah vous facilite dans votre démarche. Voici quelques articles
            pour vous aider.
            <br />
            <b>
              Le danger des groupes WhatsApp :{" "}
              <a
                href="https://zawaj-sounnah.com/groupe-whatsapp-mariage/  "
                target="__blank"
              >
                https://zawaj-sounnah.com/groupe-whatsapp-mariage/{" "}
              </a>
            </b>
            <br />
            <br />
            <b>
              Les plus grandes erreurs avant la mouqabala :{" "}
              <a href="https://zawaj-sounnah.com/mouqabala/" target="__blank">
                https://zawaj-sounnah.com/mouqabala/
              </a>
            </b>
            <br />
            <br />
            <b>
              Article sur les sœurs trop exigentes :{" "}
              <a
                href="https://zawaj-sounnah.com/elle-veut-1-m-80-sinon-rien/"
                target="__blank"
              >
                https://zawaj-sounnah.com/elle-veut-1-m-80-sinon-rien/
              </a>
            </b>
            <br />
            <br />
          </p>
        </>
      );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={closeModal}
    >
      <Grow in={open}>
        <div className="parent">
          <div className="c-modal-infos-zawaj">
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={closeModal}
            >
              <HighlightOffRounded />
            </IconButton>
            <img
              src={require("@/assets/images/png/avatar-" +
                (userConnected as TUserConnected).infos.gender +
                ".png")}
              alt="sidebar-logo"
              className="modal-avatar"
            />

            {textForALireAbsolument()}
          </div>
        </div>
      </Grow>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const CModalInfosZawaj = connector(CModalInfosZawajFct);

export default CModalInfosZawaj;
