import { InlineIcon } from "@iconify/react";
import {
  Button,
  Chip,
  InputAdornment,
  SpeedDial,
  SpeedDialAction,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import InfiniteScroll from "react-infinite-scroll-component";
import ImamSpeakerCard, {
  TImamSpeakerCard,
} from "../../Elements/ImamSpeakerCard/ImamSpeakerCard";
import "./ImamSpeakers.styles.scss";
import NoData from "@/components/No-data/NoData";
import {
  selectToken,
  selectUserConnected,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { apiGetImamSpeakers } from "../../api/imams.api";
import { Toastify } from "@/utils/toast";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import { TUserConnected } from "../../types";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

type TFilteredValue = "imam" | "prédicateur" | "";
type PropsFromRedux = ConnectedProps<typeof connector>;
const CtImamSpeakersFct: React.FC<PropsFromRedux> = ({
  token,
  userConnected,
}) => {
  const [activeButton, setActiveButton] = useState<TFilteredValue>("");

  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchedText, setSearchedText] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
  };

  const actions = [
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Tout" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Imam" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Prédicateur" },
  ];

  const [loading, setLoading] = useState(false);
  const [fakeItems, setFakeItems] = useState<TImamSpeakerCard[]>([]);
  const [page, setPage] = useState(1);
  const [hasmore, setHasmore] = useState(false);

  const fetchMoreData = () => {
    setPage(page + 1);
    console.log(page);

    apiGetImamSpeakers(token)
      .then((response: any) => {
        console.log(response);

        if (page === response.number_of_pages) {
          setHasmore(false);
        }

        let tempList: TImamSpeakerCard[] = response.items.map((data: any) => {
          return {
            name: data.name,
            firstname: "",
            title: data.role === "speaker" ? "prédicateur" : data.role,
            cursus: data.course,
            reference: data.reference,
            youtube: data.youtube,
            facebook: data.facebook,
            twitter: data.twitter,
            instagram: data.instagram,
            telegram: data.telegram,
          };
        });

        console.log([...tempList]);
        setFakeItems([...tempList]);
      })
      .catch((error) => {
        setLoading(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  useEffect(() => {
    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      setLoading(true);

    (typeof (userConnected as TUserConnected).account
      .reason_of_desactivation_by_admin === "string"
      ? false
      : (userConnected as TUserConnected).account.is_free ||
        ((userConnected as TUserConnected).account.is_active &&
          (userConnected as TUserConnected).account.is_subscription_active)) &&
      apiGetImamSpeakers(token)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList: TImamSpeakerCard[] = response.items.map((data: any) => {
            return {
              name: data.name,
              firstname: "",
              title: data.role === "speaker" ? "prédicateur" : data.role,
              cursus: data.course,
              reference: data.reference,
              youtube: data.youtube,
              facebook: data.facebook,
              twitter: data.twitter,
              instagram: data.instagram,
              telegram: data.telegram,
            };
          });

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          setLoading(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(userConnected as TUserConnected).account.is_active && (
        <div className="ct-imam-speakers">
          <div className="ct-imam-speakers__filter-part">
            <TextField
              placeholder="Nom de l'imam /du prédicateur"
              variant="outlined"
              label="Rechercher"
              name="recherche"
              className="searchInput"
              type="text"
              value={searchedText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            {matches_sm && (
              <>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  className="ct-imam-speakers__filter-part__speed-dial"
                  direction="down"
                  icon={
                    <InlineIcon icon="ep:filter" style={{ fontSize: "20px" }} />
                  }
                >
                  {actions.map((action, index) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      onClick={() => {
                        if (index === 0) {
                          // Tout
                          setActiveButton("");
                        }
                        if (index === 1) {
                          // Imam
                          setActiveButton("imam");
                        }
                        if (index === 2) {
                          // Prédicateur
                          setActiveButton("prédicateur");
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
                {activeButton !== "" && (
                  <Chip
                    label={activeButton.toUpperCase()}
                    onDelete={() => {
                      setActiveButton("");
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
              </>
            )}

            {!matches_sm && (
              <>
                <Button
                  variant={activeButton === "imam" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("imam");
                  }}
                  color="primary"
                  className="ct-imam-speakers__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Imams
                </Button>
                <Button
                  variant={
                    activeButton === "prédicateur" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("prédicateur");
                  }}
                  color="primary"
                  className="ct-imam-speakers__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Prédicateurs
                </Button>
                <Button
                  variant={activeButton === "" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("");
                  }}
                  color="primary"
                  className="ct-imam-speakers__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  tout
                </Button>
              </>
            )}
          </div>
          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            <PerfectScrollbar id="scrollableDiv">
              <InfiniteScroll
                className="ct-imam-speakers__body-part"
                scrollableTarget="scrollableDiv"
                dataLength={fakeItems.length}
                next={fetchMoreData}
                hasMore={hasmore}
                loader={
                  fakeItems
                    .filter((value) => {
                      const fullname = value.name + value.firstname;
                      return (
                        fullname
                          .toLocaleLowerCase()
                          .indexOf(searchedText.toLocaleLowerCase()) !== -1
                      );
                    })
                    .filter((value) => {
                      return value.title.indexOf(activeButton) !== -1;
                    }).length > 0 ? (
                    <img
                      src={require("@/assets/images/gif/loadmore.gif")}
                      className="load-more"
                      alt="load-more"
                    />
                  ) : (
                    <NoData />
                  )
                }
              >
                {fakeItems
                  .filter((value) => {
                    const fullname = value.name + value.firstname;
                    return (
                      fullname
                        .toLocaleLowerCase()
                        .indexOf(searchedText.toLocaleLowerCase()) !== -1
                    );
                  })
                  .filter((value) => {
                    return value.title.indexOf(activeButton) !== -1;
                  })
                  .map((value, index) => {
                    return (
                      <ImamSpeakerCard
                        key={index}
                        name={value.name}
                        firstname={value.firstname}
                        title={value.title}
                        cursus={value.cursus}
                        reference={value.reference}
                        youtube={value.youtube}
                        facebook={value.facebook}
                        twitter={value.twitter}
                        instagram={value.instagram}
                        telegram={value.telegram}
                      />
                    );
                  })}
              </InfiniteScroll>
            </PerfectScrollbar>
          )}
        </div>
      )}
      {(userConnected as TUserConnected).account.changes_requests_rejected && (
        <CModalRejectedModif
          open={
            (userConnected as TUserConnected).account.changes_requests_rejected
          }
        />
      )}
      <CModalRemindPay
        open={
          typeof (userConnected as TUserConnected).account
            .reason_of_desactivation_by_admin === "string"
            ? true
            : !(userConnected as TUserConnected).account.is_free &&
              (!(userConnected as TUserConnected).account.is_active ||
                !(userConnected as TUserConnected).account
                  .is_subscription_active)
        }
        type={
          (userConnected as TUserConnected).account.is_subscription_expired
            ? "reabonnement"
            : "abonnement"
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectToken,
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const CtImamSpeakers = connector(CtImamSpeakersFct);

export default CtImamSpeakers;
