import React, { useEffect, useState } from "react";
import {
  selectToken,
  selectUserConnected,
} from "@/store/reducers/member/member.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import * as moment from "moment";
import "moment/locale/fr";
import { useFormik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./DesactivateSubscription.styles.scss";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { Toastify } from "@/utils/toast";
import {
  apiActivateAccount,
  apiActivateAccountPaypal,
  apiDesactivateAccount,
  apiGetSubscriptionPlan,
} from "@/features/profile/api/subscription.api";
import {
  TSubscriptionPlan,
  TUserConnected,
} from "@/features/profile/types/types";
import classNames from "classnames";
import { HighlightOffRounded } from "@mui/icons-material";
//import CmpStripe from './StripeComponent/Stripe.component';
import { store } from "@/store";
import { updateIsSubscriptionActive } from "@/store/reducers/member/member.actions";
import { useNavigate } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import { routePaths } from "@/config";

type PropsFromRedux = ConnectedProps<typeof connector>;

const DesactivateSubscriptionFct: React.FC<PropsFromRedux> = ({
  userConnected,
  token,
}) => {
  const navigate = useNavigate();

  //const [isDesactivate, // setIsDesactivate] = useState(true);
  const [isNew, setIsNew] = useState(true);
  // DESACTIVATE PART

  const [loading, setLoading] = useState(false);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const validationSchema = yup.object({
    reason_radio: yup
      .string()
      .required(" La raison est obligatoire, svp faites un choix "),
  });

  const formik = useFormik({
    initialValues: {
      reason_radio: "",
      reason_other: "",
      amelioration: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClickOpenLogout();
    },
  });

  const desactivateAccountSubmit = (data: any) => {
    const dataTosend = {
      reason: data.reason_radio,
      other_reason: data.reason_other,
      suggestion: data.amelioration,
    };
    console.log(dataTosend);
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    apiDesactivateAccount(dataTosend, token)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        store.dispatch(updateIsSubscriptionActive(false));
        initFormuleAbo();
        setIsNew(true);
        Toastify(`${response?.message}`, "success", 5000);
      })
      .catch((error: any) => {
        setLoading(false);

        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const desactivate = () => {
    // setIsDesactivate(true);
    //setIsNew(false);
    desactivateAccountSubmit(formik.values);
    handleCloseLogout();
  };

  // END of DESACTIVATE PART

  // REACTIVATE PART

  const [loadingReactivate, setLoadingReactivate] = useState(false);

  const [openLogoutReactivate, setOpenLogoutReactivate] = useState(false);
  const handleClickOpenLogoutReactivate = () => setOpenLogoutReactivate(true);
  const handleCloseLogoutReactivate = () => setOpenLogoutReactivate(false);

  const [loadingFormule, setLoadingFormule] = useState(false);
  const [formuleList, setFormuleList] = useState<TSubscriptionPlan[]>([]);

  const [openStripe, setOpenStripe] = useState(false);
  const handleOpenStripe = () => setOpenStripe(true);
  const handleCloseStripe = () => {
    setOpenStripe(false);
  };

  useEffect(() => {
    !(userConnected as TUserConnected).account.is_subscription_active &&
      initFormuleAbo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initFormuleAbo = () => {
    setLoadingFormule(true);

    apiGetSubscriptionPlan()
      .then((response: any) => {
        setLoadingFormule(false);
        //console.log(response);

        let tempList: TSubscriptionPlan[] = response.items.map((data: any) => {
          return {
            id: data.id,
            name: data.name,
            description: data.description,
            duration: data.duration,
            currency: data.currency,
            price_for_men: data.price_for_men,
            price_for_women: data.price_for_women,
          };
        });

        //console.log([...tempList]);
        setFormuleList([...tempList]);
      })
      .catch((error) => {
        setLoadingFormule(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const validationSchemaReactivate = yup.object({
    formule: yup
      .string()
      .required(" La formule est obligatoire, svp faites un choix "),
  });

  const formikReactivate = useFormik({
    initialValues: {
      formule: "",
    },
    validationSchema: validationSchemaReactivate,
    onSubmit: (values) => {
      handleClickOpenLogoutReactivate();
    },
  });

  const reactivateAccountSubmit = (data: any) => {
    console.log(data);

    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoadingReactivate(true);

    apiActivateAccount(token, data)
      .then((response: any) => {
        setLoadingReactivate(false);
        console.log(response);

        const redirect_url = response.redirect_url;

        const linkToStripe = document.createElement("a");

        linkToStripe.setAttribute("href", redirect_url);
        // linkToStripe.setAttribute('target','_blank');
        linkToStripe.click();

        linkToStripe.remove();
      })
      .catch((error: any) => {
        setLoadingReactivate(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const paypalInitPayment = (data: any) => {
    setLoadingReactivate(true);

    apiActivateAccountPaypal(token, data)
      .then((response: any) => {
        setLoadingReactivate(false);
        const redirect_url = response.redirect_url;
        const linkToPaypal = document.createElement("a");
        linkToPaypal.setAttribute("href", redirect_url);
        linkToPaypal.click();

        linkToPaypal.remove();
      })
      .catch((error: any) => {
        setLoadingReactivate(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  /* const reactivate = () => {
    false && 
    reactivateAccountSubmit(formikReactivate.values.formule);
    false && handleOpenStripe();
    handleCloseLogoutReactivate();
  } */

  const reactivate = () => {
    const duration = formuleList.find(
      (x) => x.id === formikReactivate.values.formule
    )?.duration;
    if (duration === "one-month") {
      reactivateAccountSubmit(formikReactivate.values.formule);
      handleCloseLogoutReactivate();
    } else {
      handleOpenStripe();
    }
  };

  // END of REACTIVATE PART

  return (
    <>
      {(userConnected as TUserConnected).account.is_subscription_active && (
        <div className="ct-desactivate-subscription-nav-part">
          <Button
            color="primary"
            className="nav-part__btn-nav"
            onClick={() => {
              navigate(routePaths.profileChildren.homeAbsolute, {
                replace: true,
              });
            }}
            startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
          >
            Aller à la liste des membres
          </Button>
        </div>
      )}
      <PerfectScrollbar className="ct-desactivate-subscription">
        {!(userConnected as TUserConnected).account.is_subscription_active && (
          <form
            className="desactivate-box"
            onSubmit={formikReactivate.handleSubmit}
          >
            <Box sx={{ width: "100%" }}>
              <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                  {isNew && (
                    <>
                      <p className="desactivate-title">
                        {(userConnected as TUserConnected).account.is_free
                          ? "COMPTE GRATUIT"
                          : "AUCUN ABONNEMENT"}
                      </p>
                    </>
                  )}
                  {!isNew && (
                    <>
                      <p className="desactivate-title">
                        Abonnement <b>désactivé</b>
                      </p>
                      <div className="recap-part">
                        <div className="text-part">
                          <h3> MON DERNIER ABONNEMENT </h3>
                          <p className="desactivate">
                            {" "}
                            Expiré le{" "}
                            <b>{moment.default(new Date()).format("L")}</b>{" "}
                          </p>
                        </div>
                        <div className="infos-part">
                          <p>
                            {" "}
                            Formule{" "}
                            {(userConnected as TUserConnected).infos.gender ===
                            "male"
                              ? "homme"
                              : "femme"}{" "}
                            - {"29,99€/an"}{" "}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} lg={12} mt={2}>
                  <p>
                    <b>
                      {" "}
                      Choisissez une formule ci-dessous pour vous{" "}
                      {isNew ? "abonner" : "réabonner"}:{" "}
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} justifyContent="center">
                  <FormControl
                    fullWidth
                    color="primary"
                    className="paiement-radio-row"
                  >
                    <RadioGroup
                      row
                      aria-labelledby="id-formule"
                      name="formule"
                      className="row-radio-buttons-group"
                      value={formikReactivate.values.formule}
                      onChange={formikReactivate.handleChange}
                    >
                      {loadingFormule ? (
                        <img
                          src={require("@/assets/images/gif/loadmore.gif")}
                          height="70"
                          width="70"
                          alt="load-more"
                        />
                      ) : (
                        formuleList.map((elt, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames("formule-card", {
                                active:
                                  formikReactivate.values.formule === elt.id,
                                error:
                                  formikReactivate.touched.formule &&
                                  Boolean(formikReactivate.errors.formule),
                              })}
                            >
                              <FormControlLabel
                                value={elt.id}
                                control={<Radio />}
                                label={elt.name}
                              />
                              <span>
                                {(userConnected as TUserConnected).infos
                                  .gender === "male"
                                  ? elt.price_for_men
                                  : elt.price_for_women}{" "}
                                €
                              </span>
                              <small>
                                {elt.duration === "one-month" && "par mois"}
                                {elt.duration === "three-months" &&
                                  "chaque 03 mois"}
                                {elt.duration === "six-months" &&
                                  "chaque 06 mois"}
                                {(elt.duration === "one-year" ||
                                  elt.duration === "twelve-months") &&
                                  "par an (12 mois)"}
                              </small>
                            </div>
                          );
                        })
                      )}
                    </RadioGroup>

                    <FormHelperText
                      error={
                        formikReactivate.touched.formule &&
                        Boolean(formikReactivate.errors.formule)
                      }
                    >
                      {formikReactivate.errors.formule}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <LoadingButton
                    disabled={!formikReactivate.isValid}
                    loading={loadingReactivate}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {isNew ? "s'abonner" : "se réabonner"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
        {(userConnected as TUserConnected).account.is_subscription_active && (
          <form className="desactivate-box" onSubmit={formik.handleSubmit}>
            <Box>
              <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="recap-part">
                    <div className="text-part">
                      <h3> MON ABONNEMENT EN COURS </h3>
                      <p>
                        {" "}
                        Depuis le &nbsp;
                        <b>
                          {moment
                            .default(
                              (userConnected as TUserConnected).subscriptions
                                ?.current.date_of_subscription
                            )
                            .format("L")}
                        </b>
                      </p>
                      <p>
                        {(userConnected as TUserConnected).subscriptions
                          ?.current.subscription_plan.duration === "one-month"
                          ? "Se renouvelle le"
                          : "Expire le"}
                        &nbsp;
                        <b>
                          {moment
                            .default(
                              (userConnected as TUserConnected).subscriptions
                                ?.current.current_period_end
                            )
                            .format("L")}
                        </b>
                      </p>
                    </div>
                    <div className="infos-part">
                      <p>
                        {(
                          userConnected as TUserConnected
                        ).subscriptions?.current.subscription_plan.name.toUpperCase()}
                        <br />
                        <b>
                          {" "}
                          {
                            (userConnected as TUserConnected).subscriptions
                              ?.current.subscription_plan.amount
                          }{" "}
                          € /
                        </b>
                        {(userConnected as TUserConnected).subscriptions
                          ?.current.subscription_plan.duration ===
                          "one-month" && "mois"}
                        {(userConnected as TUserConnected).subscriptions
                          ?.current.subscription_plan.duration ===
                          "three-months" && "trimestre"}
                        {(userConnected as TUserConnected).subscriptions
                          ?.current.subscription_plan.duration ===
                          "six-months" && "semestre"}
                        {(userConnected as TUserConnected).subscriptions
                          ?.current.subscription_plan.duration === "one-year" &&
                          "an (12 mois)"}
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} mt={2}>
                  <p>
                    <b>
                      {" "}
                      Pour quelle raison souhaitez-vous désactiver votre
                      abonnement ?{" "}
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl fullWidth color="primary">
                    <RadioGroup
                      row
                      aria-labelledby="id-civilité"
                      className="radio-input"
                      name="reason_radio"
                      value={formik.values.reason_radio}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="not-found-what-looking-for"
                        control={<Radio />}
                        label="Vous n'avez pas trouvé ce que vous recherchez"
                      />
                      <FormControlLabel
                        value="plateform-not-suitable"
                        control={<Radio />}
                        label="Le site ne vous convient pas"
                      />
                      <FormControlLabel
                        value="subscription-too-expensive"
                        control={<Radio />}
                        label="L'abonnement est trop cher"
                      />
                      <FormControlLabel
                        value="other-reason"
                        control={<Radio />}
                        label="Autre (préciser)"
                      />
                    </RadioGroup>
                    <FormHelperText
                      error={
                        formik.touched.reason_radio &&
                        Boolean(formik.errors.reason_radio)
                      }
                    >
                      {formik.errors.reason_radio}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {formik.values.reason_radio === "other-reason" && (
                  <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                      placeholder="Autre raison"
                      variant="outlined"
                      label="Autre raison"
                      name="reason_other"
                      onChange={formik.handleChange}
                      value={formik.values.reason_other}
                      type="text"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} lg={12} mt={2}>
                  <p>
                    <b>
                      {" "}
                      Avez-vous une critique constructive à faire afin
                      d'améliorer la plate-forme ?{" "}
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="text-area">
                    <textarea
                      className="text-area-input"
                      rows={4}
                      name="amelioration"
                      onChange={formik.handleChange}
                      value={formik.values.amelioration}
                    ></textarea>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <LoadingButton
                    disabled={!formik.isValid}
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Désactiver mon abonnement
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </PerfectScrollbar>

      {/* DESACTIVATE  DIALOG PART */}
      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Désactivation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attention vous êtes sur le point de{" "}
            <b>supprimer votre abonnement</b>. Il vous faut un abonnement en
            cours de validité pour profiter de la plate-forme, si vous
            poursuivez votre accès sera désactivé, nous vous conseillons
            d'attendre <b>2 jours</b>&nbsp; avant le prochain renouvellement
            pour désactiver votre abonnement, si vous souhaitez quitter la
            plate-forme !
            <br />
            <br />
            <p className="spe">
              {" "}
              Êtes-vous sûr(e) de vouloir désactiver votre abonnement maintenant
              ?{" "}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogout}>Annuler</Button>
          <Button onClick={desactivate} autoFocus>
            Désactiver
          </Button>
        </DialogActions>
      </Dialog>
      {/* Ennd of DESACTIVATE  DIALOG PART */}

      {/* REACTIVATE DIALOG PART   */}
      <Dialog
        open={openLogoutReactivate}
        onClose={handleCloseLogoutReactivate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isNew ? "Activation" : "Réactivation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>
              {" - "}
              {
                formuleList.find(
                  (x) => x.id === formikReactivate.values.formule
                )?.name
              }
              {" - "}
            </b>
            <br />
            <p style={{ fontWeight: "500" }}>
              {
                formuleList.find(
                  (x) => x.id === formikReactivate.values.formule
                )?.description
              }
            </p>
            <br />
            <p className="spe">
              Vous êtes sur le point {isNew ? "d'activer" : "de réactiver"}{" "}
              votre abonnement. Vous devrez payer le montant de &nbsp;
              <b>
                {(userConnected as TUserConnected).infos.gender === "male"
                  ? formuleList.find(
                      (x) => x.id === formikReactivate.values.formule
                    )?.price_for_men
                  : formuleList.find(
                      (x) => x.id === formikReactivate.values.formule
                    )?.price_for_women}{" "}
                €
              </b>{" "}
              valable pour une période de &nbsp;
              <b>
                {formuleList.find(
                  (x) => x.id === formikReactivate.values.formule
                )?.duration === "one-month" &&
                  "01 mois ( renouvelable automatiquement )"}
                {formuleList.find(
                  (x) => x.id === formikReactivate.values.formule
                )?.duration === "three-months" &&
                  "03 mois ( renouvelable automatiquement sauf via paypal )"}
                {formuleList.find(
                  (x) => x.id === formikReactivate.values.formule
                )?.duration === "six-months" &&
                  "06 mois ( renouvelable automatiquement sauf via paypal )"}
                {(formuleList.find(
                  (x) => x.id === formikReactivate.values.formule
                )?.duration === "twelve-months" ||
                  formuleList.find(
                    (x) => x.id === formikReactivate.values.formule
                  )?.duration === "one-year") &&
                  "01 an (12mois) ( renouvelable automatiquement sauf via paypal )"}
              </b>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutReactivate}>Annuler</Button>
          <Button onClick={reactivate} autoFocus>
            {isNew ? "activer" : "réactiver"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Ennd of REACTIVATE  DIALOG PART */}

      {/* STRIPE */}
      <Modal
        open={openStripe}
        onClose={handleCloseStripe}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div className="form-stripe-part">
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseStripe}
            >
              <HighlightOffRounded />
            </IconButton>

            <Button
              color="primary"
              className="nav-part__btn-nav"
              onClick={() => {
                reactivateAccountSubmit(formikReactivate.values.formule);
                handleCloseLogoutReactivate();
                handleCloseStripe();
              }}
            >
              Payer par carte bancaire&nbsp;
              <img
                src={require("@/assets/images/png/pay-by-cb.png")}
                alt="pay-by-cb"
                width={50}
              />
            </Button>
            <Button
              color="primary"
              className="nav-part__btn-nav"
              onClick={() => {
                paypalInitPayment(formikReactivate.values.formule);
                handleCloseLogoutReactivate();
                handleCloseStripe();
              }}
            >
              Payer avec paypal&nbsp;
              <img
                src={require("@/assets/images/png/paypal.png")}
                alt="paypal"
                width={50}
              />
            </Button>
          </div>
        </>
      </Modal>
      {/* End of STRIPE */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
});

const connector = connect(mapStateToProps);
const DesactivateSubscription = connector(DesactivateSubscriptionFct);

export default DesactivateSubscription;
