import { TProfileCard } from "@/features/profile/Elements/ProfileCard/ProfileCard";
import { InlineIcon } from "@iconify/react";
import {
  Box,
  LinearProgress,
  Grid,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import * as moment from "moment";
import classnames from "classnames";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import "./FullProfil.scss";
import { HighlightOffRounded } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  apiAskForExchange,
  apiGetFullProfil,
} from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import { TUserConnected } from "@/features/profile/types";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectEthnies } from "@/store/reducers/general_param/general_param.selector";
import { LoadingButton } from "@mui/lab";
import { apiAddToFavorite } from "@/features/profile/api/favorite.api";
import { apiAddToBlacklist } from "@/features/profile/api/blacklist.api";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import TextDisplayer from "../TextDisplayer/TextDisplayer";

type TFullProfil = TProfileCard & {
  closeModal: () => void;
  token: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const FullProfilFct: React.FC<TFullProfil & PropsFromRedux> = ({
  isVerified,
  isOnline,
  username,
  type,
  progressValue,
  profil_id,
  age,
  town,
  country,
  origine,
  nationalite,
  closeModal,
  date_of_creation,
  hasMore,
  token,
  userConnected,
  ethnies,
}) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const navigate = useNavigate();

  const [profilSelected, setProfilSelected] = useState<TUserConnected>(
    userConnected as TUserConnected
  );

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const tenue_vestimentaire_femmeArrayManager = (
    not_wear_headscarf: boolean,
    wear_turban: boolean,
    wear_hijab: boolean,
    wear_jilbab: boolean,
    wear_niqab: boolean,
    wear_abaya: boolean
  ): string[] => {
    let result: string[] = [];

    not_wear_headscarf && result.push("Ne porte pas encore le voile");
    wear_turban && result.push("Porte le turban");
    wear_hijab && result.push("Porte le Hijab");
    wear_jilbab && result.push("Porte le Jilbab");
    wear_niqab && result.push("Porte le Niqab");
    wear_abaya && result.push("L'Abaya");

    return result;
  };

  const goodNullBooleanValueReverse = (val: any): string => {
    switch (val) {
      case null:
        return "Pas encore répondu";
      case true:
        return "Oui";
      case false:
        return "Non";
      default:
        return "Pas encore répondu";
    }
  };

  useEffect(() => {
    setLoading(true);

    apiGetFullProfil(profil_id, token)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        let profilConnectedTemp = response;
        setProfilSelected(profilConnectedTemp);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
        closeModal();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToFavorite = () => {
    setLoading1(true);

    apiAddToFavorite(profil_id, token)
      .then((response: any) => {
        setLoading1(false);
        console.log(response);
        Toastify(`${username} a été rajouté à vos favoris`, "success", 5000);
      })
      .catch((error) => {
        setLoading1(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const addToBlacklist = () => {
    setLoading2(true);

    apiAddToBlacklist(profil_id, token)
      .then((response: any) => {
        setLoading2(false);
        console.log(response);
        Toastify(
          `${username} a été rajouté à votre liste noire`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading2(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });

    navigate(routePaths.profileChildren.blacklistAbsolute, { replace: true });
  };

  const startExchange = () => {
    setLoading3(true);

    apiAskForExchange(profil_id, token)
      .then((response: any) => {
        setLoading3(false);
        console.log(response);
        Toastify(
          ` votre demande d'échange a bien été envoyé à ${username}`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading3(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <img
            src={require("@/assets/images/png/logo-mini.png")}
            className="logo-mini"
            alt="load-profil"
          />
          <img
            src={require("@/assets/images/gif/loadmore.gif")}
            className="load-profil"
            alt="load-profil"
          />
          <small> Chargement du profil </small>
        </div>
      ) : (
        <div className="full-profile-container">
          <div className="full-profile-card">
            <IconButton
              className="close-icon"
              size="large"
              color="secondary"
              onClick={closeModal}
            >
              <HighlightOffRounded />
            </IconButton>

            <div
              className={classnames("card-header", {
                consulted: type === "consulted",
                un_consulted: type === "non consultés",
                followers: type === "Mes spectateurs",
              })}
            >
              {isVerified ||
              (profilSelected as TUserConnected).account!?.is_verified ? (
                <>
                  <div className="pastille">
                    <InlineIcon icon="humbleicons:verified" />
                  </div>
                </>
              ) : (
                <>
                  <div className="pastille ko">
                    <InlineIcon icon="ep:close-bold" />
                  </div>
                </>
              )}

              <Box sx={{ width: "100%", marginTop: "10px" }}>
                <LinearProgress
                  variant="determinate"
                  color={
                    (progressValue ||
                      (profilSelected as TUserConnected).rate_of_completion) ===
                    0
                      ? "inherit"
                      : "success"
                  }
                  value={
                    progressValue ||
                    (profilSelected as TUserConnected).rate_of_completion
                  }
                  sx={{ borderRadius: 5 }}
                />
                {(progressValue ||
                  (profilSelected as TUserConnected).rate_of_completion) ===
                0 ? (
                  <p className="profil-progress-text ko">
                    {" "}
                    Profil rempli à{" "}
                    <b>
                      {progressValue ||
                        (profilSelected as TUserConnected).rate_of_completion}
                      %
                    </b>{" "}
                  </p>
                ) : (
                  <p className="profil-progress-text ">
                    {" "}
                    Profil rempli à{" "}
                    <b>
                      {progressValue ||
                        (profilSelected as TUserConnected).rate_of_completion}
                      %
                    </b>{" "}
                  </p>
                )}
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <p className="profil-date-text">
                      {" "}
                      Inscrit depuis le{" "}
                      <b>
                        {moment
                          .default(
                            new Date(
                              date_of_creation! ||
                                (profilSelected as TUserConnected).infos
                                  .date_of_creation
                            )
                          )
                          .format("L")}
                      </b>{" "}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <p className="profil-id-text">
                      {" "}
                      ID: <b>{profil_id}</b>{" "}
                    </p>
                  </Grid>
                  {false && (
                    <Grid item xs={12} sm={12} lg={11}>
                      <p
                        style={{ textAlign: "center" }}
                        className="profil-date-text"
                      >
                        {" "}
                        Dernière connexion le{" "}
                        <b>
                          {moment
                            .default(new Date(date_of_creation!))
                            .format("L")}
                        </b>{" "}
                      </p>
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="profil-infos-name-age">
                      {isOnline ||
                      (profilSelected as TUserConnected).account.is_online ? (
                        <div className="dot"></div>
                      ) : (
                        <div className="dot offline"></div>
                      )}
                      <p className="profil-fullname">
                        {" "}
                        {username ||
                          (profilSelected as TUserConnected).infos
                            .username}{" "}
                      </p>
                      <p className="profil-age">
                        {" "}
                        {(profilSelected as TUserConnected).infos.age}
                        <small> ANS</small>{" "}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="tag-info-part">
                      <div className="tag">
                        <InlineIcon icon="akar-icons:location" />
                        <p>
                          {" "}
                          <b>
                            {(profilSelected as TUserConnected).contacts?.city
                              ? (profilSelected as TUserConnected).contacts
                                  ?.city
                              : "--"}
                          </b>{" "}
                          /{" "}
                          {(profilSelected as TUserConnected).contacts?.country
                            ? (profilSelected as TUserConnected).contacts
                                ?.country
                            : "--"}{" "}
                        </p>
                      </div>
                      <div className="tag">
                        <InlineIcon icon="el:globe" />
                        {origine ||
                        (profilSelected as TUserConnected).additional_infos
                          .profile.home_country ? (
                          <p>
                            {" "}
                            ORIGINE{" "}
                            <b>
                              {origine ||
                                (profilSelected as TUserConnected)
                                  .additional_infos.profile.home_country}
                            </b>{" "}
                          </p>
                        ) : (
                          <p> -- </p>
                        )}
                      </div>
                      <div className="tag">
                        <InlineIcon icon="icon-park-outline:passport" />
                        {nationalite ||
                        (profilSelected as TUserConnected).additional_infos
                          .profile.citizenship ? (
                          <p>
                            {" "}
                            NATIONALITÉ{" "}
                            <b>
                              {nationalite ||
                                (profilSelected as TUserConnected)
                                  .additional_infos.profile.citizenship}
                            </b>{" "}
                          </p>
                        ) : (
                          <p> -- </p>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>

            {/* FICHE PROFIL */}
            <Accordion expanded={expanded} onChange={handleChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="accordion-title-container">
                  <InlineIcon icon="carbon:user-profile-alt" />
                  <Typography> Profil </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="fiche-container">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Origine principale :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.home_country || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      {typeof (profilSelected as TUserConnected)
                        .additional_infos.profile.other_home_country ===
                        "string" && (
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                          <div className="item-infos">
                            <p className="field-text"> 2nd Origine :</p>
                            <p className="value-text">
                              {" "}
                              {(profilSelected as TUserConnected)
                                .additional_infos.profile.other_home_country ||
                                ""}{" "}
                            </p>
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Nationalité :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.citizenship || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      {typeof (profilSelected as TUserConnected)
                        .additional_infos.profile.other_citizenship ===
                        "string" && (
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                          <div className="item-infos">
                            <p className="field-text"> 2nd Nationalité :</p>
                            <p className="value-text">
                              {" "}
                              {(profilSelected as TUserConnected)
                                .additional_infos.profile.other_citizenship ||
                                ""}{" "}
                            </p>
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Ethnie :</p>
                          <p className="value-text">
                            {" "}
                            {ethnies.find(
                              (x) =>
                                x.id ===
                                (profilSelected as TUserConnected)
                                  .additional_infos.profile.ethnicity
                            )?.name || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Situation matrimoniale :
                          </p>
                          <p className="value-text">
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.marital_status === "never-married" &&
                              "Jamais Marié(e)"}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.marital_status === "divorced" &&
                              "Divorcé(e)"}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.marital_status === "widow" &&
                              "Veuf(Veuve)"}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.marital_status === "married" &&
                              "Marié(e)"}
                          </p>
                        </div>
                      </Grid>
                      {(profilSelected as TUserConnected).infos.gender ===
                        "male" && (
                        <>
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div className="item-infos">
                              <p className="field-text">
                                {" "}
                                Ai-je les moyens financiers pour un second foyer
                                ?
                              </p>
                              <p className="value-text">
                                {goodNullBooleanValueReverse(
                                  (profilSelected as TUserConnected)
                                    .additional_infos.profile.have_money
                                )}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div className="item-infos">
                              <p className="field-text">
                                {" "}
                                Ma famille sera-t'elle mise au courant avant le
                                mariage ?
                              </p>
                              <p className="value-text">
                                {goodNullBooleanValueReverse(
                                  (profilSelected as TUserConnected)
                                    .additional_infos.profile
                                    .is_family_know_before_marriage
                                )}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div className="item-infos">
                              <p className="field-text">
                                {" "}
                                Serai-je équitable dans le partage des nuits ?
                              </p>
                              <p className="value-text">
                                {goodNullBooleanValueReverse(
                                  (profilSelected as TUserConnected)
                                    .additional_infos.profile
                                    .fair_in_sharing_nights
                                )}
                              </p>
                            </div>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Ethnie(s) recherchée(s) :
                          </p>
                          <p className="value-text">
                            {(
                              profilSelected as TUserConnected
                            ).additional_infos.profile.ethnicities_wanted
                              .map((elt) => {
                                return ethnies.find((x) => x.id === elt)?.name;
                              })
                              .join(", ") || ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Lieu(x) ou vous êtes née et où vous avez grandi :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.childhood_place || ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Garçon(s) à charge :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.dependent_boys || 0}{" "}
                            Garçon(s)
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Fille(s) à charge :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.dependent_girls || 0}{" "}
                            Fille(s)
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Garde des enfants en cas de Hijra :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.hijra_childcare || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Nombre d'enfants acceptés :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.accepted_children || 0}{" "}
                            enfant(s)
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Profession :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .profile.occupation || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Description :</p>

                          <TextDisplayer
                            className="value-text"
                            rows={7}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.profile.description || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Profil recherché :</p>
                          <TextDisplayer
                            className="value-text"
                            rows={7}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.profile.profile_wanted || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Critères rédhibitoires :
                          </p>
                          <TextDisplayer
                            className="value-text"
                            rows={7}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.profile
                                .prohibitive_criteria || ""
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* End od PROFIL PART */}

            {/* FICHE RELIGION */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="accordion-title-container">
                  <InlineIcon icon="healthicons:mosque-outline" />
                  <Typography> Religion </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="fiche-container">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Salafi :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_aspects.is_salafi
                              ? "Oui"
                              : "Non" || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Tenue vestimentaire :</p>
                          {(profilSelected as TUserConnected).infos.gender ===
                            "male" && (
                            <p className="value-text">
                              {" "}
                              {(profilSelected as TUserConnected)
                                .additional_infos.religious_aspects
                                .male_dress_code || ""}{" "}
                            </p>
                          )}
                          {(profilSelected as TUserConnected).infos.gender ===
                            "female" && (
                            <p className="value-text">
                              {" "}
                              {tenue_vestimentaire_femmeArrayManager(
                                (
                                  profilSelected.additional_infos
                                    .religious_aspects as any
                                ).female_dress_code?.not_wear_headscarf,
                                (
                                  profilSelected.additional_infos
                                    .religious_aspects as any
                                ).female_dress_code?.wear_turban,
                                (
                                  profilSelected.additional_infos
                                    .religious_aspects as any
                                ).female_dress_code?.wear_hijab,
                                (
                                  profilSelected.additional_infos
                                    .religious_aspects as any
                                ).female_dress_code?.wear_jilbab,
                                (
                                  profilSelected.additional_infos
                                    .religious_aspects as any
                                ).female_dress_code?.wear_niqab,
                                (
                                  profilSelected.additional_infos
                                    .religious_aspects as any
                                ).female_dress_code?.wear_abaya
                              ).join(", ") || ""}{" "}
                            </p>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Les savants que je suis :
                          </p>
                          <TextDisplayer
                            className="value-text"
                            rows={7}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.religious_aspects
                                .favorite_scholars || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Les prédicateurs que j'écoute :
                          </p>
                          <TextDisplayer
                            className="value-text"
                            rows={7}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.religious_aspects
                                .favorite_preachers || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            École de jurisprudence :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_aspects.have_school_of_jurisprudence ||
                              ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Hijra:</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_aspects.is_in_hijra
                              ? "Oui"
                              : "Non" || ""}{" "}
                            ,{" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_aspects.hijra_country || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Je souhaite faire la hijra :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_aspects.when_to_make_hijra ||
                              "jamais"}{" "}
                          </p>
                        </div>
                      </Grid>
                      {(profilSelected as TUserConnected).infos.gender ===
                        "female" && (
                        <>
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div className="item-infos">
                              <p className="field-text">
                                {" "}
                                J'accepte les hommes mariés:
                              </p>
                              <p className="value-text">
                                {" "}
                                {(profilSelected as TUserConnected)
                                  .additional_infos.religious_aspects
                                  .accept_married_man
                                  ? "Oui"
                                  : "Non" || ""}{" "}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div className="item-infos">
                              <p className="field-text"> J'ai un tuteur:</p>
                              <p className="value-text">
                                {" "}
                                {(profilSelected as TUserConnected)
                                  .additional_infos.religious_aspects
                                  .have_mahram
                                  ? "Oui"
                                  : "Non" || ""}{" "}
                              </p>
                            </div>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            J'accepte de déménager :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_aspects.agree_to_move
                              ? "Oui"
                              : "Non" || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* End od RELIGION PART */}

            {/* FICHE PRATIQUE */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="accordion-title-container">
                  <InlineIcon icon="clarity:clipboard-line" />
                  <Typography> Pratique </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="fiche-container">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Les (05)cinq prières :</p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices.pray_five_prayers
                              ? "Oui"
                              : "Non" || ""}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Prière du vendredi à la mosquée :
                          </p>
                          <p className="value-text">
                            {" "}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices.pray_friday_mosque
                              ? "Oui"
                              : "Non" || ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Rapport à la mosquée:</p>
                          <TextDisplayer
                            className="value-text"
                            rows={4}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.religious_practices
                                .relationship_to_mosque || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Rapport à la lecture du Qour’an El Karim :
                          </p>
                          <TextDisplayer
                            className="value-text"
                            rows={4}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.religious_practices
                                .relationship_to_quran_reading || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Rapport à la langue arabe :
                          </p>
                          <TextDisplayer
                            className="value-text"
                            rows={4}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.religious_practices
                                .relationship_to_arabic_language || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text">
                            {" "}
                            Niveau d’instruction religieuse:
                          </p>
                          <p className="value-text">
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices
                              .level_of_religious_education === "none" &&
                              "Aucun"}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices
                              .level_of_religious_education === "beginner" &&
                              "Débutant(e)"}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices
                              .level_of_religious_education ===
                              "intermediate" && "Intermédiaire"}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices
                              .level_of_religious_education === "advanced" &&
                              "Avancé(e)"}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices
                              .level_of_religious_education === "senior" &&
                              "Confirmé(e))"}
                            {(profilSelected as TUserConnected).additional_infos
                              .religious_practices
                              .level_of_religious_education ===
                              "not_pronounced" && "Ne se prononce pas"}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* End od PRATIQUE PART */}

            {/* FICHE SANTE */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="accordion-title-container">
                  <InlineIcon icon="teenyicons:plant-outline" />
                  <Typography> Santé </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="fiche-container">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Santé physique :</p>
                          <TextDisplayer
                            className="value-text"
                            rows={4}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.health_and_disability
                                .physical_health || ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <div className="item-infos">
                          <p className="field-text"> Mal occulte :</p>
                          <TextDisplayer
                            className="value-text"
                            rows={4}
                            value={
                              (profilSelected as TUserConnected)
                                .additional_infos.health_and_disability
                                .occult_diseases || ""
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* End od SANTE PART */}

            <div className="footer-part">
              <LoadingButton
                loading={loading1}
                loadingPosition="center"
                color="primary"
                className="btn-footer-action"
                variant="contained"
                onClick={addToFavorite}
                startIcon={<InlineIcon icon="uiw:like-o" />}
              >
                Je suis intéressé(e)
              </LoadingButton>

              <LoadingButton
                loading={loading2}
                loadingPosition="center"
                color="primary"
                className="btn-footer-action"
                variant="contained"
                onClick={addToBlacklist}
                startIcon={<InlineIcon icon="bx:block" />}
              >
                Liste Noire
              </LoadingButton>

              <LoadingButton
                loading={loading3}
                loadingPosition="center"
                color="primary"
                className="btn-footer-action"
                variant="contained"
                onClick={startExchange}
                startIcon={
                  <InlineIcon icon="fluent-emoji-high-contrast:handshake" />
                }
              >
                Entrer en échange
              </LoadingButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  ethnies: selectEthnies,
});

const connector = connect(mapStateToProps);
const FullProfil = connector(FullProfilFct);

export default FullProfil;
