import React, { useEffect, useState } from "react";
import { InlineIcon } from "@iconify/react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "./Search.styles.scss";
import { Toastify } from "@/utils/toast";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiSearchProfile } from "../../api/profile.api";
import {
  selectUserConnected,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TFilteredQuery, TUserConnected } from "../../types";
import { MultipleSelectChip } from "@/components/MultipleSelectChip/MultipleSelectChip";
import { Loader } from "@/components/Loader/Loader";
import { store } from "@/store";
import { updateLocation } from "@/store/reducers/member/member.actions";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import { apiGetSearchEthniesAndOrigins } from "../../api/search.api";
import {
  updateFormQuery,
  updateSearchQuery,
} from "@/store/reducers/general_param/general_param.actions";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import { selectFormQuery } from "@/store/reducers/general_param/general_param.selector";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

type PropsFromRedux = ConnectedProps<typeof connector>;
type TCheckBoxList = Array<{ name: string; checked: boolean }> | [];

const CtSearchFct: React.FC<PropsFromRedux> = ({
  userConnected,
  token,
  form_query,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const navigate = useNavigate();

  const [originList, setOriginList] = React.useState<TCheckBoxList>([]);

  const handleOriginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempList = originList;

    if (event.target.name === "Peu importe") {
      tempList.map((et) => (et.checked = event.target.checked));
    } else {
      const exist = tempList.findIndex((elt) => elt.name === event.target.name);
      if (exist !== -1) tempList[exist].checked = event.target.checked;

      const anyone = tempList.findIndex((elt) => elt.name === "Peu importe");
      if (anyone !== -1) tempList[anyone].checked = false;
    }

    setOriginList([...tempList]);
  };

  const [ethniesList, setEthiesList] = React.useState<TCheckBoxList>([]);

  const handleEthniesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempList = ethniesList;

    if (event.target.name === "Peu importe") {
      tempList.map((et) => (et.checked = event.target.checked));
    } else {
      const exist = tempList.findIndex((elt) => elt.name === event.target.name);
      if (exist !== -1) tempList[exist].checked = event.target.checked;

      const anyone = tempList.findIndex((elt) => elt.name === "Peu importe");
      if (anyone !== -1) tempList[anyone].checked = false;
    }

    setEthiesList([...tempList]);
  };

  const [countriesResidenceList, setCountriesResidenceList] =
    React.useState<TCheckBoxList>([]);

  const handleCountriesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tempList = countriesResidenceList;

    if (event.target.name === "Peu importe") {
      tempList.map((et) => (et.checked = event.target.checked));
    } else {
      const exist = tempList.findIndex((elt) => elt.name === event.target.name);
      if (exist !== -1) tempList[exist].checked = event.target.checked;

      const anyone = tempList.findIndex((elt) => elt.name === "Peu importe");
      if (anyone !== -1) tempList[anyone].checked = false;
    }

    setCountriesResidenceList([...tempList]);
  };

  const tenues_vestimentaires_femmes_list = [
    "Ne porte pas encore le voile",
    "Porte le turban",
    "Porte le Hijab",
    "Porte le Jilbab",
    "Porte le Niqab",
    "L'Abaya",
  ];

  const situation_matrimoniale_femme_list = [
    "Jamais Mariée",
    "Divorcée",
    "Veuve",
  ];

  const situation_matrimoniale_homme_list = [
    "Jamais Marié",
    "Divorcé",
    "Veuf",
    "Marié",
  ];

  const validationSchema = yup.object({
    age_minimum: yup
      .number()
      .min(18, " l'âge minimum est de 18 ans")
      .max(70, " l'âge maximum est de 70 ans")
      .test({
        name: "min_over_max",
        message: " l'âge minimum doit être inférieure à l'âge maximum ",
        test: function (value, ctx) {
          let result = false;
          if (value) {
            if (ctx.parent.age_maximum) {
              result = value < ctx.parent.age_maximum;
            } else {
              result = value < 70;
            }
          } else {
            result = true;
          }
          return result;
        },
      }),
    age_maximum: yup
      .number()
      .min(18, " l'âge minimum est de 18 ans")
      .max(70, " l'âge maximum est de 70 ans")
      .test({
        name: "max_over_min",
        message: " l'âge maximum doit être supérieure à l'âge minimum ",
        test: function (value, ctx) {
          let result = false;
          if (value) {
            if (ctx.parent.age_minimum) {
              result = value > ctx.parent.age_minimum;
            } else {
              result = true;
            }
          } else {
            result = true;
          }
          return result;
        },
      }),
    distance_parcouru: yup
      .number()
      .min(0, "la distance parcouru doit être positive"),
  });

  const formik = useFormik({
    initialValues: {
      age_minimum: form_query.age_minimum,
      age_maximum: form_query.age_maximum,
      tenue_vestimentaire_femme: form_query.tenue_vestimentaire_femme,
      //tenue_vestimentaire_homme: '',
      //distance_parcouru: '',
      situation_matrimoniale: form_query.situation_matrimoniale,
      situation_parentale: form_query.situation_parentale,
      accepte_enfants: form_query.accepte_enfants,
      accepte_married_man: form_query.accepte_married_man,
      profils_salafi: form_query.profils_salafi,
      accepte_demenager: form_query.accepte_demenager,
      etant_hijra: form_query.etant_hijra,
      souhait_hijra: form_query.souhait_hijra,
      recherche_mon_ethnie: form_query.recherche_mon_ethnie,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dataToSend = {
        ...values,
        tenue_vestimentaire_femme: setArrayToGoodValue(
          values.tenue_vestimentaire_femme
        ),
        situation_matrimoniale: setArrayToGoodValue(
          values.situation_matrimoniale
        ),
        originList: cleanList(originList),
        ethniesList: cleanList(ethniesList),
        countriesResidence: cleanList(countriesResidenceList),
      };

      store.dispatch(
        updateFormQuery({
          ...values,
          originList: dataToSend.originList,
          ethniesList: dataToSend.ethniesList,
          countriesResidence: dataToSend.countriesResidence,
        } as TFilteredQuery)
      );

      const queryToSend = buildQueryString(dataToSend);
      console.log("helloooooo:::::::", queryToSend);

      launchSearchRequest(queryToSend);
    },
    onReset: (values) => {
      let initValues = {
        age_minimum: "",
        age_maximum: "",
        tenue_vestimentaire_femme: [],
        //tenue_vestimentaire_homme: '',
        //distance_parcouru: '',
        situation_matrimoniale: [],
        situation_parentale: "",
        accepte_enfants: "",
        accepte_married_man: "",
        profils_salafi: "",
        accepte_demenager: "",
        etant_hijra: "",
        souhait_hijra: "",
        recherche_mon_ethnie: "",
        originList: [],
        ethniesList: [],
        countriesResidence: [],
      };

      originList.map((et) => {
        return {
          ...et,
          checked: false,
        };
      });
      ethniesList.map((et) => {
        return {
          ...et,
          checked: false,
        };
      });

      countriesResidenceList.map((et) => {
        return {
          ...et,
          checked: false,
        };
      });

      store.dispatch(updateFormQuery(initValues as TFilteredQuery));
      store.dispatch(updateSearchQuery(""));

      Toastify(
        `Le filtre de recherche a bien été réinitialisé `,
        "success",
        5000
      );
      window.location.reload();
    },
  });

  const buildQueryString = (values: any): string => {
    let result = "";

    if (values.age_minimum !== "")
      result = result.concat("&min_age=", values.age_minimum);

    if (values.age_maximum !== "")
      result = result.concat("&max_age=", values.age_maximum);

    if (values.tenue_vestimentaire_femme.length > 0)
      result = result.concat(
        "&dress_code=",
        values.tenue_vestimentaire_femme.join(",")
      );

    if (values.situation_matrimoniale.length > 0)
      result = result.concat(
        "&marital_status=",
        values.situation_matrimoniale.join(",")
      );

    if (values.situation_parentale !== "")
      result = result.concat("&have_children=", values.situation_parentale);

    if (values.accepte_enfants !== "")
      result = result.concat("&accept_children=", values.accepte_enfants);

    if (values.accepte_married_man !== "")
      result = result.concat(
        "&accepte_married_man=",
        values.accepte_married_man
      );

    if (values.profils_salafi !== "")
      result = result.concat("&is_salafi=", values.profils_salafi);

    if (values.accepte_demenager !== "")
      result = result.concat("&agree_to_move=", values.accepte_demenager);

    if (values.etant_hijra !== "")
      result = result.concat("&is_in_hijra=", values.etant_hijra);

    if (values.souhait_hijra !== "")
      result = result.concat("&like_to_make_hijra=", values.souhait_hijra);

    if (values.recherche_mon_ethnie !== "")
      result = result.concat("&ethnicity_wanted=", values.recherche_mon_ethnie);

    if (values.ethniesList.length > 0)
      result = result.concat(
        "&ethnicities=",
        customArrayToString(values.ethniesList, true)
      );

    if (values.originList.length > 0)
      result = result.concat(
        "&countries=",
        customArrayToString(values.originList, false)
      );

    if (values.countriesResidence.length > 0) {
      result = result.concat(
        "&countriesResidence=",
        customArrayToString(values.countriesResidence, false)
      );
    }

    return result.substring(1);
  };

  const launchSearchRequest = (data: any) => {
    false && setLoading(true);
    false &&
      apiSearchProfile(token, 1, 6, data)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          Toastify(`Votre recherche a donnée des résultats`, "success", 5000);
        })
        .catch((error) => {
          setLoading(false);
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    store.dispatch(updateSearchQuery(data));
    navigate(routePaths.profileChildren.homeAbsolute, { replace: true });
  };

  const cleanList = (data: TCheckBoxList): TCheckBoxList => {
    let result: TCheckBoxList = [];
    result = data.filter((item) => {
      return item.checked === true && item.name !== "Peu importe";
    });
    return result;
  };

  const setArrayToGoodValue = (arr: string[]): string[] => {
    let result: string[] = [];

    arr.forEach((elt) => {
      // Tenue vestimentaire
      if (elt === "Ne porte pas encore le voile")
        result.push("not_wear_headscarf");
      if (elt === "Porte le turban") result.push("wear_turban");
      if (elt === "Porte le Hijab") result.push("wear_hijab");
      if (elt === "Porte le Jilbab") result.push("wear_jilbab");
      if (elt === "Porte le Niqab") result.push("wear_niqab");
      if (elt === "L'Abaya") result.push("wear_abaya");

      // Situation maritale
      if (elt === "Jamais Mariée" || elt === "Jamais Marié")
        result.push("never-married");
      if (elt === "Divorcée" || elt === "Divorcé") result.push("divorced");
      if (elt === "Veuve" || elt === "Veuf") result.push("widow");
      if (elt === "Marié") result.push("married");
    });

    return result;
  };

  const customArrayToString = (arr: any[], isId: boolean = false): string => {
    let result: string[] = [];

    if (isId) {
      arr.forEach((elt) => {
        result.push(elt.id);
      });
    } else {
      arr.forEach((elt) => {
        result.push(elt.name);
      });
    }

    return result.join(",");
  };

  useEffect(() => {
    !(userConnected as TUserConnected).account.is_active &&
      navigator.geolocation.getCurrentPosition((position) => {
        var location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        console.log(location);
        store.dispatch(updateLocation(location));
      });

    setLoadingList(true);

    apiGetSearchEthniesAndOrigins(token)
      .then((response: any) => {
        setLoadingList(false);
        console.log(response);

        let tempList = response.home_countries.items.map((data: any) => {
          return {
            name: data,
            checked:
              form_query.originList.find((x) => x.name === data) === undefined
                ? false
                : true,
          };
        });
        setOriginList([{ name: "Peu importe", checked: false }, ...tempList]);

        let tempList2 = response.ethnicities.items.map((data: any) => {
          return {
            id: data.id,
            name: data.name,
            checked:
              form_query.ethniesList.find((x) => x.name === data.name) ===
              undefined
                ? false
                : true,
          };
        });

        setEthiesList([{ name: "Peu importe", checked: false }, ...tempList2]);

        if (!form_query.countriesResidence) {
          store.dispatch(
            updateFormQuery({ ...form_query, countriesResidence: [] })
          );
        }

        let tempList3 = response.countries_of_residence.items.map(
          (data: any) => {
            return {
              name: data,
              checked:
                form_query?.countriesResidence?.find(
                  (x: any) => x.name === data
                ) === undefined
                  ? false
                  : true,
            };
          }
        );

        setCountriesResidenceList([
          { name: "Peu importe", checked: false },
          ...tempList3,
        ]);
      })
      .catch((error) => {
        setLoadingList(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(typeof (userConnected as TUserConnected).account
        .reason_of_desactivation_by_admin === "string"
        ? false
        : (userConnected as TUserConnected).account.is_free ||
          ((userConnected as TUserConnected).account.is_active &&
            (userConnected as TUserConnected).account
              .is_subscription_active)) && (
        <div className="ct-search">
          <div className="ct-search__nav-part">
            <Button
              variant="contained"
              color="tertiary"
              className="ct-search__nav-part__btn-nav"
              onClick={handleOpenAlert}
              startIcon={<InlineIcon icon="clarity:warning-line" />}
            >
              Bon à savoir
            </Button>
          </div>
          <div className="ct-search__body-part">
            <Box sx={{ width: "100%" }}>
              <div className="ct-search__body-part__card">
                <form
                  className="form-part"
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}
                >
                  <Box>
                    <Grid container rowSpacing={4} columnSpacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <Collapse in={openAlert}>
                          <Alert
                            severity="warning"
                            className="ct-search__body-part__alert"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleCloseAlert}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            <AlertTitle>Informations utiles</AlertTitle>
                            EN LAISSANT CES CHAMPS VIDES, TOUS LES PROFILS DE{" "}
                            <strong>ZAWAJ SOUNNAH</strong> SERONT
                            <br />
                            SÉLECTIONNÉ PAR DÉFAUT.
                          </Alert>
                        </Collapse>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <TextField
                          placeholder="Âge minimum"
                          variant="outlined"
                          label="Âge minimum (18) "
                          name="age_minimum"
                          onChange={formik.handleChange}
                          value={formik.values.age_minimum}
                          error={
                            formik.touched.age_minimum &&
                            Boolean(formik.errors.age_minimum)
                          }
                          helperText={
                            formik.touched.age_minimum &&
                            formik.errors.age_minimum
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span className="suffix"> ans </span>
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <TextField
                          placeholder="Âge maximum"
                          variant="outlined"
                          label="Âge maximum (70) "
                          name="age_maximum"
                          onChange={formik.handleChange}
                          value={formik.values.age_maximum}
                          error={
                            formik.touched.age_maximum &&
                            Boolean(formik.errors.age_maximum)
                          }
                          helperText={
                            formik.touched.age_maximum &&
                            formik.errors.age_maximum
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span className="suffix"> ans </span>
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                        />
                      </Grid>

                      {(userConnected as TUserConnected).infos.gender ===
                        "male" && (
                        <>
                          <Grid item xs={12} sm={12} lg={12}>
                            <MultipleSelectChip
                              name="tenue_vestimentaire_femme"
                              selectOptions={tenues_vestimentaires_femmes_list}
                              value={formik.values.tenue_vestimentaire_femme}
                              onChange={formik.handleChange}
                              labelId="id-select-tenue-femme"
                              label="Votre tenue vestimentaire"
                              errorText={
                                formik.errors.tenue_vestimentaire_femme
                              }
                              error={
                                formik.touched.tenue_vestimentaire_femme &&
                                Boolean(formik.errors.tenue_vestimentaire_femme)
                              }
                            />
                          </Grid>
                        </>
                      )}

                      {false && (
                        <Grid item xs={12} sm={12} lg={6}>
                          {/* <TextField placeholder="Partout"
                            variant="outlined"
                            label=" Distance de recherche en Km (par défault partout) "
                            name="distance_parcouru"
                            onChange={formik.handleChange}
                            value={formik.values.distance_parcouru}
                            error={formik.touched.distance_parcouru && Boolean(formik.errors.distance_parcouru)}
                            helperText={formik.touched.distance_parcouru && formik.errors.distance_parcouru}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span className="suffix"> Km </span>
                                </InputAdornment>
                              )
                            }}
                            type="number" /> */}
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} lg={12}>
                        {(userConnected as TUserConnected).infos.gender ===
                          "female" && (
                          <>
                            <MultipleSelectChip
                              name="situation_matrimoniale"
                              selectOptions={situation_matrimoniale_homme_list}
                              value={formik.values.situation_matrimoniale}
                              onChange={formik.handleChange}
                              labelId="id-situation_matrimoniale"
                              label="Situation matrimoniale"
                              errorText={formik.errors.situation_matrimoniale}
                              error={
                                formik.touched.situation_matrimoniale &&
                                Boolean(formik.errors.situation_matrimoniale)
                              }
                            />
                          </>
                        )}
                        {(userConnected as TUserConnected).infos.gender ===
                          "male" && (
                          <>
                            <MultipleSelectChip
                              name="situation_matrimoniale"
                              selectOptions={situation_matrimoniale_femme_list}
                              value={formik.values.situation_matrimoniale}
                              onChange={formik.handleChange}
                              labelId="id-situation_matrimoniale"
                              label="Situation matrimoniale"
                              errorText={formik.errors.situation_matrimoniale}
                              error={
                                formik.touched.situation_matrimoniale &&
                                Boolean(formik.errors.situation_matrimoniale)
                              }
                            />
                          </>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} lg={12}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            Situation parentale
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="situation_parentale"
                            value={formik.values.situation_parentale}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Avec enfants"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Sans enfants"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {(userConnected as TUserConnected).infos.gender ===
                        "male" && (
                        <>
                          <Grid item xs={12} sm={12} lg={12}>
                            <FormControl
                              fullWidth
                              color="primary"
                              className="yel-radio-row"
                            >
                              <FormLabel
                                id="my-label-radio"
                                className="my-label-radio"
                              >
                                Qui accepte les hommes mariés ?{" "}
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="my-label-radio"
                                className="my-row-radio-buttons-group"
                                name="accepte_married_man"
                                value={formik.values.accepte_married_man}
                                onChange={formik.handleChange}
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio />}
                                  label="Oui"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<Radio />}
                                  label="Non"
                                />
                                <FormControlLabel
                                  value="any"
                                  control={<Radio />}
                                  label="Peu importe"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            Qui accepte les enfants ?{" "}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="accepte_enfants"
                            value={formik.values.accepte_enfants}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            N'afficher que les profils salafis
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="profils_salafi"
                            value={formik.values.profils_salafi}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            {" "}
                            Qui accepte de déménager ?{" "}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="accepte_demenager"
                            value={formik.values.accepte_demenager}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            Étant en hijra ?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="etant_hijra"
                            value={formik.values.etant_hijra}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            Souhaitant faire la hijra ?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="souhait_hijra"
                            value={formik.values.souhait_hijra}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="yel-radio-row"
                        >
                          <FormLabel
                            id="my-label-radio"
                            className="my-label-radio"
                          >
                            Afficher les profils qui recherchent mon ethnie
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            className="my-row-radio-buttons-group"
                            name="recherche_mon_ethnie"
                            value={formik.values.recherche_mon_ethnie}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl className="yel-checkbox-list">
                          <FormLabel component="legend">ORIGINES</FormLabel>
                          <FormGroup className="yel-checkbox-option-container">
                            {loadingList ? (
                              <img
                                src={require("@/assets/images/gif/loadmore.gif")}
                                height="70"
                                width="70"
                                alt="load-more"
                              />
                            ) : (
                              originList.map((origin, index) => {
                                return (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        key={index}
                                        color="secondary"
                                        checked={origin.checked}
                                        onChange={handleOriginChange}
                                        name={origin.name}
                                      />
                                    }
                                    label={origin.name}
                                  />
                                );
                              })
                            )}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl className="yel-checkbox-list">
                          <FormLabel component="legend">ETHNIE</FormLabel>
                          <FormGroup className="yel-checkbox-option-container">
                            {loadingList ? (
                              <img
                                src={require("@/assets/images/gif/loadmore.gif")}
                                height="70"
                                width="70"
                                alt="load-more"
                              />
                            ) : (
                              ethniesList.map((ethny, index) => {
                                return (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        key={index}
                                        color="secondary"
                                        checked={ethny.checked}
                                        onChange={handleEthniesChange}
                                        name={ethny.name}
                                      />
                                    }
                                    label={ethny.name}
                                  />
                                );
                              })
                            )}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <FormControl className="yel-checkbox-list">
                          <FormLabel component="legend">
                            Pays de résidence
                          </FormLabel>
                          <FormGroup className="yel-checkbox-option-container">
                            {loadingList ? (
                              <img
                                src={require("@/assets/images/gif/loadmore.gif")}
                                height="70"
                                width="70"
                                alt="load-more"
                              />
                            ) : (
                              countriesResidenceList.map((country, index) => {
                                return (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        key={index}
                                        color="secondary"
                                        checked={country.checked}
                                        onChange={handleCountriesChange}
                                        name={country.name}
                                      />
                                    }
                                    label={country.name}
                                  />
                                );
                              })
                            )}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container rowSpacing={4} columnSpacing={2}>
                      <Grid item xs={12} sm={12} lg={8}></Grid>
                      <Grid item xs={12} sm={12} lg={2}>
                        <Button
                          variant="contained"
                          color="tertiary"
                          type="reset"
                          sx={{ marginTop: "30px" }}
                        >
                          EFFACER
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={2}>
                        <LoadingButton
                          loading={loading}
                          loadingPosition="center"
                          variant="contained"
                          color="primary"
                          sx={{ marginTop: "30px" }}
                          type="submit"
                        >
                          VALIDER
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </div>
            </Box>
          </div>
        </div>
      )}
      <Loader isLoading={loading} loadingMessage="Recherche en cours" />
      {(userConnected as TUserConnected).account.changes_requests_rejected && (
        <CModalRejectedModif
          open={
            (userConnected as TUserConnected).account.changes_requests_rejected
          }
        />
      )}
      <CModalRemindPay
        open={
          typeof (userConnected as TUserConnected).account
            .reason_of_desactivation_by_admin === "string"
            ? true
            : !(userConnected as TUserConnected).account.is_free &&
              (!(userConnected as TUserConnected).account.is_active ||
                !(userConnected as TUserConnected).account
                  .is_subscription_active)
        }
        type={
          (userConnected as TUserConnected).account.is_subscription_expired
            ? "reabonnement"
            : "abonnement"
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken,
  form_query: selectFormQuery,
});

const connector = connect(mapStateToProps);
const CtSearch = connector(CtSearchFct);

export default CtSearch;
