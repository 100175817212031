import { Box, Grid, Button, Typography, FormControlLabel, FormGroup, Switch, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, {useState} from 'react';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { Toastify } from '@/utils/toast';
import * as yup from 'yup';
import './Pratiques.step.styles.scss';
import { selectUserConnected, selectToken } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { apiSavePratique } from '@/features/profile/api/profile.api';
import { store } from '@/store';
import { updateIsApproved, updateNewModificationSubmit, updateProgress, updateReligiousPractices } from '@/store/reducers/member/member.actions';
import { TAdditionnalInfosReligiousPractices, TUserConnected } from '@/features/profile/types';
import { checkTrueOrFalse } from '../../../utils/account.utils';



type PropsFromElement = {
  chooseStep : React.Dispatch<React.SetStateAction<number>>,
  scrollTop: () => void
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const PratiquesStepFct: React.FC<PropsFromRedux & PropsFromElement> = ({
  userConnected,
  token,
  chooseStep,
  scrollTop
}) => {


  const [loading, setLoading] = useState(false);


  const validationSchema = yup.object({
    rapport_mosquee: yup.string(),
      
  });

  const formik = useFormik({
    initialValues: {
      cinq_prieres: checkTrueOrFalse((userConnected as TUserConnected).additional_infos.religious_practices.pray_five_prayers),
      vendredi_mosquee: checkTrueOrFalse((userConnected as TUserConnected).additional_infos.religious_practices.pray_friday_mosque),
      rapport_mosquee: (userConnected as TUserConnected).additional_infos.religious_practices.relationship_to_mosque || '',
      rapport_lecture: (userConnected as TUserConnected).additional_infos.religious_practices.relationship_to_quran_reading || '',
      rapport_arabe: (userConnected as TUserConnected).additional_infos.religious_practices.relationship_to_arabic_language || '',
      niveau_religieux: (userConnected as TUserConnected).additional_infos.religious_practices.level_of_religious_education || '',
      
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);


      const dataToSend = {
        pray_five_prayers : values.cinq_prieres,
        pray_friday_mosque: values.vendredi_mosquee,
        relationship_to_mosque: values.rapport_mosquee,
        relationship_to_quran_reading: values.rapport_lecture,
        relationship_to_arabic_language: values.rapport_arabe,
        level_of_religious_education: values.niveau_religieux,
      }
      console.log(dataToSend);
      pratiqueStepSubmit(dataToSend);
    }
  });

  const pratiqueStepSubmit = (data:any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
      setLoading(true);

      apiSavePratique(data,token).then((response:any)=> {
        setLoading(false);
        console.log(response);

        chooseStep(3);
        scrollTop();

        if( !(response.code === 'aucune_information_fournie') ){
          store.dispatch( updateProgress(response.rate_of_completion) );
          store.dispatch( updateReligiousPractices((response.payload as TAdditionnalInfosReligiousPractices)) );

          Toastify(`Les informations de vos pratiques réligieuses ont bien été soumises à notre équipe de modération`, 'success', 5000);
          store.dispatch( updateIsApproved(false) );
          store.dispatch( updateNewModificationSubmit(true) );
        }


      }).catch((error:any)=> {
        setLoading(false);

        
        Toastify(`${error?.response?.data?.message}`, 'error', 5000);
      })

  }

  const reset = () => {
    formik.setValues({
      cinq_prieres: false,
      vendredi_mosquee: false,
      rapport_mosquee: '',
      rapport_lecture: '',
      rapport_arabe: '',
      niveau_religieux: '',
    });
  }



  return (

      <form className="form-part-pratiques"  onSubmit={formik.handleSubmit}>
        <Box sx={{width:'100%'}} >
          <Grid container rowSpacing={4} columnSpacing={2} >

              <Grid item xs={12} sm={12} lg={12} xl={6}>
                  <div className="switch-container">                  
                      <Typography> Priez-vous les 5 prières ? </Typography>
                      <FormGroup>
                            <FormControlLabel 
                                label={formik.values.cinq_prieres ? "Oui" : "Non"}
                                control={<Switch  name="cinq_prieres"
                                                  checked={formik.values.cinq_prieres}
                                                  onChange={formik.handleChange}  />}  
                            />
                      </FormGroup>
                  </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={6}>
                  <div className="switch-container">                  
                      <Typography> Priez-vous le vendredi à la mosquée ? </Typography>
                      <FormGroup>
                            <FormControlLabel 
                                label={formik.values.vendredi_mosquee ? "Oui" : "Non"}
                                control={<Switch  name="vendredi_mosquee"
                                                  checked={formik.values.vendredi_mosquee}
                                                  onChange={formik.handleChange}  />}  
                            />
                      </FormGroup>
                  </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                  <div className="text-area">
                      <Typography className="text-area-label"> Votre rapport à la mosquée</Typography>
                      <textarea 
                              className="text-area-input"
                              rows={4}
                              name="rapport_mosquee"
                              onChange={formik.handleChange}
                              value={formik.values.rapport_mosquee}>
                      </textarea>
                  </div>
              </Grid>


              <Grid item xs={12} sm={12} lg={12}>
                  <div className="text-area">
                      <Typography className="text-area-label"> Votre rapport à la lecture du Qour’an El Karim </Typography>
                      <textarea 
                              className="text-area-input"
                              rows={4}
                              name="rapport_lecture"
                              onChange={formik.handleChange}
                              value={formik.values.rapport_lecture}>
                      </textarea>
                  </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                  <div className="text-area">
                      <Typography className="text-area-label"> Votre rapport à la langue arabe </Typography>
                      <textarea 
                              className="text-area-input"
                              rows={4}
                              name="rapport_arabe"
                              onChange={formik.handleChange}
                              value={formik.values.rapport_arabe}>
                      </textarea>
                  </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={12} >
                  <FormControl fullWidth color='primary' className='niveau-radio-row' >
                        <FormLabel id="id-niveau">Votre niveau d’instruction religieuse</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="id-niveau"
                          className="row-radio-buttons-group"
                          name="niveau_religieux"
                          value={formik.values.niveau_religieux}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel value="none"  control={<Radio />} label="Aucun" />
                          <FormControlLabel value="beginner"   control={<Radio />} label="Débutant(e)" />
                          <FormControlLabel value="intermediate"   control={<Radio />} label="Intermédiaire" />
                          <FormControlLabel value="advanced"  control={<Radio />} label="Avancé(e)" />
                          <FormControlLabel value="senior"   control={<Radio />} label="Confirmé(e)" />
                          <FormControlLabel value="not_pronounced"   control={<Radio />} label="Ne se prononce pas" />
                        </RadioGroup>
                  </FormControl>
              </Grid>

          </Grid>
        </Box>

        <Grid container 
              rowSpacing={4} 
              columnSpacing={2}
              sx={{justifyContent:'flex-end',marginTop:'30px'}}  >

            <Grid item xs={12} sm={12} lg={3} xl={2}>
                <Button 
                    variant="outlined" 
                    color='tertiary'
                    onClick={()=>{
                      reset();
                    }}
                    > Effacer
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} lg={5} xl={5}>
                <LoadingButton 
                    loading={loading}
                    loadingPosition="center"
                    variant="contained" 
                    color='primary'
                    type="submit"
                    > Valider et passer à santé et handicap
                </LoadingButton>
            </Grid>
        </Grid>



      </form>
  )
}

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken
});


const connector = connect(mapStateToProps);
const PratiquesStep = connector(PratiquesStepFct);

export default PratiquesStep;
