import React, { useState } from "react";
import { routePaths } from "@/config";
import { Toastify } from "@/utils/toast";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { apiLoginMember } from "../../api/auth.api";
import { useFormik } from "formik";
import { TSignIn } from "../../types";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import "./SigninForm.styles.scss";
import { useAppDispatch } from "@/store";
import {
  getToken,
  getUserConnected,
  updateLocation,
  updateProgress,
} from "@/store/reducers/member/member.actions";
import { TUserConnected } from "@/features/profile/types";
import { selectEmail } from "@/store/reducers/temp_data/temp_data.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CModalBanned from "@/components/Modal-Banned/ModalBanned";

type PropsFromRedux = ConnectedProps<typeof connector>;
const SigninFormFct: React.FC<PropsFromRedux> = ({ email_from_redux }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userConnected, setUserConnected] = useState<TUserConnected | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const dispatch = useAppDispatch();

  const validationSchema = yup.object({
    identifier: yup
      .string()
      .required(" l'email ou l'indentifiant est obligatoire "),
    password: yup.string().required("le mot de passe est obligatoire"),
    // .min(5, 'Votre mot de passe doit contenir au moins 5 caratères')
  });

  const formik = useFormik({
    initialValues: {
      identifier: email_from_redux || "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loginSubmit(values);
    },
  });

  const loginSubmit = (data: TSignIn) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    data.identifier = data.identifier.trim();

    apiLoginMember(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);

        let tempUserConnected = response.data;
        let token = response.token;
        let progress = response.data.rate_of_completion;
        dispatch(getUserConnected(tempUserConnected as TUserConnected));
        setUserConnected(tempUserConnected as TUserConnected);
        dispatch(getToken(token));
        dispatch(updateProgress(progress));

        if (!(tempUserConnected as TUserConnected).account.is_banned) {
          navigator.geolocation.getCurrentPosition((position) => {
            var location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            console.log(location);
            dispatch(updateLocation(location));
          });
          navigate(routePaths.profileChildren.myAccountAbsolute, {
            replace: false,
          });
          Toastify(
            `As Salam Aleykoum ${
              (tempUserConnected as TUserConnected).infos.firstname
            }, tu es maintenant connecté(e).`,
            "success",
            5000
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <>
      <div className="signin-container">
        <div className="header-part">
          <h1 className="title">Se Connecter</h1>
          <p className="subtitle"> ACCÉDER À L'ESPACE MEMBRE </p>
        </div>
        <form className="form-part" onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="Votre adresse email ou votre identifiant"
            variant="outlined"
            label="Email ou Identifiant"
            name="identifier"
            onChange={formik.handleChange}
            value={formik.values.identifier}
            error={
              formik.touched.identifier && Boolean(formik.errors.identifier)
            }
            helperText={formik.touched.identifier && formik.errors.identifier}
            type="text"
          />
          <TextField
            placeholder="Votre mot de passe"
            variant="outlined"
            label="Mot de passe"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: "15px" }}
          />
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="tertiary"
            sx={{ marginTop: "30px" }}
            type="submit"
          >
            SE CONNECTER
          </LoadingButton>
        </form>
        <div className="footer-part">
          <Link
            className="link-primary"
            to={routePaths.authChildren.forgottenPasswordAbsolute}
          >
            {" "}
            Mot de passe oublié
          </Link>
          <p>
            {" "}
            Vous n'avez pas de compte ?
            <Link
              className="link-primary"
              to={routePaths.authChildren.signUpAbsolute}
            >
              {" "}
              S'inscrire{" "}
            </Link>
          </p>
          <a
            href="https://zawaj-sounnah.com/"
            target="_blank"
            rel="noreferrer"
            className="link-gray"
          >
            Aller sur zawaj-sounnah.com
          </a>
        </div>
      </div>
      {userConnected !== null && (
        <CModalBanned
          open={(userConnected as TUserConnected).account.is_banned}
          reason={(userConnected as TUserConnected).account.reason_banned}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  email_from_redux: selectEmail,
});

const connector = connect(mapStateToProps);
const SigninForm = connector(SigninFormFct);

export default SigninForm;
