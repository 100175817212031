import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";


export const apiGetAllExchange = (access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/members/infos/conversations');
}


export const apiAcceptDemand = (member_id: string, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.post["Authorization"] = `Bearer ${access_token}`;
    return axios.post('/conversations/accept/' + member_id);
}


export const apiRejectDemand = (member_id: string, access_token: string, motif: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/conversations/reject/' + member_id, { note: motif });
}


export const apiRemindDemand = (member_id: string, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/conversations/relaunch/' + member_id);
}


export const apiCancelDemand = (member_id: string, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/conversations/cancel/' + member_id);
}

export const apiEndUpExchange = (data: { id: string, note: string }, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/conversations/close-by-member/' + data.id, { note: data.note });
}

export const apiSendMessage = (data: string, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/conversations/send/message', { message: data });
}


export const apiGetConversation = (id: string, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/conversations/' + id);
}

export const markMessageAsRead = (id: string, access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.patch["Authorization"] = `Bearer ${access_token}`;
    return axios.patch('/conversations/' + id + '/messages/read');
}


export const relaunch = (access_token: string): Promise<AxiosResponse<any, any>> => {
    axios.defaults.headers.get["Authorization"] = `Bearer ${access_token}`;
    return axios.get('/conversations/current/relaunch/');
}