export const routePaths = {
  root: "/",
  auth: "/auth",
  authChildren: {
    signIn : "/sign-in",
    signInAbsolute : "/auth/sign-in",
    signUp : "/sign-up",
    signUpAbsolute : "/auth/sign-up",
    forgottenPassword : "/forgot-password",
    forgottenPasswordAbsolute : "/auth/forgot-password",
    resetPassword : "/reset-password",
    resetPasswordAbsolute : "/auth/reset-password",
    termsAndCondition: "/terms-and-conditions",
    termsAndConditionAbsolute: "/auth/terms-and-conditions",
    payment:"/payment",
    paymentAbsolute:"/auth/payment",
  },
  profile: "/profile",
  profileChildren: {
    home:"/home",
    homeAbsolute: "/profile/home",
    favorite:"/favorite",
    favoriteAbsolute: "/profile/favorite",
    exchange:"/exchange",
    exchangeAbsolute: "/profile/exchange",
    search:"/search",
    searchAbsolute: "/profile/search",
    myAccount:"/my-account",
    myAccountAbsolute: "/profile/my-account",
    myAccountChildren: {
      fiche:"/fiche", 
      ficheAbsolute:"/profile/my-account/fiche",
      form:"/form", 
      formAbsolute:"/profile/my-account/form",
    },
    notification:"/notification",
    notificationAbsolute:"/profile/notification",

    fonctionnement: "/operation-of-site",
    fonctionnementAbsolute: "/profile/operation-of-site",
    engagement: "/engagement",
    engagementAbsolute: "/profile/engagement",
    questions: "/questions",
    questionsAbsolute: "/profile/questions",
    staff: "/staff",
    staffAbsolute: "/profile/staff",
    imamSpeakers: "/imam-speakers",
    imamSpeakersAbsolute: "/profile/imam-speakers",
    blacklist: "/blacklist",
    blacklistAbsolute: "/profile/blacklist",
    live:"/live",
    liveAbsolute:"/profile/live",
    subscription:"/subscription",
    subscriptionAbsolute:"/profile/subscription",
    subscriptionChildren: {
      desactivateSubscription:"/desactivate", 
      desactivateSubscriptionAbsolute:"/profile/subscription/desactivate",
      deleteAccount:"/delete", 
      deleteAccountAbsolute:"/profile/subscription/delete",
    },

  },
};
