import { routePaths } from "@/config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { TSignUp } from "../../types";
import { useFormik } from "formik";
import * as yup from "yup";
import "./SignupForm.styles.scss";
import { useAppDispatch } from "@/store";
import { updateSignUp } from "@/store/reducers/temp_data/temp_data.actions";
import {
  apiVerifyEmail,
  apiVerifyPhone,
  apiVerifyUsername,
} from "../../api/auth.api";
import { Toastify } from "@/utils/toast";
import { MuiTelInput } from "mui-tel-input";

const SignupForm: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const dispatch = useAppDispatch();

  const [phoneValue, setPhoneValue] = useState("+33");

  const handleChangePhoneValue = (newValue: string) => {
    setPhoneValue(newValue);
    formik.setFieldValue("phone_number", newValue);
  };

  const validationSchema = yup.object({
    firstname: yup.string().required(" le prénom est obligatoire "),
    lastname: yup.string().required(" le nom est obligatoire "),
    username: yup
      .string()
      .test({
        name: "not-email",
        message: "le nom d'utilisateur n'est pas un email",
        test: function (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return !emailRegex.test(value as string);
        },
      })
      .required(" le nom d'utilisateur est obligatoire "),
    email: yup
      .string()
      .email("Entrez une adresse mail valide")
      .required(" l'email est obligatoire "),
    phone_number: yup
      .string()
      .min(7, "le numéro de téléphone est obligatoire")
      .required(" le numéro de téléphone est obligatoire "),
    password: yup
      .string()
      .min(5, "Votre mot de passe doit contenir au moins 5 caratères")
      .required("le mot de passe est obligatoire"),
    confirmed_password: yup
      .string()
      .required("la confirmation du mot de passe est obligatoire")
      .test({
        name: "passwords_match",
        message: "Les mots de passes ne correspondent pas",
        test: function (value, ctx) {
          const { password } = ctx.parent;
          if (value !== password) {
            return false;
          }
          return true;
        },
      }),
    gender: yup.string().required(" le genre est obligatoire "),
    age: yup
      .number()
      .required(" l'âge est obligatoire ")
      .min(18, " l'âge minimum est de 18 ans")
      .max(90, " l'âge maximum est de 90 ans"),
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      username: "",
      gender: "",
      age: "",
      email: "",
      password: "",
      confirmed_password: "",
      phone_number: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //navigate( routePaths.authChildren.termsAndConditionAbsolute ,{replace: false});
      let dataToSend = {
        firstname: values.firstname,
        lastname: values.lastname,
        username: values.username,
        gender: values.gender,
        age: values.age,
        email: values.email,
        password: values.password,
        phone_number: values.phone_number.trim().replace(/\s/g, ""),
      };
      signupSubmit(dataToSend as TSignUp);
    },
  });

  const signupSubmit = (data: TSignUp) => {
    const verifyEmailPromise = apiVerifyEmail(formik.values.email);
    const verifyPhonePromise = apiVerifyPhone(
      formik.values.phone_number.trim().replace(/\s/g, "")
    );
    const verifyUsernamePromise = apiVerifyUsername(formik.values.username);

    Promise.all([verifyEmailPromise, verifyPhonePromise, verifyUsernamePromise])
      .then((response: any[]) => {
        console.log(response);
        if (
          response[0].code === "email_indisponible" ||
          response[1].code === "téléphone_indisponible" ||
          response[2].code === "username_indisponible"
        ) {
          response[0].code === "email_indisponible" &&
            Toastify(" l'email renseigné existe déjà ", "warning", 5000);

          response[1].code === "téléphone_indisponible" &&
            Toastify(" le télléphone renseigné existe déjà ", "warning", 5000);

          response[2].code === "username_indisponible" &&
            Toastify(" le username existe déjà ", "warning", 5000);
        } else {
          dispatch(updateSignUp(data));
          navigate(routePaths.authChildren.termsAndConditionAbsolute, {
            replace: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Toastify(
          " Nous sommes désolés de ne pouvoir vous satisfaire pour le moment. Nos techniciens sont à pied d'oeuvre pour résorber cette situation.",
          "error",
          5000
        );
      });
  };

  return (
    <div className="signup-container">
      <div className="header-part">
        <h1 className="title">Page d'inscription</h1>
      </div>
      <form className="form-part" onSubmit={formik.handleSubmit}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre prénom"
                variant="outlined"
                label="Prénom"
                name="firstname"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
                type="text"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre nom"
                variant="outlined"
                label="Nom"
                name="lastname"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
                type="text"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre nom d'utilisateur"
                variant="outlined"
                label="Nom d'utilisateur"
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                type="text"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre adresse email"
                variant="outlined"
                label="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                type="email"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <MuiTelInput
                placeholder="Votre numéro de téléphone (Whatsapp)"
                variant="outlined"
                label="Numéro de téléphone (Whatsapp)"
                name="phone_number"
                onChange={handleChangePhoneValue}
                value={phoneValue}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
                helperText={
                  formik.touched.phone_number && formik.errors.phone_number
                }
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            {false && (
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                  placeholder="Votre numéro de téléphone (Whatsapp)"
                  variant="outlined"
                  label="Numéro de téléphone (Whatsapp)"
                  name="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                  error={
                    formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  type="text"
                  sx={{ marginTop: "15px" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre mot de passe"
                variant="outlined"
                label="Mot de passe"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Confirmer votre mot de passe"
                variant="outlined"
                label="Confirmation de Mot de passe"
                type={showPassword2 ? "text" : "password"}
                name="confirmed_password"
                onChange={formik.handleChange}
                value={formik.values.confirmed_password}
                error={
                  formik.touched.confirmed_password &&
                  Boolean(formik.errors.confirmed_password)
                }
                helperText={
                  formik.touched.confirmed_password &&
                  formik.errors.confirmed_password
                }
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl
                fullWidth
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                sx={{ marginTop: "15px" }}
              >
                <InputLabel id="id-sexe">Sexe</InputLabel>
                <Select
                  labelId="id-sexe"
                  label="Sexe"
                  name="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <MenuItem value={"male"}>Homme</MenuItem>
                  <MenuItem value={"female"}>Femme</MenuItem>
                </Select>
                <FormHelperText
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  {formik.errors.gender}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre âge"
                variant="outlined"
                label="Âge"
                name="age"
                onChange={formik.handleChange}
                value={formik.values.age}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
                type="number"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid
              sx={{ display: "none" }}
              item
              xs={12}
              sm={12}
              lg={12}
              justifyContent="center"
            >
              <FormControl
                fullWidth
                color="primary"
                className="paiement-radio-row"
              >
                <FormLabel id="id-formule">Formules</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="id-formule"
                  name="row-radio-buttons-group"
                  className="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="1m"
                    control={<Radio />}
                    label="Abonnement mensuel"
                  />
                  <FormControlLabel
                    value="6m"
                    control={<Radio />}
                    label="Forfait 6 mois"
                  />
                  <FormControlLabel
                    value="1a"
                    control={<Radio />}
                    label="Forfait 1 an"
                  />
                </RadioGroup>
                <FormHelperText className="paiement-radio-helper-text">
                  Vous devez payer &nbsp;<b>10.00 €</b>&nbsp; pour &nbsp;
                  <b>Abonnement Mensuel</b>&nbsp; comme abonnement.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Button
          variant="contained"
          color="tertiary"
          sx={{ marginTop: "30px" }}
          type="submit"
        >
          {" "}
          S'INSCRIRE
        </Button>
      </form>
      <div className="footer-part">
        <Link
          className="link-primary"
          to={routePaths.authChildren.signInAbsolute}
        >
          Retourner sur la page de connexion
        </Link>
      </div>
    </div>
  );
};

export default SignupForm;
