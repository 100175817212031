import { axios } from "@/lib/axios";
import { store } from "@/store";
import {
  updateCurrentExchange,
  updateEthnies,
  updateGeneralLinks,
  updateMailFemale,
  updateMailMale,
  updateMaxCaracter,
  updateMaxMessage,
  updateModeration,
  updateIsModerationSuspend,
  updateNbreMessageReceive,
  updateNbreMessageSent,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import {
  getUserConnected,
  updateNewModificationSubmit,
} from "@/store/reducers/member/member.actions";
import { Toastify } from "@/utils/toast";
import { apiGetInterfaceSettings } from "../api/general.api";
import {
  TInterfaceSettings,
  TModerationSocket,
  TUserConnected,
  initCurrentExchange,
} from "../types";
import {
  apiGetModerationSchedule,
  apiGetUserConnected,
} from "@/features/auth/api/auth.api";

export const initializeParam = () => {
  const state = store.getState();

  const config = {
    headers: {
      Authorization: `${state.member.token}`,
    },
  };

  axios
    .get("/ethnicities", config)
    .then((response: any) => {
      let data = response.items;
      store.dispatch(updateEthnies(data));
    })
    .catch((error: any) => {
      console.log(error);
      Toastify(`${error?.response?.data?.message}`, "error", 5000);
    });

  //store.dispatch(updateMaxMessage(10)); // <- à enlever ici après
  //store.dispatch(updateNbreMessageSent(0)); // <- à enlever ici après
  //store.dispatch(updateNbreMessageReceive(0)); // <- à enlever ici après

  apiGetInterfaceSettings(state.member.token)
    .then((response: any) => {
      console.log("PARAMÈTRE 🖱️", response);
      let interfaceSetting: TInterfaceSettings = response as TInterfaceSettings;

      store.dispatch(
        updateMaxMessage(
          interfaceSetting.moderation.number_of_messages_per_discussion
        )
      );
      store.dispatch(
        updateMaxCaracter(
          interfaceSetting.moderation.number_of_caracters_per_message
        )
      );
      store.dispatch(updateMailMale(interfaceSetting.men_group_head.email));
      store.dispatch(updateMailFemale(interfaceSetting.women_group_head.email));
      store.dispatch(
        updateGeneralLinks({
          youtube: interfaceSetting.web_media.youtube,
          telegram: interfaceSetting.social_media.telegram,
          facebook: interfaceSetting.social_media.facebook,
          twitter: interfaceSetting.social_media.twitter,
          instagram: interfaceSetting.social_media.instagram,
        })
      );
      store.dispatch(
        updateIsModerationSuspend(
          interfaceSetting.maintenance.suspend_moderation
        )
      );
    })
    .catch((error: any) => {
      console.log(error);
      //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
    });

  apiGetUserConnected(state.member.token)
    .then((response: any) => {
      let userConnected = response.data;
      store.dispatch(getUserConnected(userConnected as TUserConnected));

      if (!(userConnected as TUserConnected).account.has_conversation) {
        store.dispatch(updateNbreMessageReceive(0));
        store.dispatch(updateNbreMessageSent(0));
        store.dispatch(updateCurrentExchange(initCurrentExchange));
        store.dispatch(updateOpenDiscussion(false));
      }

      if ((userConnected as TUserConnected).account.is_approved) {
        store.dispatch(updateNewModificationSubmit(false));
      }
    })
    .catch((error) => {
      console.log("😜", error);
    });

  apiGetModerationSchedule(state.member.token)
    .then((response: any) => {
      let data: TModerationSocket = {
        day: (response as TModerationSocket).day,
        end_time: (response as TModerationSocket).end_time,
        is_holiday: (response as TModerationSocket).is_holiday,
        message: (response as TModerationSocket).message,
        start_time: (response as TModerationSocket).start_time,
        status: (response as TModerationSocket).status,
      };
      store.dispatch(updateModeration(data));
    })
    .catch((error) => {
      console.log("😜", error);
    });
};
