import { Box, Grid, Button, Typography} from '@mui/material';
import React, {useState} from 'react';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { Toastify } from '@/utils/toast';
import * as yup from 'yup';
import './Sante.step.styles.scss';
import { selectUserConnected, selectToken } from '@/store/reducers/member/member.selector';
import { ConnectedProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { apiSaveSante } from '@/features/profile/api/profile.api';
import { store } from '@/store';
import { updateHealthAndDisability, updateIsApproved, updateNewModificationSubmit, updateProgress } from '@/store/reducers/member/member.actions';
import { TAdditionnalInfosHealthAndDisability, TUserConnected } from '@/features/profile/types';



type PropsFromElement = {
  chooseStep : React.Dispatch<React.SetStateAction<number>>,
  scrollTop: () => void
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const SanteStepFct: React.FC<PropsFromRedux & PropsFromElement> = ({
  userConnected,
  token,
  chooseStep,
  scrollTop
}) => {


  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    sante_physique: yup.string(),
      
  });

  const formik = useFormik({
    initialValues: {
      sante_physique: (userConnected as TUserConnected).additional_infos.health_and_disability.physical_health || '',
      mal_occulte: (userConnected as TUserConnected).additional_infos.health_and_disability.occult_diseases || '',
      
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);


      const dataToSend = {
        physical_health : values.sante_physique,
        occult_diseases: values.mal_occulte,
      }
      console.log(dataToSend);
      santeStepSubmit(dataToSend);
    }
  });

  const santeStepSubmit = (data:any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
      setLoading(true);

      apiSaveSante(data,token).then((response:any)=> {
        setLoading(false);
        console.log(response);

        scrollTop();

        if( !(response.code === 'aucune_information_fournie') ){
          store.dispatch( updateProgress(response.rate_of_completion) );
          store.dispatch( updateHealthAndDisability((response.payload as TAdditionnalInfosHealthAndDisability)) );
  
          (userConnected as TUserConnected).account.is_fresh_new ?
            Toastify(`Nous vous remercions pour avoir rempli votre profil, il va maintenant être vérifier par nos services sous 24 h , nous pourrons vous contacter par whatsapp ou mail en cas de besoin. Cordialement l'équipe zawaj sounnah`, 'success', 5000)
            :
            Toastify(`Votre profil est en cours de vérification par notre équipe suite à vos modifications.`, 'success', 5000);
            
          store.dispatch( updateIsApproved(false) );
          store.dispatch( updateNewModificationSubmit(true) );

        }






      }).catch((error:any)=> {
        setLoading(false);

        
        Toastify(`${error?.response?.data?.message}`, 'error', 5000);
      })

  }

  const reset = () => {
    formik.setValues({
        sante_physique : '',
        mal_occulte: '',
      });
  }

  return (

      <form className="form-part-sante"  onSubmit={formik.handleSubmit}>
        <Box sx={{width:'100%'}} >
          <Grid container rowSpacing={4} columnSpacing={2} >

              <Grid item xs={12} sm={12} lg={12}>
                  <div className="text-area">
                      <Typography className="text-area-label"> Santé physique </Typography>
                      <textarea 
                              className="text-area-input"
                              placeholder="Maladie, malformation, handicap, autre"
                              rows={4}
                              name="sante_physique"
                              onChange={formik.handleChange}
                              value={formik.values.sante_physique}>
                      </textarea>
                  </div>
              </Grid>


              <Grid item xs={12} sm={12} lg={12}>
                  <div className="text-area">
                      <Typography className="text-area-label"> Mal occulte </Typography>
                      <textarea 
                              className="text-area-input"
                              placeholder="Djinn, waswas, sorcellerie, ..."
                              rows={4}
                              name="mal_occulte"
                              onChange={formik.handleChange}
                              value={formik.values.mal_occulte}>
                      </textarea>
                  </div>
              </Grid>

          </Grid>
        </Box>

        <Grid container 
              rowSpacing={4} 
              columnSpacing={2}
              sx={{justifyContent:'flex-end',marginTop:'30px'}}  >

            <Grid item xs={12} sm={12} lg={3} xl={2}>
                <Button 
                    variant="outlined" 
                    color='tertiary'
                    onClick={()=>{
                      reset();
                    }}
                    > Effacer
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} lg={5}>
                <LoadingButton 
                    loading={loading}
                    loadingPosition="center"
                    variant="contained" 
                    color='primary'
                    type="submit"
                    > Valider et terminer
                </LoadingButton>
            </Grid>
        </Grid>



      </form>
  )
}

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
  token: selectToken
});


const connector = connect(mapStateToProps);
const SanteStep = connector(SanteStepFct);

export default SanteStep;
